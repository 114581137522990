import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LViewFlags } from '@angular/core/src/render3/interfaces/view';
import { EntradasService } from './entradas.service';
import { VentaService } from './venta.service';

export class MotivoSeleccionado {
  idEntrada: string;
  lista: any[];
  constructor(idEntrada) {
    this.idEntrada = idEntrada;
    this.lista = [];
  }
}

@Injectable({
  providedIn: 'root'
})
export class MotivosService {
  private motivosSubject: BehaviorSubject<any>;
  public motivos: Observable<any>;

  private motivosSeleccionadosSubject: BehaviorSubject<any>;
  public motivosSeleccionados: Observable<any>;

  private entradasDesdeLocalizadorConMotivoSubject: BehaviorSubject<any>;
  public entradasDesdeLocalizadorConMotivo: Observable<any>;

  constructor(private entradaService: EntradasService, private ventaService: VentaService) {
    this.motivosSubject = new BehaviorSubject(undefined);
    this.motivos = this.motivosSubject.asObservable();

    this.motivosSeleccionadosSubject = new BehaviorSubject([]);
    this.motivosSeleccionados = this.motivosSeleccionadosSubject.asObservable();

    this.entradasDesdeLocalizadorConMotivoSubject = new BehaviorSubject([]);
    this.entradasDesdeLocalizadorConMotivo = this.entradasDesdeLocalizadorConMotivoSubject.asObservable();
  }

  public get motivosValue() {
    return this.motivosSubject.value;
  }

  setMotivosValue(motivos) {
    this.motivosSubject.next(motivos);
  }

  public get getAllMotivosSeleccionados() {
    return this.motivosSeleccionadosSubject.asObservable();
  }

  public get entradasDesdeLocalizadorConMotivoValue() {
    return this.entradasDesdeLocalizadorConMotivoSubject.value;
  }

  public setEntradasDesdeLocalizadorConMotivo(entradas) {
    this.entradasDesdeLocalizadorConMotivoSubject.next(entradas);
  }

  public getMotivosSeleccionadosByEntradaId(id) {
    return this.motivosSeleccionadosSubject.asObservable().pipe(
      map(motivos => {
        return motivos.find(motivo => motivo.idEntrada === id);
      })
    );
  }

  public getDistinctCountMotivosByEntradaId(id) {
    return this.motivosSeleccionadosSubject.asObservable().pipe(
      map(motivos => {
        if (motivos.find(motivo => motivo.idEntrada === id)) {
          return motivos
            .find(motivo => motivo.idEntrada === id)
            .lista.map(m => {
              return { id: m.PkId, nombre: m.Nombre, qty: m.numMotivo };
            });
        } else {
          return [];
        }
      })
    );
  }

  public pushMotivo(motivo, cantidad: any) {
    if (cantidad === 0) {
      this.deleteMotivo(motivo);
      this.getCantidadTotalPorEntrada();
      return;
    }

    const motivos = this.motivosSeleccionadosSubject.value;

    let linea;
    if (
      this.motivosSeleccionadosSubject.value.find(m => m.idEntrada === motivo.idEntradaAsociada)
    ) {
      linea = this.motivosSeleccionadosSubject.value.find(
        m => m.idEntrada === motivo.idEntradaAsociada
      );
    } else {
      linea = new MotivoSeleccionado(motivo.idEntradaAsociada);
      motivos.push(linea);
    }

    if (linea.lista.find(m => m.PkId === motivo.PkId)) {
      linea.lista.find(m => m.PkId === motivo.PkId).numMotivo = cantidad;
    } else {
      linea.lista.push({ ...motivo, numMotivo: cantidad });
    }

    this.setMotivosSeleccionados(motivos);
    this.getCantidadTotalPorEntrada();
  }

  private deleteMotivo(motivo) {
    const motivos = this.motivosSeleccionadosSubject.value;
    const linea = motivos.find(m => m.idEntrada === motivo.idEntradaAsociada);

    if (linea) {
      motivos.find(m => m.idEntrada === motivo.idEntradaAsociada).lista = linea.lista.filter(
        m => m.PkId !== motivo.PkId
      );
    }
    if (motivos.find(m => m.idEntrada === motivo.idEntradaAsociada).lista.length === 0) {
      motivos.filter(m => m.idEntrada !== motivo.idEntradaAsociada);
    }
    this.setMotivosSeleccionados(motivos);
  }

  setMotivosSeleccionados(motivosSeleccionados) {
    this.motivosSeleccionadosSubject.next(motivosSeleccionados);
  }

  public get motivosSeleccionadosValue() {
    return this.motivosSeleccionadosSubject.value;
  }

  private getCantidadTotalPorEntrada() {
    const entradaSeleccionada = this.entradaService.entradaSeleccionada;
    const motivos = this.motivosSeleccionadosValue.find(
      m => m.idEntrada === entradaSeleccionada.idTarifa
    );
    if (motivos.lista.length === 0) {
      this.ventaService.sendEntradas(entradaSeleccionada, 0);
      return;
    }
    const cantidad = motivos.lista
      .map(element => element.numMotivo)
      .reduce((total, num) => total + num);
    this.ventaService.sendEntradas(entradaSeleccionada, cantidad);
  }

  getCantidadTotal() {
    const entradaSeleccionada = this.entradaService.entradaSeleccionada;
    const motivos = this.motivosSeleccionadosValue.find(
      m => m.idEntrada === entradaSeleccionada.idTarifa
    );
    if (!motivos || motivos.lista.length === 0) {
      return 0;
    }
    const cantidad = motivos.lista
      .map(element => element.numMotivo)
      .reduce((total, num) => total + num);
    return cantidad;
  }

  getPrecioTotal() {
    const entradaSeleccionada = this.entradaService.entradaSeleccionada;
    const motivos = this.motivosSeleccionadosValue.find(
      m => m.idEntrada === entradaSeleccionada.idTarifa
    );
    if (!motivos || motivos.lista.length === 0) {
      return 0;
    }
    const cantidad = motivos.lista
      .map(element => element.numMotivo)
      .reduce((total, num) => total + num);

    let ivaTotal;
    ivaTotal =
      (Number.parseFloat(entradaSeleccionada.IVA) / 100) *
      Number.parseFloat(entradaSeleccionada.PrecioBase.replace(/,/, '.')) *
      cantidad;
    ivaTotal = Math.round(ivaTotal * 100) / 100;

    let precioTotal = 0;
    precioTotal =
      precioTotal +
      Number.parseFloat(entradaSeleccionada.PrecioBase.replace(/,/, '.')) * cantidad;
    precioTotal = Math.round(precioTotal * 100) / 100;
    return ivaTotal + precioTotal;
  }
}
