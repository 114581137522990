import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioElevadoService } from 'src/app/core/security/usuario-elevado.service';
import { TabsNavigationService } from './services/tabs-navigation.service';

@Component({
  selector: 'ticketing-gestionar',
  templateUrl: './gestionar.component.html',
  styleUrls: ['./gestionar.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'layout'
  }
})
export class GestionarComponent implements OnInit, OnDestroy {
  tabs;
  subs;
  constructor(private router: Router, private tabsNavigation: TabsNavigationService, private usuarioElevado: UsuarioElevadoService) { }

  ngOnInit() {
    this.subs = this.tabsNavigation.getTabs().subscribe( tabs => this.tabs = tabs);
    if(this.tabs.length > 0)
    this.router.navigate([`/${this.tabs[0].url}`]);
  }

  ngOnDestroy(){
    this.usuarioElevado.reducir();
    if(this.subs){this.subs.unsubscribe();}
  }
}
