import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { ModalService } from 'src/app/shared/services/modal.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { FacturaService } from 'src/app/shared/services/factura.service';
import { PagerService } from 'src/app/shared/services/pager.service';
import { ConfigurationService } from 'src/app/core';
import * as moment from 'moment';
import { InsUpdContactoAlbaranModel } from 'src/app/modules/venta/models/ins-upd-contacto-albaran-model';
import { AlbaranService } from '../../../services/albaran.service';
import { VentaService } from 'src/app/modules/venta';
import { SuccessToast } from 'src/app/core/toast/success.toast';
import { Subscription } from 'rxjs';


@Component({
  selector: 'ticketing-modal-contacto',
  templateUrl: './modal-contacto.component.html',
  styleUrls: ['./modal-contacto.component.scss']
})

export class ModalContactoComponent implements OnInit {
  @Input() id: string;
  model;
  private element: any;
  contactoForm: FormGroup;
  abrir: boolean;
  clientes = [];
  pager: any = {};
  pagedItems: any[];
  divCampos = [''];
  campos: string;
  camposObligatorios = '0';
  listaTipoDocumento = ['DNI', 'CIF', 'NIF', 'OTRO'];
  listaSexo = ['HOMBRE', 'MUJER'];
  contactoId = '0';
  listas = [];
  labels = [];
  maximasLongitudes = [];
  requeridos = [];
  keyboardWrapOn = true;
  buscarClienteSub: Subscription;
  insUpdContactoAlbaranSub: Subscription;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private el: ElementRef,
    private albaranService: AlbaranService,
    private venta: VentaService,
    private facturaService: FacturaService,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private configuration: ConfigurationService,
  ) {
    this.element = el.nativeElement;
    this.contactoForm = this.formBuilder.group({
      nombre: [''],
      priapellido: [''],
      segapellido: [''],
      email: ['', Validators.email],
      tipodocumento: [''],
      documento: [''],
      fechanacimiento: [null],
      sexo: [''],
      direccion: [''],
      localidad: [''],
      provincia: [''],
      codigopostal: [''],
      telefono: [''],
      notas: [''],
      pregunta1: [''],
      pregunta2: [''],
      pregunta3: [''],
      pregunta4: [''],
      politicaprivacidad: [false], //, Validators.requiredTrue
      publicidadinterna: [false]
    });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.clientes.length, page);
    this.pagedItems = this.clientes.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    this.pager.pages = this.pager.pages.filter(
      num => num <= this.pager.totalPages
    );

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {

    this.buscarClienteSub = this.facturaService
      .buscarCliente(this.contactoForm.valueChanges)
      .subscribe((res: any) => {
        // if (res.DatosResult) {
        //   this.clientes = res.DatosResult.ListadoContacto;
        if(res.ResultadoBuscadorClientes){
          this.clientes = res.ResultadoBuscadorClientes;
          this.setPage(1);
        } else {
          this.clientes = [];
        }
      });
    this.camposObligatorios = this.configuration.deviceConfiguration.AppConfigJsonConfig? this.configuration.deviceConfiguration.AppConfigJsonConfig.Documentacion: '0';
    this.campos = this.configuration.deviceConfiguration.AppConfigJsonConfig? this.configuration.deviceConfiguration.AppConfigJsonConfig.DocumentacionCampos: '';
    let obj = JSON.parse(this.campos);
    
    obj.forEach(campo =>{
      if (campo.visible==undefined || campo.visible==null || campo.visible == true)
      {
        this.divCampos.push(campo.name);
        if (campo.required == true){
          // 
          this.composValidar(campo.name);
        }
        if(campo.options && campo.options.length >0 )
        {
          this.setLista(campo.name, campo.options);
        }
        if(campo.label && campo.label != "")
        {
          this.setLabel(campo.name, campo.label);
        }
        if(campo.maximo && campo.maximo != "")
        {
          this.setMaximo(campo.name, campo.maximo);
        }
        if(campo.required)
        {
          this.setRequerido(campo.name, campo.required);
        }
      }
    });
    
    this.keyboardWrapOn = this.configuration.deviceConfiguration.AppConfigJsonConfig && this.configuration.deviceConfiguration.AppConfigJsonConfig.DocumentacionTeclado && this.configuration.deviceConfiguration.AppConfigJsonConfig.DocumentacionTeclado == "false"? false:true;
    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
    this.initForm();
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.abrir = false;
    this.contactoForm.reset();
    this.listas = [];
    if(this.buscarClienteSub){this.buscarClienteSub.unsubscribe();}
    if(this.insUpdContactoAlbaranSub){this.insUpdContactoAlbaranSub.unsubscribe();}
  }

  guardar() {
    // 
    if (this.ValidaCampoDocumento() == false) {
      const element = document.getElementById('documento');
      element.classList.add('color-error');
      return;
    }
    let contacto: InsUpdContactoAlbaranModel = new InsUpdContactoAlbaranModel();
    // 
    contacto.TPV = this.albaranService.albaranValue.TPVId;
    contacto.NumAlbaran = this.albaranService.albaranValue.NumAlbaran;
    contacto.Nombre = this.contactoForm.controls['nombre'].value;
    contacto.Apellido1 = this.contactoForm.controls['priapellido'].value;
    contacto.Apellido2 = this.contactoForm.controls['segapellido'].value;
    contacto.Email = this.contactoForm.controls['email'].value;
    contacto.CIF = this.contactoForm.controls['documento'].value;
    contacto.FechaNacimiento = this.contactoForm.controls['fechanacimiento'].value ? (this.contactoForm.controls['fechanacimiento'].value.year + '-'
    + this.contactoForm.controls['fechanacimiento'].value.month + '-' + this.contactoForm.controls['fechanacimiento'].value.day) : null;
    contacto.Sexo = this.contactoForm.controls['sexo'].value;
    contacto.Domicilio = this.contactoForm.controls['direccion'].value;
    contacto.Localidad = this.contactoForm.controls['localidad'].value;
    contacto.Provincia = this.contactoForm.controls['provincia'].value;
    contacto.CP = this.contactoForm.controls['codigopostal'].value;
    contacto.Telefono = this.contactoForm.controls['telefono'].value;
    contacto.PublicidadInterna = this.contactoForm.controls['publicidadinterna'].value;
    contacto.Contacto = this.contactoId;

    //
    contacto.Notas = this.contactoForm.controls['notas'].value;
    contacto.Pregunta1 = this.getLabel('Pregunta1');
    contacto.Respuesta1 = this.getSeleccionadoPregunta('Pregunta1'); //this.contactoForm.controls['pregunta1'].value
    contacto.Pregunta2 = this.getLabel('Pregunta2');
    contacto.Respuesta2 = this.getSeleccionadoPregunta('Pregunta2'); //this.contactoForm.controls['pregunta2'].value
    contacto.Pregunta3 = this.getLabel('Pregunta3');
    contacto.Respuesta3 = this.getSeleccionadoPregunta('Pregunta3'); //this.contactoForm.controls['pregunta3'].value
    contacto.Pregunta4 = this.getLabel('Pregunta4');
    contacto.Respuesta4 = this.getSeleccionadoPregunta('Pregunta4'); //this.contactoForm.controls['pregunta4'].value
    //
     this.insUpdContactoAlbaranSub = this.venta.insUpdContactoAlbaran(contacto).subscribe(respuesta => {
      
      if (!respuesta || !respuesta.DatosResult) {
        this.toastr.error('Error', respuesta.Mensajes[0].DescripcionMensaje, {
        });
      } else {
        this.toastr.success('Contacto insertado correctamente', '', {
          toastComponent: SuccessToast
        });
      }
    });
    setTimeout(() => {
      this.close();
    }, 1200);
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.contactoForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  seleccionarCliente(cliente) {
    this.facturaService.setClienteSeleccionadoValue(cliente);
    this.contactoId = cliente.pkId;
    // 
    this.contactoForm.controls['nombre'].patchValue(cliente.Nombre);
    this.contactoForm.controls['priapellido'].patchValue(cliente.Apellido1);
    this.contactoForm.controls['segapellido'].patchValue(cliente.Apellido2);
    this.contactoForm.controls['email'].patchValue(cliente.Email);
    this.contactoForm.controls['tipodocumento'].patchValue('OTRO');
    this.contactoForm.controls['documento'].patchValue(cliente.Cif);
    this.contactoForm.controls['fechanacimiento'].patchValue({
      year: parseInt(moment(cliente.FechaNacimiento, 'DD/MM/YYYY').format('YYYY'), 10),
      month: parseInt(moment(cliente.FechaNacimiento, 'DD/MM/YYYY').format('M'), 10),
      day: parseInt(moment(cliente.FechaNacimiento, 'DD/MM/YYYY').format('D'), 10)
    });
    this.contactoForm.controls['sexo'].patchValue(cliente.Sexo);
    this.contactoForm.controls['direccion'].patchValue(cliente.Domicilio);
    this.contactoForm.controls['localidad'].patchValue(cliente.Localidad);
    this.contactoForm.controls['provincia'].patchValue(cliente.Provincia);
    this.contactoForm.controls['codigopostal'].patchValue(cliente.CP);
    this.contactoForm.controls['telefono'].patchValue(cliente.Telefono);
    if (cliente.PublicidadInterna == 1){
      this.contactoForm.controls['publicidadinterna'].patchValue(cliente.PublicidadInterna);
    }
    this.abrir = false;
  }

  initForm() {
    if (this.venta.clienteSeleccionadoValue) {
      const cliente = this.venta.clienteSeleccionadoValue;
      this.contactoId = cliente.pkId;
      this.contactoForm.controls['nombre'].patchValue(cliente.Nombre);
      this.contactoForm.controls['priapellido'].patchValue(cliente.Apellido1);
      this.contactoForm.controls['segapellido'].patchValue(cliente.Apellido2);
      this.contactoForm.controls['email'].patchValue(cliente.Email);
      this.contactoForm.controls['documento'].patchValue(cliente.Cif);
      this.contactoForm.controls['tipodocumento'].patchValue('OTRO');
      this.contactoForm.controls['fechanacimiento'].patchValue({
        year: parseInt(moment(cliente.FechaNacimiento, 'DD/MM/YYYY').format('YYYY'), 10),
        month: parseInt(moment(cliente.FechaNacimiento, 'DD/MM/YYYY').format('M'), 10),
        day: parseInt(moment(cliente.FechaNacimiento, 'DD/MM/YYYY').format('D'), 10)
      });
      this.contactoForm.controls['sexo'].patchValue(cliente.Sexo);
      this.contactoForm.controls['direccion'].patchValue(cliente.Domicilio);
      this.contactoForm.controls['localidad'].patchValue(cliente.Localidad);
      this.contactoForm.controls['provincia'].patchValue(cliente.Provincia);
      this.contactoForm.controls['codigopostal'].patchValue(cliente.CP);
      this.contactoForm.controls['telefono'].patchValue(cliente.Telefono);
      if (cliente.PublicidadInterna == 1){
        this.contactoForm.controls['publicidadinterna'].patchValue(cliente.PublicidadInterna);
      }
    } else {
      this.contactoId = '0';
    }
    // 
  }

  composValidar(campo) {
    if (campo === 'nombre') { this.contactoForm.controls['nombre'].setValidators(Validators.required); }
    if (campo === 'priapellido') { this.contactoForm.controls['priapellido'].setValidators(Validators.required); }
    if (campo === 'segapellido') { this.contactoForm.controls['segapellido'].setValidators(Validators.required); }
    if (campo === 'documento') { this.contactoForm.controls['documento'].setValidators(Validators.required); }
    if (campo === 'fechanacimiento') { this.contactoForm.controls['fechanacimiento'].setValidators(Validators.required); }
    if (campo === 'localidad') { this.contactoForm.controls['localidad'].setValidators(Validators.required); }
    if (campo === 'provincia') { this.contactoForm.controls['provincia'].setValidators(Validators.required); }
    if (campo === 'codigopostal') { this.contactoForm.controls['codigopostal'].setValidators(Validators.required); }
    if (campo === 'telefono') { this.contactoForm.controls['telefono'].setValidators(Validators.required); }
    if (campo === 'notas') { this.contactoForm.controls['notas'].setValidators(Validators.required); }
    if (campo === 'Pregunta1') { this.contactoForm.controls['pregunta1'].setValidators(Validators.required); }
    if (campo === 'Pregunta2') { this.contactoForm.controls['pregunta2'].setValidators(Validators.required); }
    if (campo === 'Pregunta3') { this.contactoForm.controls['pregunta3'].setValidators(Validators.required); }
    if (campo === 'Pregunta4') { this.contactoForm.controls['pregunta4'].setValidators(Validators.required); }
  }

  ValidaCampoDocumento() {
    if (this.contactoForm.controls['tipodocumento'].value == 'DNI'
    || this.contactoForm.controls['tipodocumento'].value == 'NIF'){
      return this.validarDniNif(this.contactoForm.controls['documento'].value );
    } else if (this.contactoForm.controls['tipodocumento'].value == 'CIF'){
      return this.validarCIF(this.contactoForm.controls['documento'].value );
    }
    return true;
  }

  validarDniNif(value) {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = value.toString().toUpperCase(); 
    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      return false;
    }
    let nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');
    let letter = str.substr(-1);
    let charIndex = parseInt(nie.substr(0, 8)) % 23;
    if (validChars.charAt(charIndex) === letter) {
      return true;
    }
    return false;
  }

  validarCIF(cif) {
    if (!cif || cif.length !== 9) {
      return false;
    }
    const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    const digits = cif.substr(1, cif.length - 2);
    const letter = cif.substr(0, 1);
    const control = cif.substr(cif.length - 1);
    let sum = 0;
    let i;
    let digit: number;
    if (!letter.match(/[A-Z]/)) {
      return false;
    }
    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i], 10);
      if (isNaN(digit)) {
        return false;
      }
      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit = digit / 10 + (digit % 10);
        }
        sum += digit;
      } else {
        sum += digit;
      }
    }
    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }
    if (letter.match(/[ABEH]/)) {
      return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control;
    }
    return String(digit) === control || letters[digit] === control;
  }

  setLista(nombreLista:string, lista)
  {
    this.listas.push({nombre: nombreLista, lista: this.mapeaOpcionesconSeleccionado(lista)});
  }
  getLista(nombreLista:string)
  {
    let respuesta = this.listas.find(elemento=> elemento.nombre == nombreLista);
    return respuesta != null? respuesta.lista : null;
  }

  setLabel(nombreCampo:string, label)
  {
    this.labels.push({nombre: nombreCampo, label: label});
  }
  getLabel(nombreCampo:string)
  {
    let respuesta =  this.labels.find(elemento=> elemento.nombre == nombreCampo);
    return respuesta != null? respuesta.label : null;
  }

  setMaximo(nombreCampo:string, max)
  {
    this.maximasLongitudes.push({nombre: nombreCampo, maximo: max});
  }
  getMaximo(nombreCampo:string)
  {
    let respuesta =  this.maximasLongitudes.find(elemento=> elemento.nombre == nombreCampo);
    return respuesta != null? respuesta.maximo : null;
  }

  setRequerido(nombreCampo:string, requerido)
  {
    this.requeridos.push({nombre: nombreCampo, requerido: requerido});
  }
  getRequerido(nombreCampo:string)
  {
    let respuesta =  this.requeridos.find(elemento=> elemento.nombre == nombreCampo);
    return respuesta != null? respuesta.requerido : false;
  }

  mapeaOpcionesconSeleccionado(listaString)
  {
    let respuesta =[];
    listaString.forEach(element => {
      respuesta.push({nombre: element, seleccionado: false});
    });
    return respuesta;
  }

  seleccionaCheckEncuesta(item, nombreCampo:string)
  {
    let pregunta =  this.listas.find(elemento=> elemento.nombre == nombreCampo);
    let indexPregunta = this.listas.indexOf(pregunta);
    //let elemento = pregunta.lista.find(elemento=> elemento.nombre == item);
    //Es excluyente, asi que ponemos todos los seleccionados a false
    pregunta.lista.forEach(element => { element.seleccionado = false;
    });
    let indexElemento = pregunta.lista.indexOf(item);
    item.seleccionado = !item.seleccionado;
    pregunta.lista[indexElemento] = item;
    this.listas[indexPregunta] = pregunta;
  }

  getSeleccionadoPregunta(nombreCampo:string):string
  {
    let respuesta = null;
    let pregunta =  this.listas.find(elemento=> elemento.nombre == nombreCampo);
    let elemento = pregunta != null? pregunta.lista.find(elemento => elemento.seleccionado == true) : null;
    respuesta = elemento != null? elemento.nombre:null;
    return respuesta;
  }
}
