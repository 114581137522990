import { Injectable } from '@angular/core';
import { parseStackingContexts } from 'html2canvas/dist/types/render/stacking-context';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { VentaService } from '../venta';

@Injectable({
  providedIn: 'root'
})
export class AsociadoService {
  private tipoClienteSubject: BehaviorSubject<any>;
  public tipoCliente$: Observable<any>;

  private entradasConsumidasSubject: BehaviorSubject<any>;
  public entradasConsumidas$: Observable<any>;

  private entradasDisponiblesSubject: BehaviorSubject<any>;
  public entradasDisponibles$: Observable<any>;

  private listaEntradasSeleccionadasSubject: BehaviorSubject<any>;
  public listaEntradasSeleccionadas$: Observable<any>;

  private codigoClienteSubject: BehaviorSubject<any>;
  public codigoCliente$: Observable<any>;

  private fechaVentaSubject: BehaviorSubject<any>;
  public fechaVenta$: Observable<any>;

  private mensajeErrorSubject: BehaviorSubject<any>;
  public mensajeError$: Observable<any>;

  order = '';
  selected;
  entrada;
  
  constructor(private translate: TranslateService) { 
    this.tipoClienteSubject = new BehaviorSubject(undefined);
    this.tipoCliente$ = this.tipoClienteSubject.asObservable();

    this.entradasConsumidasSubject = new BehaviorSubject(undefined);
    this.entradasConsumidas$ = this.entradasConsumidasSubject.asObservable();

    this.entradasDisponiblesSubject = new BehaviorSubject(undefined);
    this.entradasDisponibles$ = this.entradasDisponiblesSubject.asObservable();
  
    this.listaEntradasSeleccionadasSubject = new BehaviorSubject(undefined);
    this.listaEntradasSeleccionadas$ = this.listaEntradasSeleccionadasSubject.asObservable();

    this.codigoClienteSubject = new BehaviorSubject(undefined);
    this.codigoCliente$  = this.codigoClienteSubject.asObservable();

    this.fechaVentaSubject = new BehaviorSubject(undefined);
    this.fechaVenta$ = this.fechaVentaSubject.asObservable();

    this.mensajeErrorSubject = new BehaviorSubject(undefined);
    this.mensajeError$ = this.mensajeErrorSubject.asObservable();

  }

  public get mensajeErrorValue(){
    return this.mensajeErrorSubject.value;
  }
  public setMensajeError(mensaje){
    this.mensajeErrorSubject.next(mensaje);
  }
  public get fechaVentaValue(){
    return this.fechaVentaSubject.value;
  } 

  public setFechaVenta(fecha){
    this.fechaVentaSubject.next(fecha);
  }

  public get codigoClienteValue() {
    return this.codigoClienteSubject.value;
  }

  public setCodigoCliente(codigo) {
    this.codigoClienteSubject.next(codigo);
  }

  public get tipoClienteValue() {
    return this.tipoClienteSubject.value;
  }

  public setTipoCliente(tipo) {
    let tipoCliente;
    switch (tipo)
    {
      case '0':
      case 0 :    
        tipoCliente = this.translate.instant('NO_CLIENTE_ASOCIADO');
        break;
      case '9':
      case 9 :
        tipoCliente = this.translate.instant('CLIENTE_MAPFRE');
        break; 
      case '8':
      case 8 : 
        tipoCliente = this.translate.instant('CLIENTE_PLATINO');
        break;
      case '-1':
      case -1:
        tipoCliente = this.translate.instant('SERVICIO_NO_DISPONIBLE');
        break;     
      default:
        tipoCliente = 'sin validar';
        break;
    }
    this.tipoClienteSubject.next(tipoCliente);

  }
  
  public get entradasConsumidasValue() {
    return this.entradasConsumidasSubject.value;
  }

  public setEntradasConsumidas(entradas) {
    const arrayProd = Object.values(entradas)
    this.entradasConsumidasSubject.next(arrayProd);
  }
  public get entradasDisponiblesValue() {
    return this.entradasDisponiblesSubject.value;
  }

  public setEntradasDisponibles(entradas) {
    const arrayProd = Object.values(entradas)
    this.entradasDisponiblesSubject.next(arrayProd);
  }

public get listaEntradasSeleccionadasValue(){
  return this.listaEntradasSeleccionadasSubject.value;
}

public setListaEntradasSeleccionadas(entradas){
  this.listaEntradasSeleccionadasSubject.next(entradas);
}

  setNumpadOrder(order: string) {
    this.order = order;
    this.selected.value = this.orderDispatcher();
  }

  orderDispatcher(): string {
    switch (this.order) {
      case 'delete':
        const newstr = this.selected.value.substring(0, this.selected.value.length - 1);
        if (newstr.length > 0) {
          this.pushEntrada(this.entrada, +newstr);
          return newstr;
        } else {
          this.pushEntrada(this.entrada, 0);
          return '';
        }
      case 'reset':
        this.pushEntrada(this.entrada, 0);
        return '0';
      case 'losefocus':
        if (this.selected.value === '') {
          this.selected.value = 0;
          this.pushEntrada(this.entrada, 0);
        }
        this.selected.focus = false;
        return this.selected.value;
      default:
        const oldValue = ''.concat(this.selected.value);
        if (oldValue === '0' && this.order === '0') {
          return '';
        }
        const newValue = oldValue.concat(this.order);
        const Ncomma = (newValue.match(/,/g) || []).length;
        let Nintegers = 0;
        let Ndecimals = 0;
        if (Ncomma > 0) {
          Nintegers = newValue.split('.')[0].length;
          Ndecimals = newValue.split('.')[1].length;
        } else {
          Nintegers = newValue.length;
        }
        if (Ncomma < 2) {
          if (Ndecimals < 3 && Nintegers < 4) {
            if (oldValue === '' || oldValue === '0') {
              this.pushEntrada(this.entrada, +this.order);
              return this.order;
            } else {
              this.pushEntrada(this.entrada, +newValue);
              return newValue;
            }
          } else {
            return oldValue;
          }
        } else {
          return oldValue;
        }
    }
  }

  public pushEntrada(entrada, cantidad){
          //Controlamos el maximo 
          cantidad = entrada.Maximo > cantidad ? cantidad : Number.parseInt(entrada.Maximo);
    if (cantidad === 0) {
      this.deleteEntrada(entrada);
      this.getCantidadTotalPorEntrada();
      return;
    }

    const entradas = this.listaEntradasSeleccionadasSubject.value || [];
    if (this.listaEntradasSeleccionadasSubject.value && this.listaEntradasSeleccionadasSubject.value.find(m => m.ItemId === entrada.ItemId)) {
      this.listaEntradasSeleccionadasSubject.value.find(m => m.ItemId === entrada.ItemId).cantidad = cantidad;
    } else {
      entradas.push({...entrada, cantidad: cantidad, DNIAbonadosExt: this.codigoClienteValue.NumeroDocumento, desdeAsociado: true, codigoAsociado: this.codigoClienteValue});
    }
    this.setListaEntradasSeleccionadas(entradas);
    this.getCantidadTotalPorEntrada();
    ;
  }

  private deleteEntrada(entrada) {
    const entradas = this.listaEntradasSeleccionadasSubject.value;
    entradas.filter(m => m.ItemId === entrada.ItemId);
    this.setListaEntradasSeleccionadas(entradas);
    ;
  }


  private getCantidadTotalPorEntrada() {
    const entradas = this.listaEntradasSeleccionadasSubject.value;
    entradas.forEach(element => {     
//      this.ventaService.sendEntradas(element, element.cantidad);
    });
  }

  getCantidadTotal() {
    const entradas = this.listaEntradasSeleccionadasSubject.value;
    if (!entradas) {
      return 0;
    }
    const cantidad = entradas
      .map(element => element.cantidad)
      .reduce((total, num) => total + num);
    return cantidad;
  }

  getPrecioTotal() {
    const entradas = this.listaEntradasSeleccionadasSubject.value;
    if (!entradas) {
      return 0;
    }
    const cantidad = entradas
      .map(element => element.cantidad)
      .reduce((total, num) => total + num);

    let ivaTotal = 0;
    let precioTotal = 0;
    entradas.forEach(element => {
      ivaTotal +=
      (Number.parseFloat(element.IVA) / 100) *
      Number.parseFloat(element.PrecioBase.replace(/,/, '.')) *
      cantidad;
      ivaTotal = Math.round(ivaTotal * 100) / 100;
      precioTotal =
      precioTotal +
      Number.parseFloat(element.PrecioBase.replace(/,/, '.')) * cantidad;
      precioTotal = Math.round(precioTotal * 100) / 100;
    });
    return ivaTotal + precioTotal;
  }

  public reset (){
    this.entradasDisponiblesSubject.next(undefined);
    this.entradasConsumidasSubject.next(undefined);
    this.listaEntradasSeleccionadasSubject.next(undefined);
    this.tipoClienteSubject.next(undefined);
    this.mensajeErrorSubject.next(undefined);
  }

















  
}
