import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumenVentaComponent } from './components/resumen-venta/resumen-venta.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { SharedModule } from 'src/app/shared/shared.module';
import { DetalleVentaComponent } from './components/detalle-venta/detalle-venta.component';
import { ImprimirVentaComponent } from './components/imprimir-venta/imprimir-venta.component';
//import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OperacionesVentaComponent } from './components/detalle-venta/operaciones-venta/operaciones-venta.component';
import { DetalleBoxComponent } from './components/detalle-venta/detalle-box/detalle-box.component';
import { BuscadorAlbaranComponent } from './components/detalle-venta/buscador-albaran/buscador-albaran.component';
import { AlbaranPrevioComponent } from './components/detalle-venta/albaran-previo/albaran-previo.component';
import { AlbaranSiguienteComponent } from './components/detalle-venta/albaran-siguiente/albaran-siguiente.component';
import { ResumenAlbaranComponent } from './components/resumen-albaran/resumen-albaran.component';
import { HttpClientModule } from '@angular/common/http';
import { GestionarVentaComponent } from './gestionar-venta.component';
import { TipoImpresionPipe } from './pipes/tipo-impresion.pipe';
import { TipoOperacionPipe } from './pipes/tipo-operacion.pipe';
import { TipoAccionPipe } from './pipes/tipo-accion.pipe';
// import { FormaPagoPipe } from './pipes/forma-pago.pipe';
import { FechaPipe } from './pipes/fecha.pipe';
import { SelectorDevolucionComponent } from './components/selector-devolucion/selector-devolucion.component';
//import { TranslateService } from 'src/app/shared/services/translate.service'; // Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
import { SelectorPagoDevolucionComponent } from './components/selector-devolucion/selector-pago-devolucion.component';
import { gestionarVentaRoutingModule } from './gestionar-venta.routing.module';

//<< Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
// export function setupTranslateFactory(
//   service: TranslateService): Function {
//   return () => service.use(navigator.language);
// }
// Yaribel 20210105 >>

@NgModule({
  declarations: [GestionarVentaComponent, ResumenVentaComponent, DetalleVentaComponent, ImprimirVentaComponent, OperacionesVentaComponent,
    DetalleBoxComponent, BuscadorAlbaranComponent, AlbaranPrevioComponent, AlbaranSiguienteComponent, ResumenAlbaranComponent,
    TipoImpresionPipe,
    TipoOperacionPipe,
    TipoAccionPipe,
    // FormaPagoPipe,
    FechaPipe,
    SelectorDevolucionComponent,
    SelectorPagoDevolucionComponent],
  imports: [
    CommonModule,
    //RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    SharedModule,
    HttpClientModule, 
    gestionarVentaRoutingModule
  ],
  //<< Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
  // providers: [
  //   TranslateService
  // ]
  // Yaribel 20210105 >>
})
export class GestionarVentaModule { }
