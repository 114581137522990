import { CustomerDisplayType } from './customerDisplayType.enum';
import { Injectable } from '@angular/core';
import { VisorCustomerDisplay } from './visorCustomerDisplay';
import { CustomerDisplay } from './customerDisplay.interface';
import { VirtualCustomerDisplay } from './virtualCustomerDisplay';


@Injectable({
    providedIn: 'root'
  })
export class CustomerDisplayFactory {
    public static getCustomerDisplay(customerDisplayType: CustomerDisplayType): CustomerDisplay {
        if (customerDisplayType === CustomerDisplayType.VISOR) {
            return new VisorCustomerDisplay();
        }
        if (customerDisplayType === CustomerDisplayType.VIRTUAL) {
            return new VirtualCustomerDisplay();
        }
    }
}
