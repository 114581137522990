import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'date'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, formato: string, locale: string): any {
    var datePipe = new DatePipe(locale);
    value = datePipe.transform(value, formato);
    return value;
    //return moment(value, formato).locale(locale).format('L');
  }

}
