import { Printer } from '../printer.interface';
import { PurchaseInvoiceOptions } from '../printOptions';
import { PrintableType } from '../printableType.enum';

export class PurchaseInvoice implements Printer {

    private documentBody;
    private type: PrintableType;

    paint(options: PurchaseInvoiceOptions): Printer {
        this.documentBody = 'data:application/pdf;base64,' + options.document + '\n';
        return this;
    }
    print() {
        const downloadLink = document.createElement('a');
        const fileName = 'factura.pdf';
        downloadLink.href = this.documentBody;
        downloadLink.download = fileName;
        downloadLink.click();
        // OsConnectionFactory.getConnection(ConnectionType.VIRTUAL).print(this.documentBody, this.type);
    }
}
