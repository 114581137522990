import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/app/app.configuration';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  shareReplay
} from 'rxjs/operators';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FacturaService {
  private clienteSeleccionadoSubject: BehaviorSubject<any>;
  public clienteSeleccionado: Observable<any>;
  private listaClientes: any;
  private ultimaActualizacionClientes: any;
  private textoBuscadorSubject: BehaviorSubject<any>;
  public textoBuscador:Observable<any>;


  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private albaranService: AlbaranService
  ) {
    
    this.clienteSeleccionadoSubject = new BehaviorSubject(undefined);
    this.clienteSeleccionado = this.clienteSeleccionadoSubject.asObservable();
    this.textoBuscadorSubject = new BehaviorSubject(undefined);
    this.textoBuscador = this.textoBuscadorSubject.asObservable();
  }

  public get clienteSeleccionadoValue() {
    return this.clienteSeleccionadoSubject.value;
  }

  setClienteSeleccionadoValue(cliente) {
    this.clienteSeleccionadoSubject.next(cliente);
  }
  public get textoBuscadorValue() {
    return this.textoBuscadorSubject.value;
  }

  settextoBuscadorValue(texto) {
    this.textoBuscadorSubject.next(texto);
  }

  crearFactura(datosFactura) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CIFCliente: datosFactura.codigoLegal,
      NombreCliente: datosFactura.nombre,
      DomicilioCliente: datosFactura.direccion,
      LocalidadCliente: datosFactura.ciudad,
      CPCliente: datosFactura.codigoPostal,
      Provincia: datosFactura.provincia,
      Pais: datosFactura.pais,
      Comentarios: datosFactura.comentarios,
      Ventas: this.albaranService.albaranValue.NumVenta,
      GuardarInfo: '0',
      idContacto: '0',
      ClienteCodigo: datosFactura.codigoCliente,
      UserName: JSON.parse(localStorage.getItem('currentUser'))
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GenerarFactura`, body)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  recuperarFactura(datosFactura) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      LetraFactura: datosFactura.SerieFactura,
      NumFactura: datosFactura.NumFacturaId
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/RecuperarFacturaReferencias`,
        body
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /*   pdfFactura(datosFactura) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      LetraFactura: datosFactura.FraSerie,
      NumFactura: datosFactura.FraNumero
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/FacturaPDF`, body).pipe(
      map((res: any) => {
        return res;
      })
    );
  } */

  pdfFactura(datosFactura) {
    // 
    let body = {};
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    if ('FraNumero' in datosFactura) {
      body = {
        ConexionIacpos: conexionId,
        LetraFactura: datosFactura.FraSerie,
        NumFactura: datosFactura.FraNumero
      };
    } else if ('NumFacVenta' in datosFactura) {
      body = {
        ConexionIacpos: conexionId,
        LetraFactura: datosFactura.SerieVenta,
        NumFactura: datosFactura.NumFacVenta
      };
    } else if ('FacturaNumero' in datosFactura) {
      body = {
        ConexionIacpos: conexionId,
        LetraFactura: datosFactura.FacturaSerie,
        NumFactura: datosFactura.FacturaNumero
      };
    }else {
      body = {
        ConexionIacpos: conexionId,
        LetraFactura: datosFactura.SerieFactura,
        NumFactura: datosFactura.NumFacturaId
      };
    }
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/RecuperarFactura`, body)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  recuperarDesgloseIVAFactura(datosFactura){
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      LetraFactura: datosFactura.LetraFactura,
      NumFactura: datosFactura.NumFactura
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/RecuperarFacturaIva`,
        body
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /*   pdfFacturaGestionar(datosFactura) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      LetraFactura: datosFactura.SerieVenta,
      NumFactura: datosFactura.NumFacVenta
    };
    return this.http.post(`${this.config.getConfig('API_URL')}api/FacturaPDF`, body).pipe(
      map((res: any) => {
        return res;
      })
    );
  } */

  recuperarFacturaRelacionAnular(datosFactura) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      LetraFactura: datosFactura.FacturaSerie,
      NumFactura: datosFactura.FacturaNumero
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/RecuperarFacturaRelacion`,
        body
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  recuperarFacturaRelacion(datosFactura) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      LetraFactura: datosFactura.SerieFactura,
      NumFactura: datosFactura.NumFacturaId
    };
    return this.http
      .post(
        `${this.config.getConfig('API_URL')}api/RecuperarFacturaRelacion`,
        body
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  buscarCliente(terms: Observable<any>) {
    return terms
      .pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      //.pipe(switchMap(input => this.getClientes(input.nombre)));
      // .pipe(switchMap(input => this.obtenerClientes(input.nombre)));
      .pipe(
        switchMap(input => {
          if (input.search_field.length > 2) {
            return this.obtenerClientes(input.search_field);
          } else {
            return [];
          }
        })
      );
  }

  buscarClientes(query: string, validarBaja = '1') {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPVId: '1',
      Filtro: query,
      ValidarBaja: validarBaja
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GetClientesXFiltro`, body)
      .pipe(shareReplay(1))
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return [];
          }

          let ResultadoBuscadorClientes = res.DatosResult.ListadoCliente;
          let respuesta = {ResultadoBuscadorClientes: ResultadoBuscadorClientes};
          return respuesta;
        })
      );
  }

  buscarClienteXCodigo(Codigo){
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      CodigoCliente: Codigo,
      ValidarBaja: '1'
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GetClientesXFiltro`, body)
      .pipe(shareReplay(1))
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return [];
          }

          let ResultadoBuscadorClientes = res.DatosResult.ListadoCliente;
          let respuesta = {ResultadoBuscadorClientes: ResultadoBuscadorClientes};
          return respuesta;
        })
      );
  }

  // buscarClienteXCodigo(Codigo) {

  //   return this.buscarClientes(Codigo);
  //   const conexionId = JSON.parse(localStorage.getItem('conexionId'));
  //   const body = {
  //     ConexionIacpos: conexionId,
  //     Usuario:"",
  //     Password:"",
  //     NombreOriginal: "",
  //     CodigoCliente: Codigo
  //   };
  //   return this.http
  //     .post(`${this.config.getConfig('API_URL')}api/BuscadorCliente`, body);
  
  
  
  
  // }

  getClientes(texto) {
    return  this.buscarClientes(texto);
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Usuario:"",
      Password:"",
      NombreOriginal: texto,
      CodigoCliente: ""
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/BuscadorCliente`, body)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getClientesAsync(texto) {
    return this.buscarClientes(texto).toPromise();
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Usuario:"",
      Password:"",
      NombreOriginal: "",
      CodigoCliente: ""
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/BuscadorCliente`, body)
      .pipe(
        map((res: any) => {
          return res;
        })
      ).toPromise();
  }
  // buscarClienteXCodigo(Codigo) {
  //   const conexionId = JSON.parse(localStorage.getItem('conexionId'));
  //   const body = {
  //     ConexionIacpos: conexionId,
  //     Usuario:"",
  //     Password:"",
  //     NombreOriginal: "",
  //     CodigoCliente: Codigo
  //   };
  //   return this.http
  //     .post(`${this.config.getConfig('API_URL')}api/BuscadorCliente`, body);
  // }

  // getClientes(texto) {
  //   const conexionId = JSON.parse(localStorage.getItem('conexionId'));
  //   const body = {
  //     ConexionIacpos: conexionId,
  //     Usuario:"",
  //     Password:"",
  //     NombreOriginal: texto,
  //     CodigoCliente: ""
  //   };
  //   return this.http
  //     .post(`${this.config.getConfig('API_URL')}api/BuscadorCliente`, body)
  //     .pipe(
  //       map((res: any) => {
  //         return res;
  //       })
  //     );
  // }

  // getClientesAsync(texto) {
  //   const conexionId = JSON.parse(localStorage.getItem('conexionId'));
  //   const body = {
  //     ConexionIacpos: conexionId,
  //     Usuario:"",
  //     Password:"",
  //     NombreOriginal: "",
  //     CodigoCliente: ""
  //   };
  //   return this.http
  //     .post(`${this.config.getConfig('API_URL')}api/BuscadorCliente`, body)
  //     .pipe(
  //       map((res: any) => {
  //         return res;
  //       })
  //     ).toPromise();
  // }
  async obtenerClientes(texto) 
  {
    //Cargamos la lista solo si no hay lista o cambiamos el texto de busqueda
    if(!this.listaClientes || !texto.startsWith(this.textoBuscadorValue) || this.textoBuscadorValue == "")
    {
      this.settextoBuscadorValue(texto);
      this.listaClientes = await this.getClientesAsync(texto);
    }
    var respuesta = JSON.parse(JSON.stringify(this.listaClientes)); 
    var filtrado = this.listaClientes.ResultadoBuscadorClientes || [];
    respuesta.ResultadoBuscadorClientes = filtrado.filter(elemento => elemento.NombreCliente.toUpperCase().includes(texto.toUpperCase()) ||
                                                                elemento.NombreClienteFiscal.toUpperCase().includes(texto.toUpperCase()) ||
                                                                elemento.Codigo.toUpperCase().includes(texto.toUpperCase()) || 
                                                                elemento.PersonaCobro.toUpperCase().includes(texto.toUpperCase()) ||
                                                                elemento.CIFoDNI.toUpperCase().includes(texto.toUpperCase()) ||
                                                                elemento.CIFoDNIFiscal.toUpperCase().includes(texto.toUpperCase()) ||
                                                                elemento.EMail.toUpperCase().includes(texto.toUpperCase()) ||
                                                                elemento.Telefono.toUpperCase().includes(texto.toUpperCase()));
    return respuesta; 

    // // //Cargamos la lista  la actualizamos solo si ha pasado un minuto
    // // if(!this.listaClientes || !this.ultimaActualizacionClientes || this.ultimaActualizacionClientes.add(1, 'minutes') < moment())
    // // {
      
    // //   this.ultimaActualizacionClientes = moment();
    // //   this.listaClientes = await this.getClientesAsync("");
    // //   //texto = texto != undefined && texto != null && texto != ''? texto.toUpperCase() : ''; 
    // //   /*WHERE ((UPPER(C.chNombre)  LIKE '%' + UPPER(@chFiltro) + '%')  
    // //   OR (UPPER(C.chApellido1) LIKE '%' + UPPER(@chFiltro) + '%')  
    // //   OR (UPPER(C.chApellido2) LIKE '%' + UPPER(@chFiltro) + '%')  
    // //   OR (UPPER(C.chContacto)  LIKE '%' + UPPER(@chFiltro) + '%')  
    // //   OR (UPPER(C.chCif)   LIKE '%' + UPPER(@chFiltro) + '%')  
    // //   OR (UPPER(C.chTelefono)   LIKE '%' + UPPER(@chFiltro) + '%')  
    // //   OR (UPPER(C.chEmail)   LIKE '%' + UPPER(@chFiltro) + '%')   */
    // // }
    // // var respuesta = JSON.parse(JSON.stringify(this.listaClientes)); 
    // var filtrado = this.listaClientes.ResultadoBuscadorClientes;
    // if(texto && texto != null && texto != undefined && texto != '')
    // {
    // respuesta.ResultadoBuscadorClientes = filtrado.filter(elemento => elemento.NombreClienteOriginal.toUpperCase().includes(texto.toUpperCase()) || 
    //     elemento.NombreClienteFiscal.toUpperCase().includes(texto.toUpperCase()) ||
    //     elemento.CodigoCliente.toUpperCase().includes(texto.toUpperCase()) ||
    //     elemento.PrimerApellidoCliente.toUpperCase().includes(texto.toUpperCase()) ||
    //     elemento.SegundoApellidoCliente.toUpperCase().includes(texto.toUpperCase()) ||
    //     elemento.PersonaCobro.toUpperCase().includes(texto.toUpperCase()) ||
    //     elemento.CIFoDNI.toUpperCase().includes(texto.toUpperCase()) ||
    //     elemento.CIFoDNIFiscal.toUpperCase().includes(texto.toUpperCase()) ||
    //     elemento.EMail.toUpperCase().includes(texto.toUpperCase()) ||
    //     elemento.Telefono.toUpperCase().includes(texto.toUpperCase()) );
    // //respuesta.ResultadoBuscadorClientes = filtrado;
    // }
    // return respuesta; 
  }

  getPaises(){
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/Configuracion/GetPaises`,
      body
    );
  }

}
