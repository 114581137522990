import { CustomerDisplay } from './customerDisplay.interface';
import { OsConnectionFactory } from '../connection/osConnection.factory';
import { ConnectionType } from '../connection/connectionType.enum';
import { CustomerDisplayMessage } from './customerDisplayMessage.model';
import { OsConnection } from '../connection/osConnection.interface';

export class VirtualCustomerDisplay implements CustomerDisplay {


    osConnection: OsConnection;
    constructor() {
        this.osConnection = OsConnectionFactory.getConnection(ConnectionType.VIRTUAL);
    }

    printMessage(message: CustomerDisplayMessage) {
    }

    setToBlank() {
    }

}
