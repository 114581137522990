import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { SidebarNavigationService } from 'src/app/shared/services/sidebar-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class TabsNavigationService {


  tabs; 
  constructor(private sidebarService: SidebarNavigationService) { }

  public getTabs() {
    const padre = this.sidebarService.seccionSelecionadaValue;
    return of(this.sidebarService.secciones.filter(x=> x.ModuloIdPadre == padre.PkId));
  }
}
