export const environment = {
    console: true,
    devUser : '',
    devPassword: '',
    ADMIN_AGENDA_URL: "https://admin.realescuela.iacpospre.com/admin/Agenda.aspx",
    ADMIN_INDEX_URL: "https://admin.realescuela.iacpospre.com/admin/MenuOperacionesEspeciales.aspx",
    API_URL: "https://apipre.apiacpos.com/demo_butacas_bridge/",
    CODIGO_APLICACION: "PVKORE",
    ID_APLICACION: "1",
    TPV_PATH: "TPV01",
    NUMSERIE:"TAQU$A$N0QBO3AQ0$A$ILLA$A$CVW1R9537",
    chClaveEmpresa: "REALESCUELA",
    chEntornoConexion: "PREPRODUCCION",
    chNombreProyecto: "TAQUILLA",
    chOrigenConexion: "TAQUILLA",
    production: false,
    svgFolder: "development"
};
