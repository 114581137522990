import { Printer } from '../printer.interface';
import * as jspdf from 'jspdf';
import { PrintableType } from '../printableType.enum';
import { OsConnectionFactory } from '../../connection/osConnection.factory';
import { ConnectionType } from '../../connection/connectionType.enum';
import { LabelOptions } from '../printOptions';

export class Label implements Printer {
  private documentBody;
  type: PrintableType;
  constructor() {
    this.type = PrintableType.LABEL;
    this.documentBody = new jspdf('p', 'mm', 'a4');
  }

  paint(options: LabelOptions): Printer {
    let y = 0;
    const img = new Image();
    img.src = 'assets/img/etiqueta-logo.png';
    this.documentBody.setFontSize(8);
    this.documentBody.text(
      options.data.localizador + ' - ' + options.data.integrantes,
      6,
      (y += 5),
      'left'
    );
    this.documentBody.addImage(img, 'png', 6, (y += 3), 30, 5, 'image', 'NONE', 0);
    this.documentBody.text(options.data.fecha, 20, (y += 13), 'center');

    this.documentBody.setFontSize(25);
    this.documentBody.text(options.data.hora.toString(), 13, (y += 8), 'center');
    this.documentBody.text(':', 20, y, 'center');
    this.documentBody.text(options.data.minutos.toString(), 27, y, 'center');

    this.documentBody.setFontSize(8);
    this.documentBody.text(options.data.textoManual, 20, (y += 8), 'center');
    return this;
  }

  print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    connection.print(this.documentBody, this.type);
  }
}
