import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Albaran } from '../../models/albaran.model';
import { AlbaranService } from '../../services/albaran.service';
import { Observable } from 'rxjs';
import { PrintService } from 'src/app/shared/services/print.service';
import { FacturaService } from 'src/app/shared/services/factura.service';
import { PagerService } from 'src/app/shared/services/pager.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterService } from 'src/app/shared/services/filter.service';
import * as moment from 'moment';

@Component({
  selector: 'ticketing-imprimir-venta',
  templateUrl: './imprimir-venta.component.html',
  styleUrls: ['./imprimir-venta.component.scss']
})
export class ImprimirVentaComponent implements OnInit {
  albaran$: Observable<Albaran>;

  albaran: Albaran;
  arr = [];
  selectedItems = [];

  @ViewChild('checkAllSwitch') checkAllSwitch: ElementRef;

  pager: any;
  pagedItems: any[];

  busquedaForm: FormGroup;
  valoresImpresion: any;

  constructor(
    private albaranService: AlbaranService,
    private facturaService: FacturaService,
    private printService: PrintService,
    private formControlService: FormControlService,
    private filterService: FilterService,
    private formBuilder: FormBuilder,
    private pagerService: PagerService
  ) {}

  ngOnInit() {
    this.busquedaForm = this.formBuilder.group({
      query: ''
    });
    this.valoresImpresion = this.printService.opcionesDeImpresionValue;
    this.albaran = this.albaranService.albaranValue;
    this.arr = this.generateItems(
      this.albaran.AlbaranTicketsDatosImpresion,
      this.albaran.AlbaranItems
    );
    const listaCompleta = this.arr;
    this.busquedaForm.valueChanges.subscribe(val => {
      if (val.query === '') {
        this.arr = listaCompleta;
      } else {
        this.arr = [];
        listaCompleta.forEach(item => {
          if(val.query === item.numTickets.toString()) {
            this.arr.push(item)
          }
        })
      }
      this.setPage(1);
    });
    this.setPage(1);
    this.selectAll();
  }

  checkEntrada(event, ticket) {
    if (event.srcElement.checked) {
      this.selectedItems.push(ticket);
    } else {
      this.selectedItems = this.selectedItems.filter(e => e.id !== ticket.id);
    }
    if (
      this.selectedItems.length ===
      this.arr.filter(e => e.Devuelta !== '1').length
    ) {
      this.checkAllSwitch.nativeElement['checked'] = true;
    } else {
      this.checkAllSwitch.nativeElement['checked'] = false;
    }
    if (this.selectedItems.length === 0) {
      this.checkAllSwitch.nativeElement['checked'] = false;
    }
  }

  isChecked(ticket) {
    return this.selectedItems.find(e => e.id === ticket.id);
  }

  checkAllEntradas(event) {
    if (event.srcElement.checked) {
      this.selectAll();
    } else {
      this.unselectAll();
    }
  }

  private selectAll() {
    this.arr.forEach((e, i) => {
      // if (!this.isChecked(e) && e.Devuelta !== '1') {
      if (!this.isChecked(e) && e.yaDevuelto === false && e.imprimir === true ) { //Yaribel 20210318
        this.selectedItems.push(e);
      }
    });
  }

  private unselectAll() {
    this.selectedItems = [];
  }

  groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
  }

 /* private generateItems(tickets, items) {
    return tickets.map(ticket => {
      const datosEntrada = items.find(i => i.ItemId === ticket.ItemId);
      datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
      datosEntrada.idTarifa = datosEntrada.ItemId;
      const yaDevuelto = ticket.Devuelta === '0' ? false : true;
      const imprimir = ticket.ImprimirTicket === 'True' ? true : false;
      const nTicket =
        this.albaran.TipoImpresion === '01'
          ? +ticket.OrdenCjta
          : +ticket.NumTicket;
      return {
        ...ticket,
        imprimir: imprimir,
        yaDevuelto: yaDevuelto,
        id: nTicket,
        itemInfo: datosEntrada
      };
    });
  }*/

  private generateItems(tickets, albaranes) {
    // 
    const ticketsAgrupados = this.groupBy(tickets, c =>
      c.NumOrdenPromo !== '' ? c.NumOrdenPromo : c.RegistroTablaId
    );
    // 
    // 
    const respuesta = [];
    const numOrdenPromoId = [];
    for (const [key, ticket] of Object.entries(ticketsAgrupados)) {
      // 
      // 
      if (Object.entries(ticket).length > 1) {
        let contador = 0;
        while (contador <= 1) {
          
          if (ticket[contador].TipoProducto === '1') {
            const datosEntrada = albaranes.find(i => {
              return i.ItemId === ticket[contador].ItemId && i.TipoProducto == 1 && i.CodigoPromocional == ticket[contador].CodigoPromocional;
            });
            // 
            datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
            datosEntrada.idTarifa = datosEntrada.ItemId;
            datosEntrada.TipoEntradaId = datosEntrada.ItemId;
            const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
            const imprimir = (ticket[contador].ImprimirTicket === 'True' || ticket[contador].ImprimirTicket === '1') ? true : false;
            respuesta.push({
              tickets: ticket,
              imprimir: imprimir,
              yaDevuelto: yaDevuelto,
              id: +ticket[contador].NumTicket,
              numTickets: +ticket[contador].NumTicket,
              itemInfo: datosEntrada,
              OrdenCjta:+ticket[contador].OrdenCjta,

            });
          } else if (ticket[contador].TipoProducto === '2') {
            // 
            
            if (!numOrdenPromoId.includes(ticket[contador].NumOrdenPromo)) {
              const datosEntrada = albaranes.find(item => {
                return item.ItemId === ticket[contador].TipoPromocionId && item.TipoProducto == 2 && item.CodigoPromocional == ticket[contador].CodigoPromocional;
              });
              // 
              datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
              datosEntrada.idTarifa = datosEntrada.ItemId;
               datosEntrada.TipoPromocionId = datosEntrada.ItemId;
              const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
              //const imprimir =  (ticket[contador].ImprimirTicket === 'True' || ticket[contador].ImprimirTicket === '1') ? true : false;             
              let imprimir = false;
              for (let ix = 0; ix < Object.entries(ticket).length; ix++) {
                if(ticket[ix].ImprimirTicket === 'True' || ticket[ix].ImprimirTicket === '1')
                  imprimir = true;              
              }
              // 
              // 
              numOrdenPromoId.push(ticket[contador].NumOrdenPromo);
              const promocionEntradas = [];
              datosEntrada.PromocionEntradas.forEach(entrada => {
                const tempObject = Object.assign({}, entrada, {
                  NumOrdenPromo: ticket[contador].NumOrdenPromo
                });
                promocionEntradas.push(tempObject);
              });
              // 
              const temp = Object.assign({}, datosEntrada, {
                PromocionEntradas: promocionEntradas
              });
              let numTickets = '';
              for (const [k, item] of Object.entries(ticket)) {
                numTickets = '' + numTickets + ', ' + item.NumTicket;
              }
              numTickets = numTickets.substr(1);
              // 
              respuesta.push({
                tickets: ticket,
                imprimir: imprimir,
                yaDevuelto: yaDevuelto,
                id: +ticket[contador].NumTicket,
                numTickets: numTickets,
                itemInfo: temp,
                OrdenCjta:+ticket[contador].OrdenCjta,
              });
            }
          }
          contador ++;
        }
      } else {
        if (ticket[0].TipoProducto === '1') {
          const datosEntrada = albaranes.find(i => {
            return i.ItemId === ticket[0].ItemId && i.TipoProducto == 1  && i.CodigoPromocional == ticket[0].CodigoPromocional;
          });
          // 
          datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
          datosEntrada.idTarifa = datosEntrada.ItemId;
          datosEntrada.TipoEntradaId = datosEntrada.ItemId;
          const yaDevuelto = ticket[0].Devuelta === '0' ? false : true;
          const imprimir = (ticket[0].ImprimirTicket === 'True' || ticket[0].ImprimirTicket === '1') ? true : false;
          respuesta.push({
            tickets: ticket,
            imprimir: imprimir,
            yaDevuelto: yaDevuelto,
            id: +ticket[0].NumTicket,
            numTickets: +ticket[0].NumTicket,
            itemInfo: datosEntrada,
            OrdenCjta:+ticket[0].OrdenCjta,
          });
        } else if (ticket[0].TipoProducto === '2') {
          // 
          
          if (!numOrdenPromoId.includes(ticket[0].NumOrdenPromo)) {
            const datosEntrada = albaranes.find(item => {
              //return item.ItemId === ticket[0].ItemId && item.TipoProducto == 2;
              //return item.ItemId === ticket[0].TipoPromocionId && item.TipoProducto == 2; //Yaribel 20201127 Cambiamos esto para que evalue por item
              return item.ItemId === ticket[0].TipoPromocionId && item.TipoProducto == 2 && item.CodigoPromocional == ticket[0].CodigoPromocional; //Yaribel 20210607 Añadimos codigo promocional
            });
            // 
            datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
            datosEntrada.idTarifa = datosEntrada.ItemId;
             datosEntrada.TipoPromocionId = datosEntrada.ItemId;
            const yaDevuelto = ticket[0].Devuelta === '0' ? false : true;
            // const imprimir = (ticket[0].ImprimirTicket === 'True' || ticket[0].ImprimirTicket === '1') ? true : false;
            let imprimir = false;
            for (let ix = 0; ix < Object.entries(ticket).length; ix++) {
              if(ticket[ix].ImprimirTicket === 'True' || ticket[ix].ImprimirTicket === '1')
                imprimir = true;              
            }
            // 
            // 
            numOrdenPromoId.push(ticket[0].NumOrdenPromo);
            const promocionEntradas = [];
            datosEntrada.PromocionEntradas.forEach(entrada => {
              const tempObject = Object.assign({}, entrada, {
                NumOrdenPromo: ticket[0].NumOrdenPromo
              });
              promocionEntradas.push(tempObject);
            });
            // 
            const temp = Object.assign({}, datosEntrada, {
              PromocionEntradas: promocionEntradas
            });
            let numTickets = '';
            for (const [k, item] of Object.entries(ticket)) {
              numTickets = '' + numTickets + ', ' + item.NumTicket;
            }
            numTickets = numTickets.substr(1);
            // 
            respuesta.push({
              tickets: ticket,
              imprimir: imprimir,
              yaDevuelto: yaDevuelto,
              id: +ticket[0].NumTicket,
              numTickets: numTickets,
              itemInfo: temp,
              OrdenCjta:+ticket[0].OrdenCjta,
            });
          }
        }
      }
    }
    // 
    return respuesta;
  }

  private setPage(page: number): void {
    this.pager = this.pagerService.getPager(this.arr.length, page, 10);
    this.pagedItems = this.arr.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    this.pager.pages = this.pager.pages.filter(
      num => num <= this.pager.totalPages
    );

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }

  imprimir() {
    if (this.printService.opcionesDeImpresionValue.entradas) {
      //
      // 
      const idsSeleccionados = [];
      this.selectedItems.forEach(item => {
        if (!item.yaDevuelto) {
          const cadena = item.numTickets.toString();
          if (cadena.includes(',')) {
            const arraySelected = cadena.split(',');
            arraySelected.forEach(element => {
              const order = Number.parseInt(element, 10);
              idsSeleccionados.push(order);
            });
          } else {
            const order = Number.parseInt(cadena, 10);
            idsSeleccionados.push(order);
          }
        }
      });
      this.albaranService
        .reimprimirTicketsAlbaran(this.albaran.NumAlbaran, this.albaran.pkId)
        .subscribe(data => {
          const selectedQRs = [];
          // 
          data.ListadoCbQR.forEach(item => {
            // 
            // 
            const order = Number.parseInt(item.NumOrden, 10);
            // 
            if (idsSeleccionados.includes(order)) {
              
              selectedQRs.push(item);
            }
          });
          // 
          const QRs = { ListadoCbQR: [...selectedQRs] };
          const localizador = this.albaran.Localizador.length > 0? this.albaran.Localizador: this.albaran.NumVenta;

           //<<Yaribel 20210126 unificamos la impresion de tickets en uno solo
          // // filtrar aqui entradas seleccionadas para impresion parcial
          // this.printService.reimprimirTicketsAlbaran(
          //   this.albaran.TPVNombre,
          //   this.albaran.NumVenta,
          //   this.albaran.NumEntradas,
          //   this.selectedItems,
          //   this.albaran.FechaHoraEntrada.split(' ')[1],
          //   this.albaran.NumAlbaran,
          //   moment(this.albaran.AlbaranHistorial[0].Fecha).format('DD/MM/YYYY'),
          //   this.albaran.AlbaranHistorial[0].Hora.slice(0, -3),
          //   this.albaran.AlbaranRecintos,
          //   this.albaran.AlbaranMotivos,
          //   localizador,
          //   // data
          //   QRs,
          //   this.albaran.TipoImpresion
          // );

        
          // filtrar aqui entradas seleccionadas para impresion parcial
          this.printService.imprimirTicketUnico(
            this.albaran.TPVNombre,
            this.albaran.TPVId,
            this.albaran.NumAlbaran,
            QRs,
            this.albaran.FechaHoraEntrada.split(' ')[1],
            this.selectedItems,
            this.albaran.NumVenta,
            moment(this.albaran.AlbaranHistorial[0].Fecha).format('DD/MM/YYYY'),
            this.albaran.AlbaranHistorial[0].Hora.slice(0, -3),
            this.albaran.AlbaranRecintos,
            this.albaran.AlbaranMotivos,
            localizador,
            this.albaran.TipoImpresion,
            this.albaran.NumEntradas,
          );
          // Yaribel 20210126 >> 
        });

      // this.router.navigate(['venta', 'entradas']);
    }
  }

  focusValue(event) {
    this.formControlService.setCurrentFormGroup(
      this.busquedaForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  getNumTicket(item){
    if(+item.OrdenCjta > 0){
      return item.OrdenCjta;
    } 
    return  item.numTickets;
  }
}
