import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoOperacion'
})
export class TipoOperacionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case '00':
        return 'Venta';
      case '01':
        return 'Abono';
      default:
        return '';
    }
  }
}
