import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarNavigationService {
  private seccionesSubject: BehaviorSubject<any>;
  public secciones$: Observable<any>;

  private seccionSeleccionadaSubject: BehaviorSubject<any>;
  public seccionSeleccionada$: Observable<any>;


  constructor() {
    
    this.seccionesSubject = new BehaviorSubject([]);
    this.secciones$ = this.seccionesSubject.asObservable();

    this.seccionSeleccionadaSubject = new BehaviorSubject([]);
    this.seccionSeleccionada$ = this.seccionesSubject.asObservable();
  }

  setSecciones(secciones) {
    this.seccionesSubject.next(secciones);
  }

  get secciones() {
    return this.seccionesSubject.value;
  }
  
  setSeccionSeleccionada(seccion) {
    this.seccionSeleccionadaSubject.next(seccion);
  }

  get seccionSelecionadaValue() {
    return this.seccionSeleccionadaSubject.value;
  }

  getNavigation() {
    return this.secciones$;
  }

  blockSecciones(url?) {
    this.secciones.forEach(seccion => {
      if (seccion.nombre !== 'venta') {
        seccion.disabled = true;
      }
      if (seccion.nombre !== 'auditorio' && url !== '/venta/pago') {
        seccion.disabled = false;
      }
      if (seccion.nombre !== 'venta' && url !== '/venta/pago') {
        seccion.disabled = false;
      }
    });
  }

  getSeccionByUrl(url) {
    return this.secciones.find(seccion => seccion.url === url);
  }

  unblockVentaAuditorio() {
    this.secciones.forEach(seccion => {
      if (seccion.nombre !== 'venta') {
        seccion.disabled = true;
      }
      if (seccion.nombre !== 'auditorio') {
        seccion.disabled = false;
      }
      if (seccion.nombre !== 'venta') {
        seccion.disabled = false;
      }
    });
  }

  unblockSecciones() {
    this.secciones.forEach(seccion => {
      seccion.disabled = false;
    });
  }
}
