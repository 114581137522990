import { Component, OnInit, Input } from '@angular/core';

import { Albaran } from '../../../models/albaran.model';
import { ModalService } from 'src/app/shared/services/modal.service';
import { PermisosService } from 'src/app/core/security/permisos.service';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { Router } from '@angular/router';
import { AlbaranService } from '../../../services/albaran.service';
import { VentaService, StepperNavigationService, EntradasService, Carrito } from 'src/app/modules/venta';
import { NumpadService } from 'src/app/shared/services/numpad.service';
import * as moment from 'moment';
import { map, first } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/core';
import { AppConfiguration } from 'src/app/app.configuration';

@Component({
  selector: 'ticketing-operaciones-venta',
  templateUrl: './operaciones-venta.component.html',
  styleUrls: ['./operaciones-venta.component.scss']
})
export class OperacionesVentaComponent implements OnInit {
  @Input() albaran: Albaran;
  idioma = 'es-ES';
  constructor(
    private modalService: ModalService,
    private permisosService: PermisosService,
    private user: UserManagerService,
    private router: Router,
    private ventaService: VentaService,
    private albaranService: AlbaranService,
    private stepperNavigation: StepperNavigationService,
    private entradaService: EntradasService,
    private configuracionService: ConfigurationService,
    private numpadService: NumpadService,
    private config: AppConfiguration,
  ) {}

  ngOnInit() {
    this.configuracionService.datosInicio
    .subscribe(conf => (this.idioma = conf.Idioma));
  }

  abrirOpcionesDeImpresion() {
    this.modalService.open('impresion');
  }

  abrirModalConfirmacionAnulacion() {
    this.modalService.open('confirmarAnulacionVenta');
    //this.router.navigate(['/gestionar-venta/devolucion-parcial']);
    this.albaranService.anulacionTotal.next(true);
  }

  abrirModalDeImprimirFactura() {
    const cliente = this.mapCliente();
    this.ventaService.setClienteSeleccionadoValue(cliente);
    this.modalService.open('facturar');
  }

  puedeAnularVenta() {
    return this.albaran.TieneDevoluciones === '0' &&
      this.albaran.EsDevolucion === 'False'
      ? true
      : false;
  }

  puedeDevolverVenta() {
    let ok = true;
    if (this.albaran.AlbaranRecintos) {
      this.albaran.AlbaranRecintos.forEach(recinto => {
        if (recinto.Periodo == '1') {
          ok = false;
        }
      });
    }
    return this.albaran.TieneDevoluciones !== '2' &&
      this.albaran.EsDevolucion === 'False' && ok
      ? true
      : false;
  }

  devolucionParcial() {
    this.router.navigate(['/gestionar-venta/devolucion-parcial']);
  }

  puedeFacturar() {
    return this.albaran.TieneDevoluciones === '0' &&
      this.albaran.EsDevolucion === 'False' &&
      this.albaran.Facturado === '0'
      ? true
      : false;
  }

  puedeImprimirVenta() {
    return true; //this.albaran.EsDevolucion === 'False' ? true : false;
  }

  private generarMediosPagoVenta(): any[] {
    const arrayFop = [];
    this.albaran.AlbaranFOPs.forEach(item => {
      const itemFop = {FOP: item.FOP, Importe: item.Importe, TarjetaMonederoCodigo: item.TarjetaMonederoCodigo } //Yaribel 11112020 Modificamos itemFop para que obtenga  la tarjetaMonederoCodigo
      arrayFop.push(itemFop);
    });
    return arrayFop;
  }

  async modificarVenta() {
    
    await this.cargarDatosVenta();

    this.ventaService.carritoValue.albaran = {
      pkId: this.albaran.pkId,
      TPVId: this.albaran.TPVId,
      NumAlbaran: this.albaran.NumAlbaran,
      MediosPago: this.generarMediosPagoVenta()
    };
    this.ventaService.setFechaSeleccionadaValue(
      moment(this.ventaService.carritoValue.entradas[0].entrada.Fecha)
    );
    this.ventaService.carritoModificado = false;
    this.ventaService.setHayDescuento(false); //Yaribel 20210730  cliente Mapfre VSTS 8370
    this.stepperNavigation.desbloquearNavegacionPorPago();
    this.stepperNavigation.continuarVenta.next(false);
    this.stepperNavigation.resetearPasos();
    this.ventaService.carritoSubject.pipe(first()).subscribe((res1: Carrito) => {
      if (res1.carrito) {
        res1.setCarritoModificacionCarritoValue(res1.carrito);
        this.ventaService.carritoSubject.next(res1);
      }
    });
    if (this.config.getConfig('COMPRAINICIO')) {
      this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
    } else {
      this.router.navigate(['']);
    }
  }

  puedeModificarVenta() {
    return this.albaran.TieneDevoluciones === '0' &&
      this.albaran.EsDevolucion === 'False' &&
      this.albaran.Facturado === '0'
      ? true
      : false;
  }

  tienePermiso(accion: string) {
    const conf = {
      toast: false,
      elevarUsuario: false
    };
    return this.permisosService.puedeAccionar(
      this.user.currentUserValue,
      accion,
      conf
    );
  }

  private async cargarDatosVenta() {
    
    this.ventaService.clearCarrito();
    const entradas = await this.mapEntradas();
    
    ;
    entradas.forEach(entrada => {
      for (let index = 0; index < +entrada.entrada.cantidad; index++) {
        if (entrada.entrada.TipoProducto === '1') {
          this.ventaService.sendEntrada(entrada.entrada);
          this.numpadService.entrada = entrada.entrada;
        } else if (entrada.entrada.TipoProducto === '2') {
          const orderPromo = [entrada.entrada.NumOrdenPromos[index]];
          const precioBono = Number(
            Number.parseFloat(
              entrada.entrada.PrecioEnDivisaReferencia.replace(/,/, '.')
            )
          ).toFixed(2);

          const producto = Object.assign({}, entrada.entrada, {
            NumOrdenPromos: orderPromo,
            PromocionEntradas: entrada.entrada.PromocionEntradas,
            PrecioEnDivisaReferencia: precioBono
          });

          const entradaBase = Object.assign({}, entrada, { entrada: producto });
          this.ventaService.sendEntrada(producto);
          this.numpadService.entrada = producto;
        }
      }
    });
    const cliente = this.mapCliente();
    if (this.albaran.AlbaranRecintos) {
      const horarios = this.mapHorarios(entradas);
      const horariosPrevios = this.mapHorarios(entradas);
      this.ventaService.carritoValue.horarios = horarios;
      this.ventaService.carritoValue.horariosPrevios = horariosPrevios;
    }
    this.ventaService.carritoValue.entradasPrevias = entradas;
    this.ventaService.setClienteSeleccionadoValue(cliente);
  }

  async mapEntradas(): Promise<any[]> {
    
    const entradas: any = this.albaran.AlbaranItems.map((producto, index) => {      
      //TODO: Como en la taquilla vieja no se contemplan las nominaciones previas
      //TODO: Queda pensar como hacer butacas
      /*ListaButacas: producto.ListaButacas,
        FechaHoraAccesoLocalizadorButaca:
          producto.ListaButacas.length > 0
            ? producto.ListaButacas[0].FechaHora
            : undefined
      /*if (producto.PersonasReserva.length > 0) {
        if (producto.TipoProducto === '2') {
          producto.PersonasReserva.forEach(item => {
            producto.PromocionEntradas[
              parseInt(item.numOrden, 10) - 1
            ].EntradasVendidasPersonas = [item];
          });
        }
      }*/
      const objCaracteristicas = [];
      if (producto.TipoCentro && producto.TipoCentro != '') {
        objCaracteristicas.push({
          Tipo: producto.TipoCentro, Rango: producto.RangoEdad, Nivel: producto.Curso, Profesor: ''
        });
      }
      
      const entrada: any = { 
        ...JSON.parse(JSON.stringify(producto)),
        idTarifa: producto.TarifaId,
        Modificado: false,
        TipoEntradaId: producto.TipoProducto === '1' ? producto.ItemId : '0',
        TipoPromocionId: producto.TipoProducto === '2' ? producto.ItemId : '0',
        TipoAbonadoId: '',
        Minimo: '',
        Maximo: '',
        Orden: '',
        PrecioEnDivisaReferencia: producto.PrecioUnidad,
        Fecha: producto.FechaHoraEntrada,
        cantidad: producto.Cantidad,
        CaracteristicasEntrada: objCaracteristicas
      };
      entrada.IVA = +producto.IVA.replace(',', '.') + '';
      ;
      return {
        entrada: entrada,
        cantidad: Number.parseInt(producto.Cantidad, 10)
      };
    });
    const entradasIdsArr = [];
    entradas.forEach(entrada => {
      if (entrada.entrada.TipoProducto === '2') {
        entrada.entrada.PromocionEntradas.forEach(item => {
          entradasIdsArr.push(item.TipoEntradaId);
        });
      } else if (entrada.entrada.TipoProducto === '1') {
        entradasIdsArr.push(entrada.entrada.TipoEntradaId);
      }
    });
    const entradasIds = entradasIdsArr.join();
    return await this.entradaService
      .getRecintosEntrada(entradasIds)
      .pipe(
        map((res: any) => {
          if (res.DatosResult === null) {
            return entradas;
          }
          const recintos = res.DatosResult.RecintosDisponibles;
          entradas.forEach(e => {
            if (e.entrada.TipoProducto === '1') {
              e.entrada.recintos = recintos.filter(recinto => {
                return recinto.TipoEntrada === e.entrada.TipoEntradaId;
              });
            } else if (e.entrada.TipoProducto === '2') {
              e.entrada.recintos = [];
              e.entrada.PromocionEntradas.forEach(item => {
                recintos
                  .filter(recinto => {
                    return recinto.TipoEntrada === item.TipoEntradaId;
                  })
                  .forEach(ar => {
                    e.entrada.recintos.push(ar);
                  });
              });
            }
            e.entrada.recintos = e.entrada.recintos.filter(
              (recinto, index, self) =>
                index ===
                self.findIndex(
                  r =>
                    r.TipoEntrada === recinto.TipoEntrada &&
                    r.Recinto === recinto.Recinto
                )
            );
          });
          return entradas;
        })
      )
      .toPromise();
  }


  // async mapEntradas(): Promise<any[]> {
  //   // 
  //   
  //   const entradas: any = this.albaran.AlbaranItems.map((producto, index) => {
      
  //     //TODO: Como en la taquilla vieja no se contemplan las nominaciones previas
  //     //TODO: Queda pensar como hacer butacas
  //     /*ListaButacas: producto.ListaButacas,
  //       FechaHoraAccesoLocalizadorButaca:
  //         producto.ListaButacas.length > 0
  //           ? producto.ListaButacas[0].FechaHora
  //           : undefined
  //     /*if (producto.PersonasReserva.length > 0) {
  //       if (producto.TipoProducto === '2') {
  //         producto.PersonasReserva.forEach(item => {
  //           producto.PromocionEntradas[
  //             parseInt(item.numOrden, 10) - 1
  //           ].EntradasVendidasPersonas = [item];
  //         });
  //       }
  //     }*/
  //     const objCaracteristicas = [];
  //     if (producto.TipoCentro && producto.TipoCentro != '') {
  //       objCaracteristicas.push({
  //         Tipo: producto.TipoCentro, Rango: producto.RangoEdad, Nivel: producto.Curso, Profesor: ''
  //       });
  //     }
  //     const entrada: any = {
  //       idTarifa: producto.TarifaId,
  //       CategoriaId: producto.CategoriaId,
  //       Modificado: false,
  //       TipoEntradaId: producto.TipoProducto === '1' ? producto.ItemId : '0',
  //       TipoPromocionId: producto.TipoProducto === '2' ? producto.ItemId : '0',
  //       TipoAbonadoId: '',
  //       GrupoId: producto.GrupoId,
  //       GrupoMotivoId: producto.GrupoMotivoId,
  //       NombreTipo: producto.NombreTipo,
  //       NombrePantalla: producto.NombrePantalla,
  //       NombreImpresion: producto.NombreImpresion,
  //       NombreDispay: producto.NombreDisplay,
  //       Minimo: '',
  //       Maximo: '',
  //       Orden: '',
  //       PrecioEnDivisaReferencia: producto.PrecioUnidad,
  //       PrecioBase: producto.PrecioBase,
  //       IVA: +producto.IVA.replace(',', '.') + '',
  //       Comision: producto.Comision,
  //       IvaComision: producto.IvaComision,
  //       ComisionBase: producto.ComisionBase,
  //       DesglosarComision: producto.DesglosarComision,
  //       Fecha: producto.FechaHoraEntrada,
  //       PromocionEntradas: producto.PromocionEntradas,
  //       TipoProducto: producto.TipoProducto,
  //       cantidad: producto.Cantidad,
  //       NumOrdenPromos: producto.NumOrdenPromos,
  //       ItemId: producto.ItemId,
  //       CaracteristicasEntrada: objCaracteristicas
  //     };
  //     return {
  //       entrada: entrada,
  //       cantidad: Number.parseInt(producto.Cantidad, 10)
  //     };
  //   });
  //   const entradasIdsArr = [];
  //   entradas.forEach(entrada => {
  //     if (entrada.entrada.TipoProducto === '2') {
  //       entrada.entrada.PromocionEntradas.forEach(item => {
  //         entradasIdsArr.push(item.TipoEntradaId);
  //       });
  //     } else if (entrada.entrada.TipoProducto === '1') {
  //       entradasIdsArr.push(entrada.entrada.TipoEntradaId);
  //     }
  //   });
  //   const entradasIds = entradasIdsArr.join();
  //   return await this.entradaService
  //     .getRecintosEntrada(entradasIds)
  //     .pipe(
  //       map((res: any) => {
  //         if (res.DatosResult === null) {
  //           return entradas;
  //         }
  //         const recintos = res.DatosResult.RecintosDisponibles;
  //         entradas.forEach(e => {
  //           if (e.entrada.TipoProducto === '1') {
  //             e.entrada.recintos = recintos.filter(recinto => {
  //               return recinto.TipoEntrada === e.entrada.TipoEntradaId;
  //             });
  //           } else if (e.entrada.TipoProducto === '2') {
  //             e.entrada.recintos = [];
  //             e.entrada.PromocionEntradas.forEach(item => {
  //               recintos
  //                 .filter(recinto => {
  //                   return recinto.TipoEntrada === item.TipoEntradaId;
  //                 })
  //                 .forEach(ar => {
  //                   e.entrada.recintos.push(ar);
  //                 });
  //             });
  //           }
  //           e.entrada.recintos = e.entrada.recintos.filter(
  //             (recinto, index, self) =>
  //               index ===
  //               self.findIndex(
  //                 r =>
  //                   r.TipoEntrada === recinto.TipoEntrada &&
  //                   r.Recinto === recinto.Recinto
  //               )
  //           );
  //         });
  //         return entradas;
  //       })
  //     )
  //     .toPromise();
  // }

  mapCliente() {
    return this.albaran.AlbaranCliente[0];
  }


  mapHorarios(entradas: any[]) {
    return this.ventaService.mapHorarios(this.albaran.AlbaranRecintos);
  }
  
  // mapHorarios(entradas: any[]) {
  //   const aforos = new Array();
  //   const aforosProducto = this.albaran.AlbaranRecintos.map((e, index) => {
  //     return {
  //       cantidad: e.CantidadTotal,
  //       index: index,
  //       recinto: {
  //         obligatorio: (e.ControlaAforoVenta && e.ControlaAforoVenta == '1') ? true : false,
  //         Activo: '1',
  //         CodigoTipoControl: '',
  //         ControlaAforo: e.ControlaAforoVenta,
  //         ControlaAforoReserva: '',
  //         ControlaNumSesion: '0',
  //         DisponibleInternet: '',
  //         EsActividad: '',
  //         NombreRecinto: e.RecintoNombre,
  //         Recinto: e.RecintoId,
  //         TiempoExtra: '0',
  //         TieneButacas: '0',
  //         TipoAforo: '',
  //         TipoEntrada: e.ItemId,
  //         entradas: this.albaran.AlbaranRecintos.filter(x => x.RecintoId == e.RecintoId && x.FechaEntrada == e.FechaEntrada).map( p => p.ItemId),
  //         fecha: moment(e.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD'),
  //         sesiones: [],
  //         ControlAforoExterno: ''
  //       },
  //       resumen: `${e.RecintoNombre} - ${moment(
  //         e.FechaEntrada,
  //         'YYYY/MM/DD'
  //       )
  //         .locale(this.idioma)
  //         .format('L')} ${moment(
  //           e.FechaEntrada,
  //           'YYYY/MM/DD HH:mm:ss'
  //         )
  //           .locale(this.idioma)
  //           .format('HH:mm')} (${e.CantidadTotal})`,
  //       sesion: {
  //         Activa: 1,
  //         ControlaGrupo: 0,
  //         Disponible: 0,
  //         DisponibleInternet: 0,
  //         Fecha: moment(e.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('YYYYMMDD'),
  //         FranjaHorario: '',
  //         HoraFin: '',
  //         HoraInicio: moment(e.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('HH:mm'),
  //         LimiteAforo: null,
  //         MaximoAforo: 0,
  //         MaximoInternet: 0,
  //         Nombre: e.RecintoNombre,
  //         NumSesionId: 0,
  //         RecintoId: e.RecintoId,
  //         RecintosSesionId: null,
  //         SesionBloqueada: 0,
  //         TipoAforo: '',
  //         TipoSesionId: 0,
  //         disabled: false
  //       }
  //     };
  //   });
  //   const objRecinto = [];
  //   const objFecha = [];
  //   const newArr = [];
  //   let indice = 0;
  //   aforosProducto.map(el => {
  //     if (!(el.recinto.Recinto in objRecinto)) {
  //       objRecinto[el.recinto.Recinto] = true;
  //       if (!(el.recinto.fecha in objFecha)) {
  //         objFecha[el.recinto.fecha] = true;
  //         el.index = indice;
  //         indice++;
  //         newArr.push(el);
  //       } else {
  //         el.index = indice;
  //         indice++;
  //         newArr.push(el);
  //       }
  //     } else {
  //       if (!(el.recinto.fecha in objFecha)) {
  //         objFecha[el.recinto.fecha] = true;
  //         el.index = indice;
  //         indice++;
  //         newArr.push(el);
  //       }
  //     }
  //   });
  //   aforos.push(...newArr);
  //   aforos.flat(1);
  //   return aforos;
  // }

}
