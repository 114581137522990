import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { TurnoService, ConfigurationService  } from 'src/app/core/index';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { VentaService } from 'src/app/modules/venta';

import { ConfirmationService } from 'src/app/shared/services/confirmation.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { ToastrService } from 'ngx-toastr';
import { InfoToast } from 'src/app/core/toast/info.toast';


@Component({
  selector: 'ticketing-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'header'
  }
})
export class NavbarComponent implements OnInit {

  fecha = new Date();
  userDetails: any;
  tpv: any;
  turno: any;
  Empresa; 

  constructor(
    private translate: TranslateService,
    private userService: UserManagerService,
    private configurationService: ConfigurationService,
    private turnoService: TurnoService,
    private ventaService: VentaService,
    private modalService: ModalService,
    private confirmationService: ConfirmationService,
    private config: AppConfiguration,
    private toast: ToastrService,
    @Inject(LOCALE_ID) public localeId: string) { }

  ngOnInit() {
    this.turnoService.turno.subscribe( turno => this.turno = turno);
    this.userService.currentUser.subscribe( user => this.userDetails = user);
    this.configurationService.datosTPVPath.subscribe(tpv => this.tpv = tpv);
    this.Empresa = this.config.getConfig('chClaveEmpresa'),
    setInterval(() => {
      this.fecha = new Date();
    }, 1000);
  }

  setLang(lang: string): void {
    this.localeId = lang;
    this.translate.use(lang);
  }

  logOut(): void {
    if (!this.userDetails) {
      // this.toast.info('No hay datos de Usuario, Realice el login','Info', {
        this.toast.info(this.translate.instant('NO_HAY_DATOS_USUARIO'),'Info', {
        toastComponent: InfoToast,
        timeOut: 7500
      });             
      return;
    }
    this.modalService.open('gestionarTurno');
  }

}
