import { Printer } from '../printer.interface';
import { RefundAlbaranOptions } from '../printOptions';
import { OsConnectionFactory } from '../../connection/osConnection.factory';
import { ConnectionType } from '../../connection/connectionType.enum';
import { PrintableType } from '../printableType.enum';
import { AlbaranDocumentBody, AlbaranOperationType } from './albaranDocumentBody';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/conf';

export class PartialRefundAlbaran implements Printer {
  private documentBody: any;
  private type: PrintableType;

  window = window as any;
  constructor(private translate: TranslateService,
    private config: AppConfiguration, private configuration: ConfigurationService) {
    this.type = PrintableType.ALBARAN;
    this.documentBody = new AlbaranDocumentBody(this.translate,this.config,this.configuration).build();
  }
  paint(options: RefundAlbaranOptions): Printer {
    //<< Yaribel 20210316 Modificamos para imprimir tambien los que tienen localizador
    // const albaran = options.albaran;
    const albaran = options.albaran.datos || options.albaran;
    // Yaribel 20210316 >>

    const cabecera = options.cabecera; //Yaribel 20201222 Añadimos cabecera para imprimir los datos de la cabecera en los justificantes con localizador
    this.documentBody = new AlbaranDocumentBody(this.translate,this.config,this.configuration)
       // .addClientHeader()
       .addClientHeader(cabecera) //Yaribel 20201222 Añadimos cabecera para imprimir los datos de la cabecera en los justificantes con localizador
       .addLine()
       .addOperation(AlbaranOperationType.DEVOLUCION)

      //<< Yaribel 20210316 Modificamos para imprimir tambien los que tienen localizador

      // .addSellingPointName(albaran.datos.TPVId, albaran.datos.TPVNombre)
      // .addRefundingSellingPointName(albaran.datos.OrigenSerie, albaran.datos.OrigenTPVNombre)
      // .addAlbaranNumber(albaran.datos.NumAlbaran)
      // .addOriginAlbaranNumber(albaran.datos.OrigenNumero)
      // .addLocator(albaran.datos.NumVenta)
      // .addOriginLocator(albaran.datos.OrigenNumVenta)
      // .addDateOfVisit(
      //   albaran.datos.FechaHoraVenta.split(' ')[0],
      //   albaran.datos.FechaHoraVenta.split(' ')[1]
      // )
      // .addDateOfOperation()
      // .addRefundedItems(albaran.datos.AlbaranItems)
      // .addLine()
      // .addDesgloseIVA(albaran.datos.AlbaranItems)
      // .addLine()
      // .addTotal(albaran.datos.TotalPagar)
      .addSellingPointName(albaran.TPVId, albaran.TPVNombre)
      .addRefundingSellingPointName(albaran.OrigenSerie, albaran.OrigenTPVNombre)
      .addAlbaranNumber(albaran.NumAlbaran)
      .addOriginAlbaranNumber(albaran.OrigenNumero)
      .addLocator(albaran.NumVenta)
      .addOriginLocator(albaran.OrigenNumVenta)
      .addOnlineSourceLocator(albaran.Localizador)
      .addDateOfVisit(
        albaran.FechaHoraVenta.split(' ')[0],
        albaran.FechaHoraVenta.split(' ')[1]
      )
      .addDateOfOperation()
      .addRefundedItems(albaran.AlbaranItems)
      .addLine()
      .addDesgloseIVA(albaran.AlbaranItems)
      .addLine()
      .addTotal(albaran.TotalPagar)

      // Yaribel 20210316 >>

      .addFooterMessage(cabecera)
      .build();
    return this;
  }

  /*     print(connectionType = 'VIRTUAL') {
        let conn = ConnectionType.VIRTUAL;
        if (connectionType === 'VIRTUAL') {
          conn = ConnectionType.VIRTUAL;
        }
        if (connectionType === 'ASPNET') {
          conn = ConnectionType.ASPNET;
        }
        const connection = OsConnectionFactory.getConnection(conn);
        connection.print(this.documentBody, this.type);
    } */

  print(connectionType = 'VIRTUAL') {
    if (this.window.external && typeof this.window.external.imprimirPDFBinario === 'function') {
      this.window.external.imprimirPDFBinario(
        this.generatePDFBinario(this.documentBody),
        'justificante'
      );
      // this.documentBody.save('justificante.pdf');
    }
    this.documentBody.save('justificante.pdf');
  }

  private generatePDFBinario(pdf) {
    return this.arrayBufferToBase64(pdf.output('arraybuffer'));
  }

  private arrayBufferToBase64(buffer: Iterable<number>) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
