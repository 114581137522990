import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/app/app.configuration';
import { shareReplay, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {
  constructor(private http: HttpClient, private config: AppConfiguration) {}

  findContacto(query: string, campo = null) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Filtro: query,
      Campo: campo
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/BuscadorContacto`, body)
      .pipe(shareReplay(1))
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return [];
          }
          return res.DatosResult.ListadoContacto;
        })
      );
  }
}