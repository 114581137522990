import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formaPago'
})
export class FormaPagoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'IN':
        return 'Internet';
      case 'CC':
        return 'Tarjeta de credito';
      case 'CA':
        return 'Efectivo';
      case 'TR':
        return 'Transferencia';
      case 'TM':
        return 'Tarjeta Monedero'; // Yaribel 12112020 Añadimos la forma de pago  TM para que visualice el nombre de la forma de pago en gestionar venta
       case 'DP':
        return 'Aplazado';
       case 'PR':
         return 'Prepago'; //Yaribel 20201217 añadimos forma de pago Prepago 
      default:
        return '';
    }
  }

}
