import { Printer } from '../printer.interface';
import { PurchaseAlbaranOptions } from '../printOptions';
import { PrintableType } from '../printableType.enum';
import {
  AlbaranDocumentBody,
  AlbaranOperationType
} from './albaranDocumentBody';
import { AppConfiguration } from 'src/app/app.configuration';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { ConfigurationService } from 'src/app/core/conf';

export class PurchaseAlbaran implements Printer {
  private documentBody: any;
  private type: PrintableType;

  window = window as any;
  constructor(private translate: TranslateService,
    private config: AppConfiguration, private configuration: ConfigurationService) {
    this.type = PrintableType.ALBARAN;
    this.documentBody = new AlbaranDocumentBody(this.translate,this.config, this.configuration).build();
  }

  paint(options: PurchaseAlbaranOptions): Printer {
    //<< Yaribel 20210316 Modificamos para imprimir tambien los que tienen localizador
    // const albaran = options.albaran;
    const albaran = options.albaran.datos || options.albaran;
    // Yaribel 20210316 >>
    const cabecera = options.cabecera;
    this.documentBody = new AlbaranDocumentBody(this.translate,this.config, this.configuration)
      .addClientHeader(cabecera)
      .addLine()
      .addOperation(AlbaranOperationType.VENTA)

      //<< Yaribel 20210316 Modificamos para imprimir tambien los que tienen localizador

      // .addSellingPointName(albaran.datos.TPVId ,albaran.datos.TPVNombre)
      // .addAlbaranNumber(albaran.datos.NumAlbaran)
      // .addLocator(albaran.datos.NumVenta)
      // .addDateOfVisit(
      //   albaran.datos.FechaHoraVenta.split(' ')[0],
      //   albaran.datos.FechaHoraVenta.split(' ')[1]
      // )
      // .addDateOfSale(
      //   (albaran.datos.FechaHoraEntrada && albaran.datos.FechaHoraEntrada != "")?albaran.datos.FechaHoraEntrada.split(' ')[0]:albaran.datos.FechaHoraVenta.split(' ')[0],
      //   (albaran.datos.FechaHoraEntrada && albaran.datos.FechaHoraEntrada != "")?albaran.datos.FechaHoraEntrada.split(' ')[1]:albaran.datos.FechaHoraVenta.split(' ')[1]
      // )
      // .addItems(albaran.datos.AlbaranItems)
      // .addLine()
      // .addDesgloseIVA(albaran.datos.AlbaranItems)
      // .addLine()
      // .addTotal(albaran.datos.TotalPagar)
      // .addPaymentMethod(
      //   albaran.datos.AlbaranFOPs,
      //   albaran.datos.CambioRecibido
      // )
      // .addChange(albaran.datos.CambioDevuelto)
      .addSellingPointName(albaran.TPVId ,albaran.TPVNombre)
      .addAlbaranNumber(albaran.NumAlbaran)
      .addLocator(albaran.NumVenta)
      .addOnlineSourceLocator(albaran.Localizador)
      .addDateOfVisit(
        albaran.FechaHoraVenta.split(' ')[0],
        albaran.FechaHoraVenta.split(' ')[1]
      )
      .addDateOfSale(
        (albaran.FechaHoraEntrada && albaran.FechaHoraEntrada != "")?albaran.FechaHoraEntrada.split(' ')[0]:albaran.FechaHoraVenta.split(' ')[0],
        (albaran.FechaHoraEntrada && albaran.FechaHoraEntrada != "")?albaran.FechaHoraEntrada.split(' ')[1]:albaran.FechaHoraVenta.split(' ')[1]
      )
      .addItems(albaran.AlbaranItems)
      .addLine()
      .addDesgloseIVA(albaran.AlbaranItems)
      .addLine()
      .addTotal(albaran.TotalPagar)
      .addPaymentMethod(
        albaran.AlbaranFOPs,
        albaran.CambioRecibido,
        albaran
      )
      .addChange(albaran.CambioDevuelto)

    // Yaribel 20210316 >>
      .addLine()
      .addFooterMessage(cabecera)
      .build();
    return this;
  }

  /*   print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    connection.print(this.documentBody, this.type);
  } */

  print(connectionType = 'VIRTUAL') {
    if (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    ) {
      this.window.external.imprimirPDFBinario(
        this.generatePDFBinario(this.documentBody),
        'justificante'
      );
      // this.documentBody.save('justificante.pdf');
    }
    this.documentBody.save('justificante.pdf');
  }

  private generatePDFBinario(pdf) {
    return this.arrayBufferToBase64(pdf.output('arraybuffer'));
  }

  private arrayBufferToBase64(buffer: Iterable<number>) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
