import { Component, OnInit, ElementRef, Input} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ConfirmationService } from '../../services/confirmation.service';

@Component({
  selector: 'ticketing-confirmar-cierre',
  templateUrl: './confirmar-cierre.component.html',
  styleUrls: ['./confirmar-cierre.component.scss']
})
export class ConfirmarCierreComponent implements OnInit {
  @Input() id: string;

  private element: any;
  constructor(
    private modalService: ModalService,
    private confirmationService: ConfirmationService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.confirmationService.setConfirmado(false);
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');

  }

  confirmarCierre() {
    this.confirmationService.setConfirmado(true);
  }
}
