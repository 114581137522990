import { Injectable } from '@angular/core';
import { AppConfiguration } from 'src/app/app.configuration';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Actividad } from '../models/actividad.model';
import * as moment from 'moment';
import { TurnoService } from 'src/app/core';


@Injectable({
  providedIn: 'root'
})
export class EspectaculosService {
  private espectaculoSeleccionadoSubject: BehaviorSubject<any>;
  public espectaculoSeleccionado: Observable<any>;
  sesionSeleccionada: {
    espectaculoId: number;
    fechaSesion: string;
    horaInicioSesion: string;
  };

  private espectaculosSubject: BehaviorSubject<any>;
  public espectaculos$: Observable<any>;

  constructor(private config: AppConfiguration, private http: HttpClient, private turnoService: TurnoService) {
    this.espectaculoSeleccionadoSubject = new BehaviorSubject(undefined);
    this.espectaculoSeleccionado = this.espectaculoSeleccionadoSubject.asObservable();

    this.espectaculosSubject = new BehaviorSubject([]);
    this.espectaculos$ = this.espectaculosSubject.asObservable();
  }

  public get espectaculoSeleccionadoValue(): Actividad {
    return this.espectaculoSeleccionadoSubject.value;
  }

  setEspectaculoSeleccionadoValue(espectaculo) {
    this.espectaculoSeleccionadoSubject.next(espectaculo);
  }

  getEspectaculos(page = 1) {
    /*const a = {
      pageSize: 10000,
      page: 1,
      likeDescripcionEspectaculo: undefined,
      xlikeDescripcion: undefined,
      minFecha: moment().format('YYYYMMDD'),
      xmaxFecha: undefined,
      Activa: 1,
      RangoFechas: 1,
      ConTarifa: 1
    };*/
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      MinFecha: moment().format('YYYY/MM/DD'),
      Activa: 1,
      RangoFechas: 1,
      ConTarifa: 1,
      Plataforma: 2,
      TpvId: this.turnoService.turnoValue.TpvId,
      UsuarioId: this.turnoService.turnoValue.UsuarioId,
      ClienteApi: {
        Id: 195331670938917605,
        Token: 'UMALehJVMAjxywlKLZeEwTy4TwfGrKh8OFpKWvliNTri9n6RHn6as6Bf84ICcqegtyvbCq7sbevuI2cPwX21sXudqb6DmsFGi5TMcYxXIXARS45mSDvvMTrAOWl9kjrv'
      },
      //chParams: JSON.stringify(a)
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/VentaTaquilla/GetSesiones`,
      body
    );
  }

  getSesionesFechasDisponibles(
    espectaculoId = 1,
    grupoRecintoId = 1,
    fechaDesde,
    fechaHasta
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: 'es',
      EspectaculoId: espectaculoId,
      GrupoRecintoId: grupoRecintoId,
      FechaDesde: fechaDesde,
      FechaHasta: fechaHasta,
      GroupbyDias: 'HORAS',
      GruposInternet: 'TODOS',
      EnlaceMenuId: 'TODOS',
      Plataforma: '2'
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/ButacasDisponibleFechas`,
      body
    );
  }

  public get espectaculos() {
    return this.espectaculosSubject.value;
  }

  public setEspectaculos(espectaculos) {
    this.espectaculosSubject.next(espectaculos);
  }

  public pushEspectaculo(espectaculo) {
    if (this.comprobarSiExisteEspectaculo(espectaculo)) {
      return;
    }
    this.espectaculos.push(espectaculo);
    this.setEspectaculos(this.espectaculos);
  }

  public popEspectaculo() {
    this.setEspectaculos(this.espectaculos.pop());
  }

  deleteEspectaculo(espectaculo) {
    this.setEspectaculos(
      this.espectaculos.filter(
        e => e.EspectaculosId !== espectaculo.EspectaculosId
      )
    );
  }

  deleteAllEspectaculos() {
    this.setEspectaculos([]);
  }

  comprobarSiExisteEspectaculo(espectaculo: any) {
    return this.espectaculos.find(
      e =>
        e.EspectaculosId === espectaculo.EspectaculosId &&
        e.fecha === espectaculo.fecha &&
        e.hora === espectaculo.hora
    );
  }

  removeEspectaculo(espectaculo) {
    const nuevaLista = this.espectaculos.filter(
      esp =>
        esp.EspectaculosId !== espectaculo.EspectaculosId ||
        esp.fecha !== espectaculo.fecha ||
        esp.hora !== espectaculo.hora
    );
    this.setEspectaculos(nuevaLista);
  }
}
