import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ticketing-cell-sesiones',
  templateUrl: './cell-sesiones.component.html',
  styleUrls: ['./cell-sesiones.component.scss']
})
export class CellSesionesComponent implements OnInit {
  @Input() color = '';
  @Input() time;
  @Input() ticketsLeft;

  @Input() isNaranja = false;
  @Input() isSelect = false;
  @Input() isRojo = false;
  @Input() isApagado = false;
  @Input() isMas = false;

  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    if (this.isApagado) {
      this.isRojo = false;
      this.isNaranja = false;
      this.isSelect = false;
      this.isMas = false;
    }
    switch (this.color) {
      case 'naranja':
        this.isNaranja = true;
        break;
      case 'rojo':
        this.isRojo = true;
        break;
      case 'apagado':
        this.isApagado = true;
        break;
      case 'mas':
        this.isMas = true;
        break;
    }
  }
}
