import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import { ConnectionType } from '../../connection/connectionType.enum';
import { OsConnectionFactory } from '../../connection/osConnection.factory';
import { CashClosureReportOptions } from '../printOptions';
import { PrintableType } from '../printableType.enum';
import { Printer } from '../printer.interface';

export class CashOpeningReport implements Printer {
  private window = window as any;
  private documentBody;
  private DATE_FORMAT = 'DD/MM/YYYY HH:mm';
  private anchura:number = 80;
  type: PrintableType;
  constructor() {
    this.documentBody = new jspdf('p', 'mm', [this.anchura * 2.83, 275 * 2.83]);
    this.type = PrintableType.CASH_OPENING_REPORT;
  }
  paint(options: CashClosureReportOptions): Printer {
    let posicion = 5;
    const item = Object.assign({}, options);
    this.documentBody.setFontSize(8);
    this.documentBody.text('Justificante de Operación de Caja', 18, posicion);
    posicion += 5;
    this.documentBody.text('__ __ __ __ __ __ __ __ __ __ __ __ __ __ __ __ __ __', 5, posicion);
    posicion += 5;
    this.documentBody.text(moment().format('DD/MM/YYYY HH:mm:ss'), 47, posicion);
    //this.documentBody.text(this.calculaHora(item.data.FechaCierre), 60, posicion);
    posicion += 5;
    this.documentBody.text(`Caja Id: ${item.data.CajaId}`, 10, posicion);
    // this.documentBody.text('Caja', 10, posicion);
    // this.documentBody.text(item.data.CajaId, 30, posicion);
    this.documentBody.text(`Código Caja: ${item.data.CajaCodigo}`, 40, posicion)
    //this.documentBody.text(item.data.CajaCodigo, 50, posicion);
    posicion += 10;
    this.documentBody.text('Usuarios:', 10 , posicion);
    posicion += 5;
//Usuario
      this.documentBody.text(item.data.TurnoInfo.UsuarioId , 10  , posicion);
        this.documentBody.text(item.data.TurnoInfo.NombreUsuario, 40 , posicion);
        posicion += 5;
        this.documentBody.text(item.data.TurnoInfo.FechaHoraInicio, 10 , posicion);
        posicion += 2;
        this.documentBody.text('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', 5, posicion);
    posicion += 5;
    this.documentBody.text('TPV', 10, posicion);
    this.documentBody.text(item.data.TurnoInfo.TpvId, 30, posicion);
    this.documentBody.text(item.data.TurnoInfo.NombreTPV, 50, posicion);
    posicion += 15;
   
    this.documentBody.text('Cambio Inicial', 10, posicion);
    this.documentBody.setFont('Courier');
    this.documentBody.text(this.justificarPosicion(this.addDotsToNumber((Number.parseFloat((item.data.TurnoEfectivo.CambioInicial).replace(',','.'))).toFixed(2).replace('.', ',')), this.anchura - 68) + ' €', 50, posicion);
    //this.documentBody.text(this.justificarPosicion(this.parsearFloatEnString(item.data.TurnoEfectivo.CambioInicial), this.anchura - 68) + ' €', 50, posicion);
    this.documentBody.setFont('Helvetica');
    posicion += 30;
    this.documentBody.text('COMENTARIOS', 10, posicion);
    posicion += 2;
    this.documentBody.text('_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _', 5, posicion);
    posicion += 5;
    this.documentBody.text('** INICIO DE TURNO **', 10, posicion);
    const pageCount = this.documentBody.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
      this.documentBody.setPage(i);
      this.documentBody.setFontSize(10);
      this.documentBody.text(10, 290, moment().format(this.DATE_FORMAT));
      this.documentBody.text(
        190,
        290,
        this.documentBody.internal.getCurrentPageInfo().pageNumber +
          '/' +
          pageCount
      );
    }
    return this;
  }


  print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    // connection.print(this.documentBody, this.type);
    if (
      this.window.external &&
      typeof this.window.external.imprimirPDFBinario === 'function'
    ) {
      this.window.external.imprimirPDFBinario(
        this.generatePDFBinario(this.documentBody),
        'documento'
      );
    } else {
      this.documentBody.save('apertura.pdf');
    }
  }

  calculaHora(fecha) {
    const hora = fecha.split(' ')[1];
    const [h, m, s] = hora.split(':');
    return h + ':' + m;
  }

  calculaFecha(fecha) {
    return fecha.split(' ')[0];
  }

  private generatePDFBinario(pdf) {
    return this.arrayBufferToBase64(pdf.output('arraybuffer'));
  }

  private arrayBufferToBase64(buffer: Iterable<number>) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private esCero(valor:string ): boolean
  {
    var respuesta = true;
    valor = valor.replace(/\./g, ''); // quitamos los puntos
    valor = valor.replace(/,/g, '.'); // cambiamos comas por puntos
    let numero :number =  parseFloat(valor);
    if (numero && numero != 0) respuesta = false;
    return respuesta;
  }

  private parsearFloatEnString(valor:string): string
  {
    //Esta funcion puede presentar problemas si nos encontramos con una devolucion de la API en formato numerico 
    //#,###,##0.00
    var decimales = 0;
    var respuesta ='';
    var last = valor.lastIndexOf('.') >= 0? valor.lastIndexOf('.'):valor.lastIndexOf(',');
    decimales = valor.length -(valor.length - (valor.length - (last > 0?last+1:valor.length )));
    decimales = decimales < 0?0:decimales;
    var separador = last >= 0 ? valor[last] : ',';
    if (separador == ',')
    {
      valor = valor.replace(/\./g, ''); // quitamos los puntos
      valor = valor.replace(/,/g, '.'); // cambiamos comas por puntos
    }
    else if (separador = '.')
    {
      valor = valor.replace(/,/g, '.'); // quitamos las comas
    }
    respuesta =  parseFloat(valor).toFixed(decimales);

    return respuesta;
  }

  private justificarPosicion(valor:string, tamano:number):string
  {
    var respuesta = valor;
    var prefijo = tamano - valor.length;
    if (prefijo >0) respuesta= new Array(prefijo + 1).join(" ") + respuesta;
    return respuesta;
  }
  addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    let negativo = false;
    if(number.substring(0, 1) === '-')
    {
      negativo =  true;
      number = number.substring(1);
    }
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => { 
          if ((integerArray.length - index) % 3 === 0) {
              newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        newInteger = negativo? '-' + newInteger : newInteger;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        newInteger = negativo? '-' + newInteger : newInteger;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
      }
      newInteger += decimal;
      newInteger = negativo? '-' + newInteger : newInteger;
      return newInteger;
    }
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    number = negativo? '-' + number : number;
    return number + d;
  }
}
