import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  LOCALE_ID,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef
} from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// Core
import { HttpErrorInterceptor } from './core';

// << Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
// // Locales
// import localeEs from '@angular/common/locales/es';
// import localeEs419 from '@angular/common/locales/es-419';
// import localeCa from '@angular/common/locales/ca';
// import localeEn from '@angular/common/locales/en';
// import { registerLocaleData } from '@angular/common';
// Yaribel 20210105 >>
// App Components
import { NavbarComponent, SidebarComponent } from './layouts/index';
import { VentaModule, DevolucionModule } from './modules/index';
// import { TranslateService } from './shared/services/translate.service'; // Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
import { SharedModule } from './shared/shared.module';

// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TokenInterceptor } from './core/http-helpers/token.interceptor';
import { GestionarModule } from './modules/gestionar';
import { LoginComponent } from './core/auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppConfiguration } from './app.configuration';
import { LocalizadorModule } from './modules/localizador/localizador.module';
import { GestionarVentaModule } from './modules/gestionar-venta/gestionar-venta.module';
import { AuditorioModule } from './modules/auditorio/auditorio.module';
import { SuccessToast } from './core/toast/success.toast';
import { ErrorToast } from './core/toast/error.toast';
import { InfoToast } from './core/toast/info.toast';
import { AgendaCalendarioComponent } from './modules/agenda/components/agenda-calendario/agenda-calendario.component';
import { ModalDatosAdicionalesEntradaComponent } from './modules/venta/components/modal-datos-adicionales-entrada/modal-datos-adicionales-entrada.component';
import { MatRadioModule } from '@angular/material';
import { ConfirmarReimpresionDiaDistintoComponent } from './shared/components/confirmar-reimpresion-dia-distinto/confirmar-reimpresion-dia-distinto.component';
import { ImpresionLocalizadorModalComponent } from './shared/components/impresion-localizador-modal/impresion-localizador-modal.component';
import { AgendaLoginComponent } from './modules/agenda/components/agenda-login/agenda-login.component';
import { AutofocusFixModule } from 'ngx-autofocus-fix';
import { ConfirmarVentaReservaPagadaComponent } from './shared/components/confirmar-venta-reserva-pagada/confirmar-venta-reserva-pagada.component';
import { CambiarPasswordComponent } from './core/auth/cambiar-password.component';
import { ClienteAsociadoComponent } from './modules/Asociado/components/cliente-asociado/cliente-asociado.component';
import { CodigoAuthComponent } from './core/auth/codigo-auth/codigo-auth.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// << Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
// export function setupTranslateFactory(service: TranslateService): Function {
//   return () => {
//     service.use(navigator.language);
//   };
// }
// Yaribel 20210105 >>

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    LoginComponent,
    HomeComponent,
    SuccessToast,
    ErrorToast,
    InfoToast,
    AgendaCalendarioComponent,
    ModalDatosAdicionalesEntradaComponent,
    ConfirmarReimpresionDiaDistintoComponent,
    ImpresionLocalizadorModalComponent,
    AgendaLoginComponent,
    ConfirmarVentaReservaPagadaComponent,
    CambiarPasswordComponent,
    ClienteAsociadoComponent,
    CodigoAuthComponent
  ],
  entryComponents: [SuccessToast, ErrorToast, InfoToast],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    VentaModule,
    DevolucionModule,
    GestionarModule,
    LocalizadorModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    AuditorioModule,
    GestionarVentaModule,
    BrowserAnimationsModule,
    MatRadioModule,
    AutofocusFixModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right'
    })
  ],
  providers: [
    // << Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
    // TranslateService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: setupTranslateFactory,
    //   deps: [TranslateService],
    //   multi: true
    // },
    // Yaribel 20210105 >>
    AppConfiguration,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfiguration) => () => config.load(),
      deps: [AppConfiguration],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // << Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
    // {
    //   provide: LOCALE_ID,
    //   deps: [TranslateService],
    //   useFactory: translateService => translateService.getLocale()
    // }
    // Yaribel 20210105 >>
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  // << Yaribel 20210105 Comentamos para unificar el translate service en un solo modulo
  // constructor() {
  //   registerLocaleData(localeEs, 'es-ES');
  //   registerLocaleData(localeCa, 'ca-CA');
  //   registerLocaleData(localeEn, 'en-EN');
  //   registerLocaleData(localeEs419, 'es-419');
  // }
  // Yaribel 20210105 >>
}
