import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ElementRef,
  Inject,
  LOCALE_ID,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import * as moment from 'moment';
import 'moment/min/locales';
import * as _ from 'lodash';
import { VentaService } from 'src/app/modules/venta/services/venta.service';
import { AlbaranService } from 'src/app/modules/gestionar-venta/services/albaran.service';
import { TokenManagerService } from 'src/app/core';

interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'ticketing-calendar-etiquetas',
  templateUrl: './calendar-etiquetas.component.html',
  styleUrls: ['./calendar-etiquetas.component.scss']
})
export class CalendarEtiquetasComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() id: string;

  private element: any;
  moment = moment.locale(this.localeId);
  currentDate = moment();
  monthsFirstColumn = moment.months().splice(0, 6);
  monthsSecondColumn = moment.months().splice(6, 11);
  dayNames;
  weeks: CalendarDate[][] = [];
  sortedDates: CalendarDate[] = [];
  disabledDays: [] = [];

  @Input() selectedDates: CalendarDate[] = [];

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    @Inject(LOCALE_ID) protected localeId,
    private cd: ChangeDetectorRef,
    private albaranService: AlbaranService,
    private ventaService: VentaService,
    private tokenService: TokenManagerService
  ) {
    this.dayNames = moment.weekdays(true);
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);

    this.tokenService.tokenRecibido.subscribe(data => {
      if (data) {
        this.generateCalendar();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedDates &&
      changes.selectedDates.currentValue &&
      changes.selectedDates.currentValue.length > 1
    ) {
      this.sortedDates = _.sortBy(changes.selectedDates.currentValue, (m: CalendarDate) =>
        m.mDate.valueOf()
      );
      this.generateCalendar();
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  // open modal
  open(): void {
    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  prevMonth(): void {
    if (
      moment(this.currentDate)
        .subtract(1, 'months')
        .isBefore(moment(), 'month')
    ) {
      return;
    }
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.generateCalendar();
  }

  nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.generateCalendar();
  }

  selectMonth(month: any): void {
    if (this.checkForDisabledMonths(month)) {
      this.currentDate = moment(this.currentDate).set('month', month);
      this.generateCalendar();
    }
  }

  checkForDisabledMonths(month: any) {
    const m = moment();
    m.set('month', month);
    m.set('year', this.currentDate.year());
    m.set('day', this.currentDate.day());
    return !m.isBefore(moment(), 'month');
  }

  selectYear(year): void {
    if (year.isBefore(moment(), 'month')) {
      return;
    }
    this.currentDate = year;
    this.generateCalendar();
  }

  generateCalendar(): void {
    this.ventaService.getFechasFestivas(this.currentDate).subscribe((res: any) => {
      if (res.DatosResult) {
        this.disabledDays = res.DatosResult.LstDatosFestivos.map(dia => {
          const diaSinHora = dia.Fecha.split(' ')[0];
          return diaSinHora;
        });
        this.ventaService.setDisabledDays(this.disabledDays);
      }
      const dates = this.fillDates(this.currentDate);
      const weeks: CalendarDate[][] = [];
      while (dates.length > 0) {
        weeks.push(dates.splice(0, 7));
      }
      this.weeks = weeks;
    });
  }

  fillDates(currentMoment: moment.Moment): CalendarDate[] {
    const firstOfMonth = moment(currentMoment)
      .startOf('month')
      .day();
    const firstDayOfGrid = moment(currentMoment)
      .startOf('month')
      .subtract(firstOfMonth - 1, 'days');
    let start = firstDayOfGrid.date();
    start = start === 2 ? start - 7 : start;
    return _.range(start, start + 42).map(
      (date: number): CalendarDate => {
        const d = moment(firstDayOfGrid).date(date);
        return {
          today: this.isToday(d),
          selected: this.isSelected(d),
          disabled: this.isDisabled(d),
          mDate: d
        };
      }
    );
  }

  isDisabled(date: moment.Moment) {
    let exists;
    this.disabledDays.forEach(day => {
      if (date.format('DD/MM/YYYY') === day) {
        exists = true;
      }
    });
    // Hacer dinamico desde los datos inicio
    // const tiempoVentaMax = Number.parseInt(this.configuration.datosInicioValue.TiempoVentaMax, 10);
    // const tipo =  this.configuration.datosInicioValue .TiempoVentaMaxTipo[0];
    return exists || date.isAfter(moment().add(2, 'M'), 'day');
  }

  isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  isSelected(date: moment.Moment): boolean {
    return date.isSame(this.ventaService.fechaSeleccionadaValue, 'day');
  }

  isSelectedMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.currentDate, 'month');
  }

  selectDate(date: CalendarDate): void {
    if (date.disabled) {
      return;
    }
    if (date.mDate.isBefore() && !date.today) {
      return;
    }
    const albaran = this.albaranService.albaranValue;
    albaran.FechaHoraEntrada = date.mDate.toString();
    this.albaranService.setAlbaranValue(albaran);
    this.close();
  }

  getYears() {
    const years = [];
    for (let i = 0; i < 5; i++) {
      years.push(
        moment()
          .month(this.currentDate.month())
          .add(i, 'year')
      );
    }
    return years;
  }
}
