export class CustomerDisplayLine {
    private text: string;
    private initialPosition: number;

    constructor(text: string, initialPosition: number = 0) {
        this.text = text;
        this.initialPosition = initialPosition;
    }

    getText() {
        return this.text;
    }

    getInitialPosition() {
        return this.initialPosition;
    }
}
