import { Component, OnInit, OnDestroy, ElementRef, Input } from '@angular/core';

import { Categoria } from '../../models/motivo.model';
import { Entrada } from '../../models/entrada.model';
import { Carrito } from '../../models/carrito.model';
import { VentaService } from '../../services/venta.service';
import { ConfigurationService } from 'src/app/core';
import { EntradasService } from '../../services/entradas.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { FormBuilder } from '@angular/forms';
import { VisorService } from 'src/app/shared/services/visor.service';
import { takeWhile } from 'rxjs/operators';
import * as moment from 'moment';
import { NumpadService } from 'src/app/shared/services/numpad.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { PagerService } from 'src/app/shared/services/pager.service';
import { pipe, Subscription } from 'rxjs';

@Component({
  selector: 'ticketing-selector-mas-entradas',
  templateUrl: './selector-mas-entradas.component.html',
  styleUrls: ['./selector-mas-entradas.component.scss']
})
export class SelectorMasEntradasComponent implements OnInit, OnDestroy {
  @Input() id: string;

  categorias: any[];
  categoriaActiva: any;
  entradas: Entrada[];

  entradaSeleccionada: Entrada;

  pager: any = {};
  pagedItems: any[];

  carrito: Carrito;
  idioma = 'es-ES';

  fecha: any;
  cliente: any;

  private alive = false;

  divisaSimboloDecimal: string;
  divisaSimbolo: string;

  private element: any;
  private datosInicioSub: Subscription;
  private carritoSub: Subscription;
  private subscriptions:Subscription[] =[];

  constructor(
    private ventaService: VentaService,
    private configurationService: ConfigurationService,
    private entradaService: EntradasService,
    private numpadService: NumpadService,
    private formControlService: FormControlService,
    private modalService: ModalService,
    private pagerService: PagerService,
    private formBuilder: FormBuilder,
    private visor: VisorService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.ventaService.setMasEntradas('extra');
    this.onChanges();
    this.divisaSimboloDecimal = this.configurationService.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configurationService.divisasValue[0].simbolo;
    this.datosInicioSub = this.configurationService.datosInicio.subscribe(conf => (this.idioma = conf.Idioma));
    this.carritoSub = this.ventaService.carrito.subscribe(carrito => (this.carrito = carrito));
    this.modalService.add(this);
  }

  ngOnDestroy() {
    this.alive = false;
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.modalService.remove(this.id);
    this.element.remove();
  }

  onChanges() {
    
    this.entradaService
      .getCategorias(this.ventaService.clienteSeleccionadoValue ? this.ventaService.clienteSeleccionadoValue.Codigo : null,
        this.ventaService.fechaSeleccionadaValue ? this.ventaService.fechaSeleccionadaValue : null)
      .pipe(takeWhile(() => this.alive))
      .subscribe((res: any) => {
        if (res !== undefined && res.length > 0) {
          this.categorias = res;
          this.categoriaActiva = this.categorias[0];
          this.entradaService
            .getEntradas(
              this.categoriaActiva.Id,
              this.ventaService.fechaSeleccionadaValue,
              this.ventaService.clienteSeleccionadoValue,
              null,
              null,
              this.categoriaActiva.TipoEntrada
            )
            .subscribe((entradas: any) => {
              this.entradas = entradas;
              const entradasIdsArr = [];
              this.entradas.forEach(entrada => {
                if (entrada.TipoProducto === '2') {
                  entrada.PromocionEntradas.forEach(item => {
                    entradasIdsArr.push(item.TipoEntradaId);
                  });
                } else if (entrada.TipoProducto === '1') {
                  entradasIdsArr.push(entrada.TipoEntradaId);
                }
              });
              const entradasIds = entradasIdsArr.join();
              this.entradaService
                .getRecintosEntrada(entradasIds)
                .pipe(takeWhile(() => this.alive))
                .subscribe((recintosDisponibles: any) => {
                  if (recintosDisponibles.DatosResult === null) {
                    return;
                  }
                  const recintos = recintosDisponibles.DatosResult.RecintosDisponibles;
                  this.entradas.forEach(e => {
                    if (e.TipoProducto === '1') {
                      e.recintos = recintos.filter(recinto => {
                        return recinto.TipoEntrada === e.TipoEntradaId;
                      });
                    } else if (e.TipoProducto === '2') {
                      e.PromocionEntradas.forEach(item => {
                        e.recintos = recintos.filter(recinto => {
                          return recinto.TipoEntrada === item.TipoEntradaId;
                        });
                      });
                    }
                  });
                });
              this.setPage(1);
            });
            if (this.categoriaActiva !== undefined) {
              this.seleccionCategoria(this.categoriaActiva && this.categoriaActiva.Id ? this.categoriaActiva.Id : '1');
            }
        } else {
          this.entradas = [];
        }
      });
  }

  seleccionCategoria(id: string): void {
    this.categoriaActiva = this.categorias.find(cat => cat.Id === id);
    this.entradaService
      .getEntradas(
        this.categoriaActiva.Id,
        this.ventaService.fechaSeleccionadaValue,
        this.ventaService.clienteSeleccionadoValue,
        null,
        null,
        this.categoriaActiva.TipoEntrada
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe((entradas: any) => {
        this.entradas = entradas;
        const entradasIdsArr = [];
        this.entradas.forEach(entrada => {
          if (entrada.TipoProducto === '2') {
            entrada.PromocionEntradas.forEach(item => {
              entradasIdsArr.push(item.TipoEntradaId);
            });
          } else if (entrada.TipoProducto === '1') {
            entradasIdsArr.push(entrada.TipoEntradaId);
          }
        });
        const entradasIds = entradasIdsArr.join();
        this.entradaService.getRecintosEntrada(entradasIds).subscribe((res: any) => {
          const recintos = res.DatosResult.RecintosDisponibles;
          this.entradas.forEach(e => {
            if (e.TipoProducto === '1') {
              e.recintos = recintos.filter(recinto => {
                return recinto.TipoEntrada === e.TipoEntradaId;
              });
            } else if (e.TipoProducto === '2') {
              e.PromocionEntradas.forEach(item => {
                e.recintos = recintos.filter(recinto => {
                  return recinto.TipoEntrada === item.TipoEntradaId;
                });
              });
            }
          });
        });
        this.setPage(1);
      });
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
    this.alive = true;
    //this.subscriptions = [];
    this.onChanges();
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.alive = false;
    //this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setPage(page: number): void {
    this.pager = this.pagerService.getPager(this.entradas.length, page, 9);
    this.pagedItems = this.entradas.slice(this.pager.startIndex, this.pager.endIndex + 1);

    this.pager.pages = this.pager.pages.filter(num => num <= this.pager.totalPages);

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }

    this.ventaService.carritoValue.fecha = this.getFechaSeleccionada();
  }

  getFechaSeleccionada(): string {
    if (this.ventaService.fechaSeleccionadaValue) {
      return moment(this.ventaService.fechaSeleccionadaValue)
        .locale(this.idioma)
        .format('YYYY/MM/DD HH:mm:ss');
    }
  }

  deberiaDestacarse(id: string) {
    const categorias = this.ventaService.carritoValue.entradas.map(linea => {
      return linea.entrada.CategoriaId;
    });
    return this.uniq_fast(categorias).includes(id);
  }

  private uniq_fast(a) {
    const seen = {};
    const out = [];
    const len = a.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
      const item = a[i];
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }

  getCantidadPorEntrada(entrada: Entrada): number {
    if (this.ventaService.carritoValue) {
      return this.ventaService.getCantidadPorEntrada(entrada);
    }
    return 0;
  }

  seleccionarEntrada(entrada: Entrada): void {
    if (entrada.GrupoMotivoId) {
      this.entradaService.setEntradaSeleccionada(entrada);
      this.entradaSeleccionada = entrada;
      
      //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables                   
      
      this.modalService.open('selector-motivos');
      return;
      // //<< Yaribel 20201229  controlamos la llamada al modal solo si los motivos son seleccionables 
      // this.entradaService.comprobarGrupoMotivoDescuentoSeleccionable(entrada.TipoEntradaId).subscribe(motivoSeleccionable =>{
      //   if(motivoSeleccionable)
      //   {
      //     this.modalService.open('selector-motivos');
      //     return;
      //   }
      // });
      // // Yaribel 20201229  controlamos la llamada al modal solo si los motivos son seleccionables        >> 

      //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables                   

    }
    const input = document.getElementById('extra-' + entrada.idTarifa);
    this.numpadService.selected = input;
    this.numpadService.entrada = entrada;
    this.formControlService.setCurrentFormGroup(null, input, input.getAttribute('category'));
    this.entradaSeleccionada = entrada;
  }
}
