import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core';


@Injectable({
  providedIn: 'root'
})
export class ProcedenciaService {

  private procedenciaSubject: BehaviorSubject<any>;
  public procedencias: Observable<any>;
  private alive = true;

  constructor(    
    private http: HttpClient,
    private config: AppConfiguration,
    private configuration: ConfigurationService
    ) {
    
    this.procedenciaSubject = new BehaviorSubject(undefined);
    this.procedencias = this.procedenciaSubject.asObservable();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  public getprocedenciasValue() {
    return this.procedenciaSubject.value;
  }

  setProcedenciasValue(procedencias) {
    this.procedenciaSubject.next(procedencias);
  }

  hayentradasconProcedencia(entrada) 
  {
    let respuesta = false;
    if (entrada && entrada.recintos && entrada.recintos.length > 0)
    {
      entrada.recintos.forEach(element => {
        if (element.ControlAforoExterno && element.ControlAforoExterno != '') respuesta = true;
      });
    }
    return respuesta;
  }

  procedenciasCompletas()
  { //Si utiliza procedencia por defecto es siempre obligatorio
    let procedencias = this.procedenciaSubject.value;
    let respuesta =  false;
    if(procedencias){
    procedencias.forEach(element => {
      respuesta = element.completado;
    });
   }
    return respuesta;
  }

  getPaises(){
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/Configuracion/GetPaises`,
      body
    );
  }

  getComunidades(){
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/Configuracion/GetComunidades`,
      body
    );
  }

  getCiudades(){
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}api/Configuracion/GetCiudades`,
      body
    );
  }
}
