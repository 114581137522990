import { Printer } from '../printer.interface';
import * as jspdf from 'jspdf';
import { PrintableType } from '../printableType.enum';
import { OsConnectionFactory } from '../../connection/osConnection.factory';
import { ConnectionType } from '../../connection/connectionType.enum';
import * as QRCode from 'qrcode';
import { NormalTicketOptions } from '../printOptions';
import * as moment from 'moment';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/conf';

export class NormalTicket implements Printer {
  private documentBody;
  type: PrintableType;

  constructor(translate: TranslateService, private config:AppConfiguration, private configuration:ConfigurationService) {
    const options = {
      orientation: 'p',
      unit: 'mm',
      format: [28 * 2.8, 250 * 2.8]
    };
    this.type = PrintableType.TICKET;
    this.documentBody = new jspdf(options);
  }

  paint(options: NormalTicketOptions): Printer {
    let y = 0; // = 10;s
    this.documentBody.setFontSize(8);
    y += 8;

    const opt = {
      orientation: 'p',
      unit: 'mm',
      format: [28 * 2.8, 250 * 2.8]
    };

    const pdf = new jspdf(opt);
    pdf.setFontSize(8);
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    if (options.qrCode !== '') {
      QRCode.toCanvas(options.qrCode, { errorCorrectionLevel: 'L' }, (err, canvas) => {
        if (err) {
          throw err;
        }
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(
          imgData,
          'PNG',
          1.7,
          (height / 4) * 3,
          width - (width / 100) * 10,
          width - (width / 100) * 10
        );
      });
      pdf.save('ticket.pdf');
    }
    // this.documentBody.text(`${options.totalQuantity}`, 90, (y + 21), 'right');
    // tslint:disable-next-line:max-line-length
    // creo que va siempre uno, xk la conjunta es la que tiene el total de entradas
    /* this.documentBody.text(
      `${options.contadorGeneral + 1}/${options.totalQuantity}`,
      90,
      y + 21,
      'right'
    ); */
    // this.documentBody.text(options.tpvName, 90, y + 24, 'right');
    // this.documentBody.text(options.albaranId, 90, y + 27, 'right');
    // this.documentBody.text('PRADO', 11, y, 'left');
    // this.documentBody.text(`${options.locator} - ${options.contadorGeneral + 1}`, 90, y, 'right');
    // this.documentBody.text('200', 11, (y += 3), 'left');
    /*     if (this.controlaAforoReserva(options.entrada)) {
      if (options.recinto) {
        this.documentBody.setFontSize(14);
        this.documentBody.text(
          moment(options.recinto[0].FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('HH:mm'),
          11,
          y + 14,
          'left'
        );
        this.documentBody.text(
          options.recinto[0].RecintoNombre.substring(0, 22),
          11,
          (y += 8),
          'left'
        );
        this.documentBody.setFontSize(8);
      }
    } */
    /*     if (options.motivos) {
      this.documentBody.text(
        `${options.entrada.NombreImpresion.substring(0, 30)} / ${options.motivos.ItemNombre}`,
        11,
        (y += 27),
        'left'
      );
    } else {
      this.documentBody.text(
        `${options.entrada.NombreImpresion.substring(0, 30)}`,
        11,
        (y += 27),
        'left'
      );
    } */
    // this.documentBody.text(`${options.entrada.NombreImpresion} / ${'MOTIVO'}`, 11, (y += 27), 'left');
    /*  this.documentBody.text(
      `${options.boughtAt} / ${options.boughtAtHour} h COMPRA
    `,
      11,
      (y += 4),
      'left'
    ); */
    /*     this.documentBody.text(
      `${'PVP:'} ${this.addDotsToNumber(options.entrada.PrecioEnDivisaReferencia)} €`,
      90,
      y,
      'right'
    ); */
    /*    if (options.paseHorario) {
      this.documentBody.text(
        `${this.formatFecha(fecha)} / ${this.formatHora(hora)} VISITA`,
        11,
        (y += 4),
        'left'
      );
    } else {
      this.documentBody.text(
        `${this.formatFecha(fecha)} / ${'SIN SELECCION DE PASE HORARIO'} VISITA`,
        11,
        (y += 4),
        'left'
      );
    } */
    // this.documentBody.text(`${'FILA'} / ${'BUTACA'}`, 80, y, 'right');
    return this;
  }

  print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    connection.print(this.documentBody, this.type);
  }

  private controlaAforoReserva(entrada) {
    let controla = false;
    entrada.recintos.forEach(recinto => {
      if (recinto.Activo === '1' && recinto.ControlaAforo === '1') {
        controla = true;
      }
    });
    return controla;
  }

  private addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          newInteger += '.';
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
      }
      newInteger += decimal;
      return newInteger;
    }
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    return number + d;
  }

  private formatFecha(fecha) {
    const splitFecha = fecha.split('/');
    if (splitFecha[0].length === 4 && splitFecha.length === 3) {
      return splitFecha[2] + '/' + splitFecha[1] + '/' + splitFecha[0];
    }
    return fecha;
  }

  private formatHora(hora) {
    const splitHora = hora.split(':');
    if (splitHora.length === 3) {
      return splitHora[0] + ':' + splitHora[1] + ' h';
    }
    return hora + ' h';
  }
}
