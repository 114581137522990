import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  private confirmadoSubject: BehaviorSubject<any>;
  public confirmado: Observable<any>;

  constructor() {
    this.confirmadoSubject = new BehaviorSubject(false);
    this.confirmado = this.confirmadoSubject.asObservable();
   }

   public get confirmadoValue() {
    return this.confirmadoSubject.value;
  }

  setConfirmado(confirmacion) {
    this.confirmadoSubject.next(confirmacion);
  }
}
