import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfiguration {
  private config: Object = null;
  private env: Object = null;

  constructor(private http: HttpClient) {}

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return environment[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return environment[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   *   Avoided width comments for configuration from angular.jsoin replacements for deploy!!!! 
   */
  public load() {
/*     return new Promise((resolve, reject) => {
      this.http
        .get('assets/configurations/env.json')
        .pipe(
          map((res: any, err: any) => {
            if (err) {
              resolve(true);
              return Observable.throw(err.json().error || 'Server error');
            }
            return res;
          })
        )
        .subscribe(envResponse => {
          this.env = envResponse;
          let request: any = null;

          switch (envResponse.env) {
            case 'production':
              {
                request = this.http.get(
                  'assets/configurations/config.' + envResponse.env + '.json'
                );
              }
              break;

            case 'development':
              {
                request = this.http.get(
                  'assets/configurations/config.' + envResponse.env + '.json'
                );
              }
              break;

            case 'test':
              {
                request = this.http.get(
                  'assets/configurations/config.' + envResponse.env + '.json'
                );
              }
              break;

            case 'default':
              {
                console.error('Environment file is not set or invalid');
                resolve(true);
              }
              break;
          }
          if (request) {
            request
              .pipe(map(res => res))
              .pipe(
                catchError((error: any) => {
                  console.error(
                    'Error reading ' + envResponse.env + ' configuration file'
                  );
                  resolve(error);
                  return Observable.throw(error.json().error || 'Server error');
                })
              )
              .subscribe(responseData => {
                this.config = responseData;
                resolve(true);
              });
          } else {
            console.error('Env config file "env.json" is not valid');
            resolve(true);
          }
        });
    }); */
  }
}
