import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TurnoService } from '../auth';
import { TokenManagerService } from '../auth/token-manager.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: TokenManagerService, private turnoService: TurnoService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentToken = this.authenticationService.currentTokenValue;
    const currentTurno = this.turnoService.turnoValue;
    if (currentToken && currentTurno) {
      return true;
    }
    this.router.navigate(['login']);
    // this.router.navigate([''], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
