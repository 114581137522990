import { Component, EventEmitter, Input, OnChanges, OnInit, Output, 
  SimpleChanges, ElementRef, Inject, LOCALE_ID, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import * as moment from 'moment';
import 'moment/min/locales';
import * as _ from 'lodash';
import { LocalizadorService } from 'src/app/modules/localizador/services/localizador.service';

interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'ticketing-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() id: string;

  private element: any;
  moment = moment.locale(this.localeId);
  currentDate = moment();
  monthsFirstColumn = moment.months().splice(0, 6);
  monthsSecondColumn = moment.months().splice(6, 11);
  dayNames;
  weeks: CalendarDate[][] = [];
  sortedDates: CalendarDate[] = [];
  disabledDays: [] = [];

  constructor(private modalService: ModalService, private el: ElementRef, @Inject(LOCALE_ID) protected localeId,
               private cd: ChangeDetectorRef, private localizadorService: LocalizadorService) {
                this.dayNames = moment.weekdays(true);
                this.element = el.nativeElement;
               }

  ngOnInit() {
    const modal = this;
      // this.currentDate = this.localizadorService.fechaSeleccionada;
      if (!this.id) {
          return;
      }

      if (this.modalService.exists(this.id)) {
        this.modalService.remove(this.id);
        document.getElementsByTagName('ticketing-calendar').item(0);
      }

      document.body.appendChild(this.element);
      this.element.addEventListener('click', function (e: any) {
        if (e.target.className === 'jw-modal') {
            modal.close();
        }
      });
      this.modalService.add(this);
      this.generateCalendar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDates &&
        changes.selectedDates.currentValue &&
        changes.selectedDates.currentValue.length  > 1) {
      this.sortedDates = _.sortBy(changes.selectedDates.currentValue, (m: CalendarDate) => m.mDate.valueOf());
      this.generateCalendar();
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  // open modal
  open(): void {
      this.element.style.display = 'flex';
      document.body.classList.add('jw-modal-open');
  }

  // close modal
  close(): void {
      this.element.style.display = 'none';
      document.body.classList.remove('jw-modal-open');
  }

  prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.generateCalendar();
  }

  nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.generateCalendar();
  }

  selectMonth(month: any): void {
      this.currentDate = moment(this.currentDate).set('month', month);
      this.generateCalendar();
  }

  checkForDisabledMonths(month: any) {
    const m = moment();
    m.set('month', month);
    m.set('year', this.currentDate.year());
    m.set('day', this.currentDate.day());
    return !m.isBefore(moment(), 'month');
  }

  selectYear(year): void {
    if (year.isBefore(moment(), 'month')) {
      return;
    }
    this.currentDate = year;
    this.generateCalendar();
  }

  generateCalendar(): void {
    // this.localizadorService.getFechasFestivas(this.currentDate).subscribe((res: any) => {
    //   if (res.DatosResult) {
    //     this.disabledDays = res.DatosResult.LstDatosFestivos.map( dia => {
    //       const diaSinHora = dia.Fecha.split(' ')[0];
    //       return diaSinHora;
    //     });
    //   }
    // });
    const dates = this.fillDates(this.currentDate);
      const weeks: CalendarDate[][] = [];
      while (dates.length > 0) {
        weeks.push(dates.splice(0, 7));
      }
      this.weeks = weeks;
  }

  fillDates(currentMoment: moment.Moment): CalendarDate[] {
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth - 1, 'days');
    let start = firstDayOfGrid.date();
    start = start === 2 ? start - 7 : start;
    return _.range(start, start + 42)
            .map((date: number): CalendarDate => {
              const d = moment(firstDayOfGrid).date(date);
              return {
                today: this.isToday(d),
                selected: this.isSelected(d),
                disabled: this.isDisabled(d),
                mDate: d,
              };
            });
  }

  isDisabled(date: moment.Moment) {
    let exists;
    this.disabledDays.forEach( (day) => {
      if (date.format('DD/MM/YYYY') === day) {
        exists = true;
      }
    });
    return exists;
  }

  isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  isSelected(date: moment.Moment): boolean {
    return date.isSame(this.localizadorService.fechaSeleccionadaValue, 'day');
  }

  isSelectedMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.currentDate, 'month');
  }

  selectDate(date: CalendarDate): void {
    if (date.disabled) {
      return;
    }
    this.localizadorService.setFechaSeleccionadaValue(moment(date.mDate));
    this.close();
  }

  getYears() {
    const years = [];
    for (let i = 0; i < 5; i++) {
      years.push(moment().month(this.currentDate.month()).add(i, 'year'));
    }
    return years;
  }

}
