import { Printer } from '../printer.interface';
import * as jspdf from 'jspdf';
import { PrintableType } from '../printableType.enum';
import { OsConnectionFactory } from '../../connection/osConnection.factory';
import { ConnectionType } from '../../connection/connectionType.enum';
import * as QRCode from 'qrcode';
import { OnlineTicketOptions } from '../printOptions';
import * as moment from 'moment';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/conf';

export class OnlineTicket implements Printer {
  private documentBody;
  type: PrintableType;
  constructor(private translate: TranslateService, private config:AppConfiguration, private configuration:ConfigurationService) {
    this.type = PrintableType.TICKET;
    this.documentBody = new jspdf('p', 'mm', 'a4');
  }

  paint(options: OnlineTicketOptions): Printer {
    const fechaVenta = options.localizadorValue.FechaHoraVenta.split(' ')[0];
    const horaVenta = options.localizadorValue.FechaHoraVenta.split(' ')[1].slice(0, -3);
    const fechaEntrada = moment(options.localizadorValue.FechaHoraEntrada.split(' ')[0]).format(
      'DD/MM/YYYY'
    );
    const horaEntrada = options.localizadorValue.FechaHoraEntrada.split(' ')[1].slice(0, -3);
    const e = options.entrada;
    let y = 0; // = 10;
    this.documentBody.setFontSize(8);
    y += 8;
    if (options.qrCode !== '') {
      QRCode.toCanvas(options.qrCode, { errorCorrectionLevel: 'H' }, (err, canvas) => {
        if (err) {
          throw err;
        }
        const imgData = canvas.toDataURL('image/png');
        this.documentBody.addImage(imgData, 'PNG', 72, (y -= 2), 20, 20);
      });
    }
    // this.documentBody.text(`${options.totalQuantity}`, 90, (y + 21), 'right');
    // tslint:disable-next-line:max-line-length
    this.documentBody.text(`${options.i + 1}/${options.totalQuantity}`, 90, y + 21, 'right'); // creo que va siempre uno, xk la conjunta es la que tiene el total de entradas
    this.documentBody.text(options.tpvName, 90, y + 24, 'right');
    // this.documentBody.text(options.albaranId, 90, (y + 27), 'right');

    this.documentBody.text('', 11, y, 'left');
    this.documentBody.text(
      `${options.localizadorValue.ClaveReserva}-${options.i + 1}`,
      90,
      y,
      'right'
    );
    this.documentBody.text('200', 11, (y += 3), 'left');
    if (options.localizadorValue.ReservaRecintos[0]) {
      this.documentBody.setFontSize(14);
      this.documentBody.text(
        moment(
          options.localizadorValue.ReservaRecintos[0].FechaEntrada,
          'YYYY/MM/DD HH:mm:ss'
        ).format('HH:mm'),
        11,
        y + 14,
        'left'
      );
      this.documentBody.text(
        options.localizadorValue.ReservaRecintos[0].RecintoNombre.substring(0, 22),
        11,
        (y += 8),
        'left'
      );
      this.documentBody.setFontSize(8);
    }
    /*if (e.ListaMotivos) {

        this.documentBody.text(`${options.entrada.NombreImpresion} / ${options.entrada.ListaMotivos[0].Nombre}`, 11, (y += 27), 'left');

    } else {*/
    this.documentBody.text(`${options.entrada.NombreImpresion}`, 11, (y += 27), 'left');
    // }
    // this.documentBody.text(`${options.entrada.NombreImpresion} / ${'MOTIVO'}`, 11, (y += 27), 'left');
    this.documentBody.text(
      `${fechaVenta} / ${horaVenta} h COMPRA
        `,
      11,
      (y += 4),
      'left'
    );
    // TODO: aplica filtro al formato del precio
    this.documentBody.text(
      `${'PVP:'} ${this.addDotsToNumber(options.entrada.PrecioUnidad)} €`,
      90,
      y,
      'right'
    );

    if (horaVenta === horaEntrada) {
      this.documentBody.text(
        `${fechaEntrada} / ${'SIN SELECCION DE PASE HORARIO'} VISITA`,
        11,
        (y += 4),
        'left'
      );
    } else {
      this.documentBody.text(`${fechaEntrada} / ${horaEntrada} h VISITA`, 11, (y += 4), 'left');
    }

    // this.documentBody.text(`${'FILA'} / ${'BUTACA'}`, 80, y, 'right');
    return this;
  }

  print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    connection.print(this.documentBody, this.type);
  }

  addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          newInteger += '.';
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
      }
      newInteger += decimal;
      return newInteger;
    }
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    return number + d;
  }
}
