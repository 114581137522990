import * as jspdf from 'jspdf';
import * as moment from 'moment';
import * as QRCode from 'qrcode';
import { AppConfiguration } from 'src/app/app.configuration';
import { ConfigurationService } from 'src/app/core/conf';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { ConnectionType } from '../../connection/connectionType.enum';
import { OsConnectionFactory } from '../../connection/osConnection.factory';
import { ReprintedTicketOptions } from '../printOptions';
import { PrintableType } from '../printableType.enum';
import { Printer } from '../printer.interface';

export class ReprintedTicket implements Printer {
  private documentBody;
  type: PrintableType;
  constructor(private translate: TranslateService, private config: AppConfiguration, private configuration:ConfigurationService) {
    this.type = PrintableType.TICKET;
    this.documentBody = new jspdf('p', 'mm', 'a4');
  }

  paint(options: ReprintedTicketOptions): Printer {
    const fecha = moment(options.entrada.Fecha).format('DD/MM/YYYY');
    const hora = options.entradaDate.slice(0, -3);
    let y = 0; // = 10;
    this.documentBody.setFontSize(8);
    y += 8;
    if (options.qrCode !== '') {
      QRCode.toCanvas(options.qrCode, { errorCorrectionLevel: 'H' }, (err, canvas) => {
        if (err) {
          throw err;
        }
        const imgData = canvas.toDataURL('image/png');
        this.documentBody.addImage(imgData, 'PNG', 72, (y -= 2), 20, 20);
      });
    }
    this.documentBody.setFontSize(14);
    this.documentBody.text('R', 60, y + 10, 'right');
    this.documentBody.setFontSize(8);
    // this.documentBody.text(`${options.totalQuantity}`, 90, (y + 21), 'right');
    if (options.i) {
      this.documentBody.text(`${options.i}/${options.totalQuantity}`, 90, y + 21, 'right');
    } else {
      this.documentBody.text(`${options.totalQuantity}`, 90, y + 21, 'right');
    }
    this.documentBody.text(options.tpvName, 90, y + 24, 'right');
    this.documentBody.text(options.albaranId, 90, y + 27, 'right');

    this.documentBody.text('', 11, y, 'left');
    this.documentBody.text(`${options.locator} - ${options.i}`, 90, y, 'right');
    this.documentBody.text('200', 11, (y += 3), 'left');

    if (options.recinto && this.buscarInfoRecinto(options)) {
      const recinto = this.buscarInfoRecinto(options);
      if (options.recinto) {
        this.documentBody.setFontSize(14);
        this.documentBody.text(
          moment(recinto.FechaEntrada, 'YYYY/MM/DD HH:mm:ss').format('HH:mm'),
          11,
          y + 14,
          'left'
        );
        this.documentBody.text(recinto.RecintoNombre.substring(0, 22), 11, (y += 8), 'left');
        this.documentBody.setFontSize(8);
      }
    }
    if (options.entrada.motivoAsociado && options.entrada.motivoAsociado.ItemNombre) {
      this.documentBody.text(
        `${options.entrada.itemInfo.NombreImpresion} / ${options.entrada.motivoAsociado.ItemNombre}`,
        11,
        (y += 27),
        'left'
      );
    } else {
      this.documentBody.text(`${options.entrada.itemInfo.NombreImpresion}`, 11, (y += 27), 'left');
    }
    this.documentBody.text(
      `${options.boughtAt} / ${options.boughtAtHour} h COMPRA
      `,
      11,
      (y += 4),
      'left'
    );
    this.documentBody.text(
      `${'PVP:'} ${this.addDotsToNumber(options.entrada.itemInfo.PrecioEnDivisaReferencia)} €`,
      90,
      y,
      'right'
    );

    if (!options.recinto) {
      this.documentBody.text(
        `${fecha} / ${'SIN SELECCION DE PASE HORARIO'} VISITA`,
        11,
        (y += 4),
        'left'
      );
    } else {
      this.documentBody.text(`${fecha} / ${hora} h VISITA`, 11, (y += 4), 'left');
    }
    // this.documentBody.text(`${'FILA'} / ${'BUTACA'}`, 80, y, 'right');
    return this;
  }

  print(connectionType = 'VIRTUAL') {
    let conn = ConnectionType.VIRTUAL;
    if (connectionType === 'VIRTUAL') {
      conn = ConnectionType.VIRTUAL;
    }
    if (connectionType === 'ASPNET') {
      conn = ConnectionType.ASPNET;
    }
    const connection = OsConnectionFactory.getConnection(conn);
    connection.print(this.documentBody, this.type);
  }

  private controlaAforoReserva(entrada) {
    let controla = false;
    entrada.recintos.forEach(recinto => {
      if (recinto.Activo === '1' && recinto.ControlaAforo === '1') {
        controla = true;
      }
    });
    return controla;
  }

  buscarInfoRecinto(options) {
    return options.recinto.find(r => r.ItemId === options.entrada.ItemId);
  }

  addDotsToNumber(number, decimalLength = 2) {
    number = '' + number;
    number.replace('.', ',');
    if (number.includes(',')) {
      // tslint:disable-next-line:prefer-const
      let [integer, decimal] = number.split(',');
      if (integer.length > 3) {
        const integerArray = integer.split('');
        let newInteger = '';
        integerArray.forEach((num, index) => {
          if ((integerArray.length - index) % 3 === 0) {
            newInteger += '.';
          }
          newInteger += num;
        });
        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      } else {
        let newInteger = integer;

        while (decimal.length < decimalLength) {
          decimal += '0';
        }
        newInteger += ',' + decimal;
        return newInteger;
      }
    } else if (number.length > 3) {
      const integerArray = number.split('');
      let newInteger = '';
      integerArray.forEach((num, index) => {
        if ((integerArray.length - index) % 3 === 0) {
          newInteger += '.';
        }
        newInteger += num;
      });
      let decimal = ',';
      while (decimal.length <= decimalLength) {
        decimal += '0';
      }
      newInteger += decimal;
      return newInteger;
    }
    // 
    let d = ',';
    for (let i = 0; i < decimalLength; ++i) {
      d += '0';
    }
    return number + d;
  }

  formatFecha(fecha) {
    const splitFecha = fecha.split('/');
    if (splitFecha[0].length === 4 && splitFecha.length === 3) {
      return splitFecha[2] + '/' + splitFecha[1] + '/' + splitFecha[0];
    }
    return fecha;
  }

  formatHora(hora) {
    const splitHora = hora.split(':');
    if (splitHora.length === 3) {
      return splitHora[0] + ':' + splitHora[1] + ' h';
    }
    return hora + ' h';
  }
}
