import { Injectable } from '@angular/core';
import { VentaService } from '../../modules/venta/services/venta.service';
import { FormGroup } from '@angular/forms';
import { MyCurrencyPipe } from '../pipes/myCurrency.pipe';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NumpadService {
  order = '';
  selected;
  commaDisabled;
  entrada;
  formGeneral: FormGroup;
  noFocus;

  public billetesMonedasSubject: BehaviorSubject<any>;
  public billetesMonedas$: Observable<any>;
  listaBilletesDisponibles;


  constructor(private ventaService: VentaService, private currency: MyCurrencyPipe) {
    
    this.billetesMonedasSubject = new BehaviorSubject(undefined);
    this.billetesMonedas$ = this.billetesMonedasSubject.asObservable();
  }

  public get billetesMonedasValue(){
    return this.billetesMonedasSubject.value;
  }
  public setBilletesMonedas(lista){
    
   this.billetesMonedasSubject.next(lista);
  }
  setNumpadOrder(order: string) {
    
    this.order = order;
    if (this.selected.getAttribute('category') === 'CANTIDAD') {
      this.selected.value = this.validateOrderEntrada();
    } else if (this.selected.getAttribute('category') === 'DESGLOSE') {
       //this.selected.value = this.validateOrderMoneda();
    } else if (this.selected.getAttribute('category') === 'pago') {
      if (!this.formGeneral) {
        return;
      }
      this.selected.value = this.validateOrderPago();
    } else if (this.selected.getAttribute('id') === 'search_field') {
      this.selected.value = this.validateOrderPago();
    }
  }

  // validateOrderMoneda(): string {
  //   
  //   this.listaBilletesDisponibles = this.billetesMonedasValue;
  //   let index = this.listaBilletesDisponibles.findIndex(x=> x=== this.entrada);
  //   switch (this.order) {
  //     case 'delete':
  //       const newstr = this.selected.value.substring(0, this.selected.value.length - 1);
  //       if (newstr.length > 0) {
  //         this.listaBilletesDisponibles[index] = {...this.listaBilletesDisponibles[index], cantidad:newstr};
  //         this.setBilletesMonedas(this.listaBilletesDisponibles);
  //         return newstr;
  //       } else {
  //         this.listaBilletesDisponibles[index] = {...this.listaBilletesDisponibles[index], cantidad:0};
  //         this.setBilletesMonedas(this.listaBilletesDisponibles);
  //         return '0';
  //       }
  //     case 'reset':
  //       this.listaBilletesDisponibles[index] = {...this.listaBilletesDisponibles[index], cantidad:0};
  //       this.setBilletesMonedas(this.listaBilletesDisponibles);
  //       return '0';
  //     case 'losefocus':
  //       if (this.selected.value === '') {
  //         this.selected.value = 0;
  //       }
  //       this.selected.focus = false;
  //       return this.selected.value;
  //     default:
  //       const oldValue = ''.concat(this.selected.value);
  //       if (oldValue === '0' && this.order === '0') {
  //         return '';
  //       }
  //       const newValue = oldValue.concat(this.order);
  //       const Ncomma = (newValue.match(/,/g) || []).length;
  //       let Nintegers = 0;
  //       let Ndecimals = 0;
  //       if (Ncomma > 0) {
  //         Nintegers = newValue.split('.')[0].length;
  //         Ndecimals = newValue.split('.')[1].length;
  //       } else {
  //         Nintegers = newValue.length;
  //       }
  //       if (Ncomma < 2) {
  //         if (Ndecimals < 3 && Nintegers < 4) {
  //           if (oldValue === '' || oldValue === '0') {
  //             this.listaBilletesDisponibles[index] = {...this.listaBilletesDisponibles[index], cantidad:this.order};
  //             this.setBilletesMonedas(this.listaBilletesDisponibles);
  //             return this.order;
  //           } else {
  //             this.listaBilletesDisponibles[index] = {...this.listaBilletesDisponibles[index], cantidad: newValue};
  //             this.setBilletesMonedas(this.listaBilletesDisponibles);
  //             return newValue;
  //           }
  //         } else {
  //           return oldValue;
  //         }
  //       } else {
  //         return oldValue;
  //       }
  //   }
  // }

  validateOrderEntrada(): string {
    
    switch (this.order) {
      case 'delete':
        const newstr = this.selected.value.substring(0, this.selected.value.length - 1);
        if (newstr.length > 0) {
          this.ventaService.sendEntradas(this.entrada, +newstr);
          return newstr;
        } else {
          this.ventaService.sendEntradas(this.entrada, 0);
          return '';
        }
      case 'reset':
        this.ventaService.sendEntradas(this.entrada, 0);
        return '0';
      case 'losefocus':
        if (this.selected.value === '') {
          this.selected.value = 0;
          this.ventaService.sendEntradas(this.entrada, 0);
        }
        this.selected.focus = false;
        return this.selected.value;
      default:
        const oldValue = ''.concat(this.selected.value);
        if (oldValue === '0' && this.order === '0') {
          return '';
        }
        const newValue = oldValue.concat(this.order);
        const Ncomma = (newValue.match(/,/g) || []).length;
        let Nintegers = 0;
        let Ndecimals = 0;
        if (Ncomma > 0) {
          Nintegers = newValue.split('.')[0].length;
          Ndecimals = newValue.split('.')[1].length;
        } else {
          Nintegers = newValue.length;
        }
        if (Ncomma < 2) {
          if (Ndecimals < 3 && Nintegers < 4) {
            if (oldValue === '' || oldValue === '0') {
              this.ventaService.sendEntradas(this.entrada, +this.order);
              return this.order;
            } else {
              this.ventaService.sendEntradas(this.entrada, +newValue);
              return newValue;
            }
          } else {
            return oldValue;
          }
        } else {
          return oldValue;
        }
    }
  }

  validateOrderPago(): string {
    switch (this.order) {
      case 'delete':
        const newstr = this.selected.value.substring(0, this.selected.value.length - 1);
        if (newstr.length > 0) {
          this.formGeneral.controls[this.selected.getAttribute('formcontrolname')].patchValue(
            newstr
          );
          return newstr;
        } else {
          this.formGeneral.controls[this.selected.getAttribute('formcontrolname')].patchValue('');
          return '';
        }
      case 'reset':
        this.formGeneral.controls[this.selected.getAttribute('formcontrolname')].patchValue('');
        return '';
      case 'losefocus':
        if (this.selected.value === '') {
          this.selected.value = '';
        }
        this.selected.focus = false;
        return this.selected.value;
      default:
        const oldValue = ''.concat(this.selected.value);
        if (oldValue === '' && this.order === '0') {
          return '0';
        }
        const newValue = oldValue.concat(this.order);
        const Ncomma = (newValue.match(/,/g) || []).length;
        let Ndecimals = 0;
        if (Ncomma > 0) {
          Ndecimals = newValue.split(',')[1].length;
        }
        if (Ncomma < 2) {
          if (Ndecimals < 3) {
            if (oldValue === '') {
              this.formGeneral.controls[this.selected.getAttribute('formcontrolname')].patchValue(
                this.order
              );
              return this.order;
            } else {
              this.formGeneral.controls[this.selected.getAttribute('formcontrolname')].patchValue(
                newValue
              );
              return newValue;
            }
          } else {
            this.formGeneral.controls[this.selected.getAttribute('formcontrolname')].patchValue(
              oldValue
            );
            return oldValue;
          }
        } else {
          this.formGeneral.controls[this.selected.getAttribute('formcontrolname')].patchValue(
            oldValue
          );
          return oldValue;
        }
    }
  }
}
