import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards';
import { DetalleVentaComponent } from './components/detalle-venta/detalle-venta.component';
import { ImprimirVentaComponent } from './components/imprimir-venta/imprimir-venta.component';
import { SelectorDevolucionComponent } from './components/selector-devolucion/selector-devolucion.component';
import { SelectorPagoDevolucionComponent } from './components/selector-devolucion/selector-pago-devolucion.component';
import { GestionarVentaComponent } from './gestionar-venta.component';

const gestionarVentaRoutes: Routes = [
    {
        path: 'gestionar-venta',
        component: GestionarVentaComponent,
        children: [
          { path: '', component: DetalleVentaComponent },
          { path: 'devolucion-parcial', component: SelectorDevolucionComponent },
          { path: 'pago-devolucion-parcial', component: SelectorPagoDevolucionComponent },
          { path: 'imprimir', component: ImprimirVentaComponent }
        ],
        canActivate: [AuthGuard]
      },
      //JS prueba Path externos
      {
        path: 'gestionar-venta/:tpv/:albaran',
        component: GestionarVentaComponent//,
        //canActivate: [AuthGuard]
      },
      {path: '**', component: DetalleVentaComponent}
      //FJS
];
@NgModule({
    imports: [
      RouterModule.forChild(gestionarVentaRoutes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class gestionarVentaRoutingModule { }