import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { PanelComponent } from '../panel/panel.component';

@Component({
  selector: 'ticketing-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements AfterContentInit {
  @ContentChildren(PanelComponent) panels: QueryList<PanelComponent>;

  ngAfterContentInit() {
    this.panels.toArray().forEach((t) => {
      t.toggle.subscribe(() => {
        this.openPanel(t);
      });
    });
  }

  openPanel(panel: PanelComponent) {
    panel.opened = !panel.opened;
  }
}
