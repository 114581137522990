export class InsUpdContactoAlbaranModel {
    TPV: string;
    NumAlbaran: string;
    Nombre: string;
    CIF: string;
    Email: string;
    Telefono: string;
    Fax: string;
    Domicilio: string;
    Localidad: string;
    Pais: string;
    CP: string;
    Apellido1: string;
    Apellido2: string;
    Provincia: string;
    FechaNacimiento: string;
    Sexo: string;
    PublicidadInterna: string;
    Contacto: string;
    TipoPolitica: string;
    EnlacePolitica: string;
    NombreProyecto: string;
    NombreFormulario: string;
    Notas: string;
    Pregunta1: string;
    Respuesta1: string;
    Pregunta2: string;
    Respuesta2: string;
    Pregunta3: string;
    Respuesta3: string;
    Pregunta4: string;
    Respuesta4: string;
}
