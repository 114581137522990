import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlbaranService } from './services/albaran.service';
import { ConfigurationService, TurnoService } from 'src/app/core';
import { VentaService } from '../venta';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { EncriptaService } from 'src/app/shared/services/encripta.service';
import { LoginService } from 'src/app/core/auth/login.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'ticketing-gestionar-venta',
  templateUrl: './gestionar-venta.component.html',
  styleUrls: ['./gestionar-venta.component.scss'],
   // tslint:disable-next-line:use-host-property-decorator
   host: {
    class: 'layout'
  }
})
export class GestionarVentaComponent implements OnInit, OnDestroy {
  tpvParametro: string;
  albaranParametro:string;

  constructor(
    private turno: TurnoService,
    private ventaService: VentaService,
    private albaranService: AlbaranService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private configuration: ConfigurationService,
    private router: Router
  ) { }

  ngOnInit() {
    let datosUrl;
    if (this.route.snapshot.queryParams.ck) {
      datosUrl = this.route.snapshot.queryParams.ck;
      const token = JSON.parse(EncriptaService.funcionDesencript(datosUrl));
      // const cadena =
      // '{"usuario": "' +
      // 'Administrador' +
      // '", "password": "' +
      // '14acpo02s' +
      // '", "tpv": "' +
      // '4' +
      // '", "albaran": "' +
      // '350' +
      // '"}';
      // const tokenEncriptado = EncriptaService.funcionEncript(cadena);
      // 
      // 
      
      this.tpvParametro = token.tpv? token.tpv:'';
      this.albaranParametro = token.albaran? token.albaran: '';
      const user:string =  JSON.parse(localStorage.getItem('currentUser')) ? JSON.parse(localStorage.getItem('currentUser')):'';
      const pass:string = JSON.parse(localStorage.getItem('currentPwd')) ? JSON.parse(localStorage.getItem('currentPwd')):'';
      if(token.tpvPath) this.loginService.setTpvPath(token.tpvPath);
//      const path: string = JSON.parse(localStorage.getItem('currentPath')) ? JSON.parse(localStorage.getItem('currentPwd')):token.path? token.path: '';
        this.loginService.getInitData().subscribe(arg => {
          if(user.toUpperCase() == token.usuario.toUpperCase() && pass.toUpperCase() == token.password.toUpperCase()){              
            this.loginService.CargarDatosTurno(token.tokenOrigen);
            this.CargarAlbaran();
           } else {
            this.loginService.setVentanaOrigen('gestionar');
          this.loginService.cargarDatosLogin(
            token.usuario?token.usuario:'',
            token.password?token.password:'',
            '0',
            undefined,
            token.tokenOrigen
            ).subscribe(res =>{
              if(res)
              { 
                this.CargarAlbaran();
              } else{
                this.router.navigate(['']);
              }
            });
           }
          });
      


    } else {
      if(this.configuration.datosInicioValue)
      {
        this.tpvParametro = this.route.snapshot.queryParams['tpv'];
        this.albaranParametro = this.route.snapshot.queryParams['albaran'];
        this.CargarAlbaran();
      } else{
        this.router.navigate(['']);
      }


    }
    
  }

  ngOnDestroy() {
    if (this.ventaService.carritoValue && this.ventaService.carritoValue.albaran &&
      this.ventaService.carritoValue.albaran.pkId && this.ventaService.carritoValue.albaran.pkId > 0) {
    } else {
      
      this.albaranService.setAlbaranValue(undefined);
    }
  }

  getPuedoCargarDatos()
  {
    return this.configuration.datosInicioValue;
  }

  CargarAlbaran(){
    if (this.tpvParametro && this.albaranParametro){
            this.albaranService.getAlbaranxNum(this.tpvParametro, this.albaranParametro);
          }else{
            this.albaranService.getUltimoAlbaran(this.turno.turnoValue.TpvId);
          }
  }
  

}
