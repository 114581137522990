import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigurationService } from 'src/app/core/conf/configuration.service';
import { TurnoService } from 'src/app/core/auth/turno.service';
import { Router } from '@angular/router';
import { UserManagerService } from 'src/app/core/auth/user-manager.service';
import { User } from 'src/app/core/user.model';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { SuccessToast } from 'src/app/core/toast/success.toast';
import { ToastrService } from 'ngx-toastr';
import { mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { VentaService } from 'src/app/modules/venta';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { MotivosService } from 'src/app/modules/venta/services/motivos.service';

@Component({
  selector: 'ticketing-agenda-login',
  templateUrl: './agenda-login.component.html',
  styleUrls: ['./agenda-login.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'layout__cambiar-usuario'
  }
})
export class AgendaLoginComponent implements OnInit, OnDestroy {
  error;
  loging = false;
  loginForm: FormGroup;
  constructor(
    private configuration: ConfigurationService,
    private turnoService: TurnoService,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private router: Router,
    private userService: UserManagerService,
    private toastr: ToastrService,
    private ventaService: VentaService,
    private pagoService: PagoService,
    private motivosService: MotivosService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      usuario: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]*$')])
      ],
      contrasena: ['', Validators.required],
      cambioInicial: ''
    });
  }

  ngOnDestroy() {
    this.formControlService.unfocus();
  }

  login() {
    if (this.checkLoginIsInvalid() || this.loging) {
      return;
    }
    this.encripta(
      this.loginForm.controls['usuario'].value,
      this.loginForm.controls['contrasena'].value
    );
    this.loging = true;
  }

  encripta(user, pass) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const path = JSON.parse(localStorage.getItem('currentPath'))|| undefined;
    const cadena =
      '{"nombre": "' +
      user +
      '", "contrasena": "' +
      pass +
      '", "ConexionIacpos": "' +
      conexionId +
      (path? '", "tpvPath": "' +
      path: '') + 
      '"}';
    let encodeStr = '';
    const clave = '%ü&/@#$A·33424kjfdsfffsñdlfjdfksdfi234';

    for (let i = 0; i < cadena.length; i++) {
      const sCodigo = clave[this.fmod(i, clave.length)];
      encodeStr =
        // tslint:disable-next-line: no-bitwise
        encodeStr + ('0' + (sCodigo.charCodeAt(0) ^ cadena.charCodeAt(i)).toString(16)).substr(-2);
    }

    this.router.navigate([]).then(result => {
      window.open(
        `http://admin.madridsnowzone.com/admin/Agenda.aspx?ARGS=${encodeStr}`,
        '_blank'
      );
    });
    this.router.navigate(['']);
  }

  fmod(a, b) {
    return Number((a - Math.floor(a / b) * b).toPrecision(8));
  }

  checkLoginIsInvalid() {
    if (this.loginForm.invalid) {
      return true;
    }
    return false;
  }

  focusValue(event: any) {
    this.formControlService.setCurrentFormGroup(
      this.loginForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  deleteValue() {
    this.formControlService.setToBlank();
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }

  mostrarMensajeLoginIncorrecto() {
    this.error = 'Error de identificación';
  }
}
