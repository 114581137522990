import { Component, OnInit, OnDestroy } from '@angular/core';
import { TabsNavigationService } from '../../services/tabs-navigation.service';
import { ModalService } from 'src/app/shared/services/modal.service';


@Component({
  selector: 'ticketing-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  tabs = [];
  constructor(private tabsNavigation: TabsNavigationService, private modalService: ModalService) { }

  ngOnInit() {
    this.tabsNavigation.getTabs().subscribe( tabs => this.tabs = tabs);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
      this.modalService.close(id);
  }
}
