import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioElevadoService {

  private haSidoElevadoSubject: BehaviorSubject<boolean>;
  haSidoElevado$: Observable<boolean>;

  constructor() {
    this.haSidoElevadoSubject = new BehaviorSubject(false);
    this.haSidoElevado$ = this.haSidoElevadoSubject.asObservable();
  }

  get haSidoElevadoValue(){
    return this.haSidoElevadoSubject.value;
  }
  elevar() {
    this.haSidoElevadoSubject.next(true);
  }

  reducir() {
    this.haSidoElevadoSubject.next(false);
  }
}
