import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { AlbaranService } from '../../../services/albaran.service';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfigurationService } from 'src/app/core';

@Component({
  selector: 'ticketing-buscador-albaran',
  templateUrl: './buscador-albaran.component.html',
  styleUrls: ['./buscador-albaran.component.scss']
})
export class BuscadorAlbaranComponent implements OnInit {
  busquedaAlbaranForm: FormGroup;
  newQuery = '';
  filtrarCentro;
  constructor(
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private albaranService: AlbaranService,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this.filtrarCentro = this.configurationService.appConfig.value.DatosResult.AppConfigJsonVentaGestionar.FiltrarCentro && this.configurationService.appConfig.value.DatosResult.AppConfigJsonVentaGestionar.FiltrarCentro > 0? true:false
    this.busquedaAlbaranForm = this.formBuilder.group({
      tpvId: '',
      query: '',
      filtrarCentro: this.filtrarCentro
    });
    this.albaranService
      .buscar(this.busquedaAlbaranForm.valueChanges)
      .subscribe();
    // this.albaranService.albaran.subscribe( albaran => {
    //   if (albaran && !albaran.noData) {
    //     this.busquedaAlbaranForm.get('query').setValue(albaran.NumAlbaran, {onlySelf: true, emitEvent: false});
    //   } else {
    //     this.busquedaAlbaranForm.get('query').setValue(this.newQuery, {onlySelf: true, emitEvent: false});
    //   }
    // });
  }

  focusValue(event) {
    this.formControlService.setCurrentFormGroup(
      this.busquedaAlbaranForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  onMovimiento(event) {
    
    if (this.busquedaAlbaranForm.get('query').value === '') {
      // this.busquedaAlbaranForm.get('query').patchValue(this.albaranService.albaranValue.NumAlbaran);
      const queryNumAlbaran =
        +this.albaranService.albaranValue.NumAlbaran + event;
      this.albaranService.buscar2(of(queryNumAlbaran)).subscribe();
      return;
    }
    if (
      !Number.isNaN(
        Number.parseInt(this.busquedaAlbaranForm.get('query').value, 10)
      )
    ) {
      this.newQuery =
        '' +
        (Number.parseInt(this.busquedaAlbaranForm.get('query').value, 10) +
          event);
      this.busquedaAlbaranForm.get('query').setValue(this.newQuery);
    } else {
      this.busquedaAlbaranForm.get('query').setValue('');
    }
  }

  isSelected(element) {
    return this.formControlService.currentFormGroupValue &&
    this.formControlService.currentFormGroupValue.elemento &&
      element.id === this.formControlService.currentFormGroupValue.elemento.id
      ? true
      : false;
  }
  changefiltrarCentro(){
    this.filtrarCentro = !this.filtrarCentro;
  }
  mostrarFiltro(){
    return this.configurationService.appConfig.value.DatosResult.AppConfigJsonVentaGestionar.FiltrarCentro && this.configurationService.appConfig.value.DatosResult.AppConfigJsonVentaGestionar.FiltrarCentro == 2;
  }

  claseConFiltro(){
    if(this.mostrarFiltro()){
      return 'inputControl control-div-buscador conFiltro'
    }
    return 'inputControl control-div-buscador'
  }
}
