import { NgModule,  LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//<< Yaribel 20210105 Añadimos para unificar el translate service en un solo modulo
// Locales
import localeEs from '@angular/common/locales/es';
import localeEs419 from '@angular/common/locales/es-419';
import localeCa from '@angular/common/locales/ca';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
// Yaribel 20210105 >>

import { MyCurrencyPipe} from './pipes/myCurrency.pipe';
import { TranslateService } from './services/translate.service'; // Yaribel 20210105 Añadimos para unificar el translate service en un solo modulo
import { CerrarTurnoComponent } from './components/cerrar-turno/cerrar-turno.component';
import { ConfirmarCierreComponent } from './components/confirmar-cierre/confirmar-cierre.component';
import { GestionarTurnoComponent } from './components/gestionar-turno/gestionar-turno.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ImpresionModalComponent } from './components/impresion-modal/impresion-modal.component';
import { ConfirmarAnulacionVentaComponent } from './components/confirmar-anulacion-venta/confirmar-anulacion-venta.component';
import { FacturacionComponent } from './components/facturacion/facturacion.component';
import { ConfirmarUsuarioComponent } from './components/confirmar-usuario/confirmar-usuario.component';
import { EtiquetasModalComponent } from './components/etiquetas-modal/etiquetas-modal.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { ModalComponent } from './components/modal/modal.component';
import { NumpadComponent } from './components/numpad/numpad.component';
import { KeyboardComponent } from './components/keyboard/keyboard.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ElevarUsuarioComponent } from './components/elevar-usuario/elevar-usuario.component';
import { ConfirmarEntradasLocalizadorComponent } from './components/confirmar-entradas-localizador/confirmar-entradas-localizador.component';
import { CalendarEtiquetasComponent } from './components/etiquetas-modal/calendar-etiquetas/calendar-etiquetas.component';

/* import { ConfirmarSalirFacturaComponent } from './components/confirmar-salir-factura/confirmar-salir-factura.component';
import { ConfirmarExcesoEntradasComponent } from './components/confirmar-exceso-entradas/confirmar-exceso-entradas.component';
import { ConfirmarArqueoAleatorioComponent } from './components/confirmar-arqueo-aleatorio/confirmar-arqueo-aleatorio.component'; */
import { AddDotsToNumberPipe } from './pipes/add-dots-to-number.pipe';
import { FormatFechaPipe } from './pipes/format-fecha.pipe';
import { FormatHoraPipe } from './pipes/format-hora.pipe';
import { FechaPipe } from './pipes/fecha.pipe';
import {orderByPipe} from './pipes/orderBy.pipe';
import { CalendarAuditorioComponent } from './components/calendar-auditorio/calendar-auditorio.component';
import { ModalContactoComponent } from '../modules/gestionar-venta/components/detalle-venta/modal-contacto/modal-contacto.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmarCanjeoCaducadoComponent } from './components/confirmar-canjeo-caducado/confirmar-canjeo-caducado.component';
import { APP_INITIALIZER } from '@angular/core'; // Yaribel 20210105 Añadimos para unificar el translate service en un solo modulo
import { ConfirmarCambioFechaComponent } from './components/confirmar-cambio-fecha/confirmar-cambio-fecha.component';
import { FormaPagoPipe } from '../modules/gestionar-venta/pipes/forma-pago.pipe';
import { DateFormatPipe } from '../modules/auditorio/date.pipe';
import { ConfirmarGestionComponent } from './components/confirmar-gestion/confirmar-gestion.component';
import { ValidarClienteAsociadoComponent } from '../modules/Asociado/components/validar-cliente-asociado/validar-cliente-asociado.component';
import { DesglosarCambioComponent } from '../modules/gestionar/components/desglosar-cambio/desglosar-cambio.component';
import  {AutofocusDirective} from '../modules/gestionar/components/desglosar-cambio/auto-focus.directive';
// import { ConfirmarImpresionDiaDistintoComponent } from './components/confirmar-impresion-dia-distinto/confirmar-impresion-dia-distinto.component';

//<< Yaribel 20210105 Añadimos para unificar el translate service en un solo modulo
export function setupTranslateFactory(service: TranslateService): Function {
  return () => {
    service.use(navigator.language);
  };
}
// Yaribel 20210105 >>

@NgModule({
  declarations: [
    TranslatePipe,
    MyCurrencyPipe,
    FormaPagoPipe,
    DateFormatPipe,
    orderByPipe,
    ModalComponent,
    NumpadComponent,
    KeyboardComponent,
    CalendarComponent,
    CerrarTurnoComponent,
    ConfirmarCierreComponent,
    GestionarTurnoComponent,
    DatePickerComponent,
    ImpresionModalComponent,
    ConfirmarAnulacionVentaComponent,
    FacturacionComponent,
    ModalContactoComponent,
    ConfirmarUsuarioComponent,
    EtiquetasModalComponent,
    ElevarUsuarioComponent,
    ConfirmarEntradasLocalizadorComponent,
    CalendarEtiquetasComponent,
    DesglosarCambioComponent,
    /*    ConfirmarSalirFacturaComponent,
    ConfirmarExcesoEntradasComponent,
    ConfirmarArqueoAleatorioComponent, */
    AddDotsToNumberPipe,
    FormatFechaPipe,
    FormatHoraPipe,
    FechaPipe,
    CalendarAuditorioComponent,
    ConfirmarCanjeoCaducadoComponent,
    ConfirmarCambioFechaComponent,
    ValidarClienteAsociadoComponent,
    ConfirmarGestionComponent,
    AutofocusDirective
    // ConfirmarImpresionDiaDistintoComponent
  ],
  imports: [CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule, NgbDatepickerModule],
  exports: [
    TranslatePipe,
    orderByPipe,
    ModalComponent,
    CalendarComponent,
    CerrarTurnoComponent,
    NumpadComponent,
    KeyboardComponent,
    MyCurrencyPipe,
    DateFormatPipe,
    FormaPagoPipe,
    ConfirmarCierreComponent,
    GestionarTurnoComponent,
    DatePickerComponent,
    ImpresionModalComponent,
    ConfirmarAnulacionVentaComponent,
    FacturacionComponent,
    ModalContactoComponent,
    EtiquetasModalComponent,
    ElevarUsuarioComponent,
    ConfirmarEntradasLocalizadorComponent,
    CalendarEtiquetasComponent,
    DesglosarCambioComponent,
    /*     ConfirmarSalirFacturaComponent,
    ConfirmarExcesoEntradasComponent,
    ConfirmarArqueoAleatorioComponent,
    ConfirmarReimpresionDiaDistintoComponent,
    ImpresionLocalizadorModalComponent, */
    AddDotsToNumberPipe,
    FormatFechaPipe,
    FormatHoraPipe,
    FechaPipe,
    CalendarAuditorioComponent,
    ConfirmarCanjeoCaducadoComponent,
    ConfirmarCambioFechaComponent,
    ConfirmarGestionComponent,  
    ValidarClienteAsociadoComponent,
    AutofocusDirective

    // ConfirmarImpresionDiaDistintoComponent
  ],
  //<< Yaribel 20210105 Añadimos para unificar el translate service en un solo modulo
  providers:[
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    MyCurrencyPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: translateService => translateService.getLocale()
    }
  ]
  // Yaribel 20210105 >>
})
export class SharedModule {
  // Yaribel 20210105 Añadimos para unificar el translate service en un solo modulo
  constructor() {
    registerLocaleData(localeEs, 'es-ES');
    registerLocaleData(localeCa, 'ca');
    registerLocaleData(localeEn, 'en-GB');
    registerLocaleData(localeEs419, 'es-419');
    // Yaribel 20210105 >>
}
}
