import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/app/app.configuration';

@Injectable({
  providedIn: 'root'
})
export class InfoMapasService {
  recinto;

  constructor(
    private http: HttpClient,
    private configuration: AppConfiguration
  ) {}

  getSVG(nombreRecinto: string) {
    return this.http.get(
      `assets/recintos/${this.configuration.getEnv('svgFolder')}/${nombreRecinto}`,
      { responseType: 'svg' as 'json' }
    );
  }

  getButacas(nombreRecinto: string): any {
    const nRecinto = nombreRecinto.split('.');
    return this.http.get(
      `assets/recintos/${this.configuration.getEnv('svgFolder')}/${nRecinto[0]}.json`
    );
  }
}
