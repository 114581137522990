import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfiguration } from 'src/app/app.configuration';
import { shareReplay, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  constructor(private http: HttpClient, private config: AppConfiguration) { 
    
  }

  buscar(terms: Observable<any>) {
    return terms
      .pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap(query => {
          if (query.searchField.length > 2) {
            return this.findClientes(query.searchField);
          } else {
            return [];
          }
        })
      );
  }

  findClientes(query: string, validarBaja = '1') {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      TPVId: '1',
      Filtro: query,
      ValidarBaja: validarBaja
    };
    return this.http
      .post(`${this.config.getConfig('API_URL')}api/GetClientesXFiltro`, body)
      .pipe(shareReplay(1))
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return [];
          }
          return res.DatosResult.ListadoCliente;
        })
      );
  }
}
