import { Component, Input, OnInit } from '@angular/core';
import { Albaran } from '../../../models/albaran.model';
import { FacturaService } from 'src/app/shared/services/factura.service';
import { PrintService } from 'src/app/shared/services/print.service';
import { TranslateService } from 'src/app/shared/services/translate.service';

@Component({
  selector: 'ticketing-detalle-box',
  templateUrl: './detalle-box.component.html',
  styleUrls: ['./detalle-box.component.scss']
})
export class DetalleBoxComponent implements OnInit {
  @Input() albaran: Albaran;
  hayCodigoPromocional = false;
  codigoPromocional = '';
  constructor(
    private facturaService: FacturaService,
    private printService: PrintService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.albaran) {
      this.albaran.AlbaranItems.forEach(item => {
        if (item.CodigoPromocional.trim() !== '') {
          this.hayCodigoPromocional = true;
          this.codigoPromocional = item.CodigoPromocional;
          return true;
        }
      });
    }
  }

  imprimir(albaran) {
    this.facturaService.pdfFactura(albaran).subscribe(res => {
      if (res) {
        this.printService.printFacturaPDF(res.DatosResult);
      }
    });
  }


    private generarFOP(fop) {
    switch (fop) {
      // case 'CA':
      //   return 'EFECTIVO';
      // case 'CC':
      //   return 'TARJETA';
      // case 'TR':
      //   return 'TRANSFERENCIA';
      // case 'TM':
      //   return 'TARJETA MONEDERO';  // Yaribel 12112020  Añadimos la etiqueta para que se visualice el  nombre de la forma de pago TM en el detalle de la venta
      // case 'DP':
      //  return 'APLAZADO';  // Yaribel 20201209  Añadimos la etiqueta para que se visualice el  nombre de la forma de pago DP en el detalle de la venta
      // case 'PR':
      //   return 'PREPAGO'; //Yaribel 20201217 añadimos forma de pago Prepago 
      // default:
      //  return 'EFECTIVO';
       case 'CA':
        return this.translate.instant('EFECTIVO');
      case 'CC':
        return this.translate.instant('TARJETA');
      case 'TR':
        return this.translate.instant('TRANSFERENCIA');
      case 'TM':
        return this.translate.instant('JUSTIFICANTE_TARJETA_MONEDERO');
      case 'DP':
        return this.translate.instant('APLAZADO');
      case 'PR':
        return this.translate.instant('PREPAGO');  
      default:
        return this.translate.instant('EFECTIVO');
    }
  }

  formatearPrecio(precio) {
    if (typeof precio === 'string') {
      precio = Number.parseFloat(precio.replace(',', '.'));
    }
    return precio.toFixed(2);
  }

  getNumAlbaran(registro){
    let texto = '';
    if(registro.TpvAsociado && registro.AlbaranAsociado)
    texto = '(' + registro.TpvAsociado + '-' + registro.AlbaranAsociado + ')';
    return texto;
  }
}
