import { Butaca } from '../espectaculos/butaca.model';
import { Entrada } from 'src/app/modules/venta/models/entrada.model';
import * as moment from 'moment';

// Comentar con Roberto y Javi si realmente es necesario este cacau
export const butacaToEntrada = (butaca: Butaca): Entrada => {
  const entrada: Entrada = {
    FechaHoraEntrada:
      moment(butaca.fecha, 'YYYYMMDD').format('YYYY/MM/DD') + ' ' + butaca.hora,
    idTarifa: butaca.tipoSeleccionado.IdTarifa,
    NombreTipo: butaca.tipoSeleccionado.NombreEntrada,
    Fecha: moment(butaca.fecha, 'YYYYMMDD').format('YYYY/MM/DD'),
    id: '',
    noEditable: false,
    ItemId: butaca.tipoSeleccionado.IdTarifa,
    CategoriaId: 1,
    TipoEntradaId: butaca.tipoSeleccionado.TipoEntradaId,
    TipoPromocionId: '0',
    TipoAbonadoId: '',
    GrupoId: '1',
    GrupoMotivoId: '',
    NombrePantalla: butaca.tipoSeleccionado.NombreEntrada,
    NombreImpresion: butaca.tipoSeleccionado.NombreEntrada,
    NombreDisplay: butaca.tipoSeleccionado.NombreEntrada,
    Minimo: butaca.tipoSeleccionado.Minimo,
    Maximo: butaca.tipoSeleccionado.Maximo,
    Orden: '0',
    PrecioEnDivisaReferencia: butaca.tipoSeleccionado.PrecioInternet,
    PrecioBase: butaca.tipoSeleccionado.BaseImponible,
    IVA: butaca.tipoSeleccionado.IVAPrecio,
    Comision: butaca.tipoSeleccionado.Comision,
    IvaComision: butaca.tipoSeleccionado.IVAComision,
    ComisionBase: '0',
    DesglosarComision: '0',
    recintos: [],
    motivos: [],
    auditorio: true,
    fechaAuditorio: butaca.fecha,
    horaAuditorio: butaca.hora,
    idRecintoButaca: butaca.idRecintoButaca,
    extraData: {
      ...butaca
    },
    PromocionEntradas: '',
    TipoProducto: '1',
    CaracteristicasEntrada: []
  };
  return entrada;
};

export const entradaToButaca = (entrada: Entrada) => {
  return entrada.extraData;
};
