import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'estado'
})
export class EstadoPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        switch (value) {
            case 0:
                return 'Agotado';
            case 1:
                return 'Disponible';
            case 2:
                return 'Últimas sesiones';

        }
    }

}