import { Component, OnInit, Input, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { VentaService } from 'src/app/modules/venta';
import { PagerService } from 'src/app/shared/services/pager.service';
import { Subscription, Observable } from 'rxjs';
import { StepperNavigationService } from '../../services/stepper-navigation.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { ContactoService } from 'src/app/shared/services/contacto.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { PagoService } from '../../services/pago.service';
import { ErrorToast } from 'src/app/core/toast/error.toast';
import { InfoToast } from 'src/app/core/toast/info.toast';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ticketing-modal-datos-adicionales-entrada',
  templateUrl: './modal-datos-adicionales-entrada.component.html',
  styleUrls: ['./modal-datos-adicionales-entrada.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalDatosAdicionalesEntradaComponent implements OnInit{
  @Input() id: string;
  form: FormGroup;
  payLoad = '';
  invalid = true;
  nombresEntradas = [];
  rangoEntradas = [];
  entradasCarrito = [];

  private element: any;
  clientes = [];
  contactos = [];
  pager: any = {};
  pagedItems: any[];
  pagerContactos: any = {};
  pagedItemsContactos: any[];
  fieldsList: any;
  formulariosGenerados = [];
  insertarSub: Subscription;
  marcarSub: Subscription;
  temporadasCargadas = [];
  diasSemana= ['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo'];
  trimestreSeleccionado = [];
  //ObsDiasSemanaSeleccionados: Promise<string[]>;
  //DiasSemanaSeleccionados = ['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo'];
  abrir = false;

  idContactos = [];

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private venta: VentaService,
    private pagerService: PagerService,
    private pagerServiceContactos: PagerService,
    private contactoService: ContactoService,
    private stepperNavigation: StepperNavigationService,
    private cdRef:ChangeDetectorRef,    
    private config: AppConfiguration,
    private pagoService: PagoService,
    private router: Router,
    private toast: ToastrService
  ) {
    this.element = el.nativeElement;
    this.form = this.formBuilder.group({
      entradas: this.formBuilder.array([])
    });
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      return;
    }

    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });

    this.modalService.add(this);
  }
  ngAfterViewInit() {
    this.cdRef.detectChanges();
     }
  onChanges() {
    this.setPage(1);
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(
      this.form.controls.entradas.value.length,
      page,
      1
    );
    this.pagedItems = this.form.controls.entradas.value.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    this.pager.pages = this.pager.pages.filter(
      num => num <= this.pager.totalPages
    );

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
    this.cdRef.detectChanges();
  }

  setPageContacto(page: number) {
    this.pagerContactos = this.pagerServiceContactos.getPager(this.contactos.length, page);
    this.pagedItemsContactos = this.contactos.slice(
      this.pagerContactos.startIndex,
      this.pagerContactos.endIndex + 1
    );

    this.pagerContactos.pages = this.pagerContactos.pages.filter(
      num => num <= this.pagerContactos.totalPages
    );

    while (this.pagerContactos.pages.length < 6 && this.pagerContactos.pages[0] > 1) {
      this.pagerContactos.pages.unshift(this.pagerContactos.pages[0] - 1);
    }
    this.cdRef.detectChanges();
  }

  open(): void {
    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
    this.onChanges();
    // TODO: SE debe pensar correctamente
    /*this.venta.getTiposEntradaDatos().pipe(first()).subscribe(data => {
      
      if (!data || !data.TiposEntradaDatosJson || !data.TiposEntradaDatosJson.contains('type')) {
        this.close();
      }
    });*/
    this.venta.getTiposEntradaDatos().subscribe(data => {
      this.fieldsList = data;
      this.venta.entradasArrNominativas.subscribe(async item => {
        
        // 
        if (item !== undefined) {
          const entradas = this.form.controls.entradas as FormArray;
          await item.forEach(entrada => {
            this.nombresEntradas.push(entrada.NombreTipo);
            this.rangoEntradas.push(entrada.CaracteristicasEntrada && entrada.CaracteristicasEntrada.length > 0 &&
              entrada.CaracteristicasEntrada[0].Rango != '' ? entrada.CaracteristicasEntrada[0].Rango : '') ;
            this.entradasCarrito.push(entrada);
            // ;
            const group = {};
            if (Object.keys(this.fieldsList).length > 0) {
              const indice = 0;
              this.fieldsList.ListadoTiposEntradaDatos.forEach(async i => {
                if (i.TEDatosId === entrada.TEDatosId) {
                  const formularioJson = JSON.parse(i.TiposEntradaDatosJson);
                  await formularioJson.DatosNominativos.forEach(async f => {
                    const key = f.name;
                    if (entrada.CaracteristicasEntrada && entrada.CaracteristicasEntrada.length > 0) {
                      if (key === 'curso') {
                        group[key] = f.required ? [{value: entrada.CaracteristicasEntrada[indice].Nivel, disabled: true}] : '';
                      } else if (key === 'tipoCentro' || key === 'tipoTabla') {
                        // 
                        group[key] = f.required ? [{value: entrada.CaracteristicasEntrada[indice].Tipo, disabled: true}] : '';
                      } else {
                        group[key] = f.required ? ['', [Validators.required]] : '';
                      }
                    } else {
                      if (group[key] === 'email') {
                        group[key] = f.required ? ['', [Validators.email]] : '';
                      } else {
                        group[key] = f.required ? ['', [Validators.required]] : '';
                      }
                    }
                    if(key == 'TarjetaMonederoCodigo')
                    {
                      group[key] = f.required? ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]*$')]]:'';

                    }
                    if(key == 'tipoDocumento')
                    {
                      group[key] = entrada.desdeAsociado? entrada.codigoAsociado.TipoDocumento == 'NIF'? ['DNI']:entrada.codigoAsociado.TipoDocumento == 'NIE'?[entrada.codigoAsociado.TipoDocumento]:'':''; 
                    }
                    if(key == 'documento')
                    {
                      group[key] = entrada.desdeAsociado? [entrada.codigoAsociado.NumeroDocumento]:''; 
                    }
                    // if (key == 'edad')
                    // {
                    //   group[key] = f.required ? ['', [Validators.required, validadorEdad(this.validadorEdadXanno)]] : '';
                    // }
                    if (key === 'trimestre') {
                      const vTrimestres = [];
                      await this.venta.getTemporadas().subscribe(dataT => {
                        // 
                        if (Object.keys(dataT.ListadoTemporadas).length > 0) {
                          dataT.ListadoTemporadas.forEach(i => {
                            vTrimestres.push (i.Nombre);
                          });
                          this.temporadasCargadas = dataT.ListadoTemporadas;
                        }
                        f.options = vTrimestres;
                        f.change = 'cambioTrimestre';
                        
                        // 
                        this.onChanges();
                      });
                    }
                    if (key === 'dia') {
                      //f.options = this.ObsDiasSemanaSeleccionados;
                      //f.options = this.DiasSemanaSeleccionados;
                      let _self = this;
                      f.options = function() {return _self.construyeArrayDiasSemana()};
                    }

                  });
                  if (entrada.CaracteristicasEntrada && entrada.CaracteristicasEntrada.length > 0) {
                    group['tipoTabla'] = [{value: entrada.CaracteristicasEntrada[indice].Tipo, disabled: true}];
                    group['Rango'] = [entrada.CaracteristicasEntrada[indice].Rango];
                    group['curso'] = [{value: entrada.CaracteristicasEntrada[indice].Nivel, disabled: true}];
                    group['Profesor'] = [entrada.CaracteristicasEntrada[indice].Profesor];
                  }
                  this.formulariosGenerados.push(formularioJson.DatosNominativos);
                  //this.trimestreSeleccionado.push(null)                  //Rellenamos valores desde entradas Nominativas
                  this.rellenarFormularioXEntradasNominativas(group,entrada.EntradasVendidasPersonas);
                  // 
                  entradas.push(this.formBuilder.group(group));
                }
              });
            }
          });
          //Metemos posibles nominativas que haya en el carrito a los formularios
          // 
          this.form.updateValueAndValidity();
          this.onChanges();
        }
      });
    });
  }

  validadorEdadXanno() {
    let valido = true;
    (this.form.get('entradas') as FormArray).controls.forEach(entrada => {
      const edad = parseInt(moment().format('YYYY'), 10) - parseInt(entrada.value.edad, 10);
      var edadControl = (entrada as FormGroup).controls.edad;
      if (edad != 0 && !edad) {
        valido = true;        
        if (edadControl)
        {edadControl.setValidators([Validators.required, validadorEdad(true)]);
          edadControl.updateValueAndValidity();
          //this.renderer.removeClass(entrada, "edadTieneError");
        }
        
      } else {
        const rango = entrada.value.Rango;
        if (!rango || rango === '') {
          valido = true;
          //entrada.setErrors(null);
          edadControl.setValidators([Validators.required, validadorEdad(true)]);
          edadControl.updateValueAndValidity();
          //this.renderer.removeClass(entrada, "edadTieneError");
        } else {
          if (rango.split(' a ')[1]) {
            const edadMin = rango.split(' a ')[0];
            const edadMax = rango.split(' a ')[1];
            if (edad < parseInt(edadMin, 10) || edad > parseInt(edadMax, 10)) {
              // 
              valido = false;
              //entrada.setErrors({'incorrect': true});
              edadControl.setValidators([Validators.required, validadorEdad(false)]);
              edadControl.updateValueAndValidity();
              //this.renderer.addClass(entrada, "edadTieneError");
            }
            else
            {
              edadControl.setValidators([Validators.required, validadorEdad(true)]);
              edadControl.updateValueAndValidity();
            }
          } else {
            // const edadMin = rango.substr(1);
            if (edad < 13) {
              // 
              //valido = false;
              //entrada.setErrors({'incorrect': true});
              edadControl.setValidators([Validators.required, validadorEdad(false)]);
              edadControl.updateValueAndValidity();
              //this.renderer.addClass(entrada, "edadTieneError");
            }
            else
            {
              edadControl.setValidators([Validators.required, validadorEdad(true)]);
              edadControl.updateValueAndValidity();
            }
          }
        }
      }
      // 
    });
    return valido;
  }

  close(cancel?): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    if (this.insertarSub) {
      this.insertarSub.unsubscribe();
    }
    this.payLoad = '';
    this.invalid = true;
    this.nombresEntradas = [];
    this.rangoEntradas = [];
    this.entradasCarrito = [];
    this.clientes = [];
    this.contactos = [];
    this.abrir = false;
    this.pager = {};
    this.pagerContactos = {};
    this.pagedItems = [];
    this.pagedItemsContactos = [];
    this.fieldsList = {};
    this.formulariosGenerados = [];
    this.idContactos = [];
    this.form = this.formBuilder.group({
      entradas: this.formBuilder.array([])
    });
    if (cancel) {
      this.stepperNavigation.desbloquearNavegacionPorPago();
      this.stepperNavigation.continuarVenta.next(false);
      this.stepperNavigation.resetearPasos();
      this.router.navigate(['']);
    }
  }

  continuar() {
    //var valueForm = this.form.getRawValue();
    const formulariosRellenados = [...this.form.getRawValue()]; //this.form.value
    const resultArr = [];
    const idsContactos = this.idContactos;
    const subs = this.venta.entradasArrNominativas.subscribe(async data => {
      data.forEach(async (d, i) => {
        const entradaNominativa = Object.assign({}, d);
        const formularioRelleno = Object.assign(
          {},
          formulariosRellenados[0].entradas[i]
        );
        //añadir el idContacto al formularioRelleno
        if (idsContactos && idsContactos.length -1 >= i )
        {
          formularioRelleno['idContacto'] = idsContactos[i];
        }
        entradaNominativa.EntradasVendidasPersonas = this.cargaFormulario(formularioRelleno);
        resultArr.push(entradaNominativa);
      });
      await this.insertarNominativasCarrito(resultArr);
    });
    
    setTimeout(() => {
      if(subs){subs.unsubscribe();}
      this.close();
      this.form.reset();
    }, 500);
  }

  insertarNominativasCarrito(arr) {
    let index = 0;
    let entradasVendidasPersonas = [];
    // 
    this.insertarSub = this.venta.entradasArrCompleto.subscribe( data => {
      this.venta.getTiposEntradaDatos().subscribe( fields => {
        
        this.fieldsList = fields;
        // 
        // 
        data.forEach(async d => {
          if (Object.keys(this.fieldsList).length > 0) {
                if (
                  d.entrada.TipoProducto === '1' &&
                  d.entrada.TEDatosId !== null &&
                  d.entrada.TEDatosId !== '' &&
                  d.entrada.CuentaNominada !== '0'
                  ) {
                    entradasVendidasPersonas = [];
                    for (let i = 0; i < d.cantidad; i++) {
                      let TEDatosIdEncontrado = false;                     
                      this.fieldsList.ListadoTiposEntradaDatos.forEach( i => {
                        const formularioJson = i.TiposEntradaDatosJson  && i.TiposEntradaDatosJson != '' ? JSON.parse(i.TiposEntradaDatosJson): null;
                        if (i.TEDatosId ===  d.entrada.TEDatosId && formularioJson) {
                          TEDatosIdEncontrado = true;  //si coincide el tbTipoEntradaDatos con la entrada que evaluamos
                          entradasVendidasPersonas.push(arr[index].EntradasVendidasPersonas);
                          index = (formularioJson.TipoNominacion !=  undefined && formularioJson.TipoNominacion === "GRUPO") ? index : index++;
                        }                       
                      });
                      if(!TEDatosIdEncontrado)
                      {
                        entradasVendidasPersonas.push(arr[index].EntradasVendidasPersonas);
                        index++;
                      }                     
                    }
                    d.entrada.EntradasVendidasPersonas = entradasVendidasPersonas;
                  } else if (d.entrada.TipoProducto === '2') {
                    entradasVendidasPersonas = [];
                    for (let i = 0; i < d.cantidad; i++) {
                      d.entrada.PromocionEntradas.forEach(item => {
                        if (
                          item.TEDatosId !== null &&
                          item.TEDatosId !== '' &&
                          item.CuentaNominada !== '0'
                          ) {
                            
                            for (let j = 0; j < parseInt(item.NumeroEntradas, 10); j++) {
                              let TEDatosIdEncontrado = false;              
                              this.fieldsList.ListadoTiposEntradaDatos.forEach( i => {
                                const formularioJson = JSON.parse(i.TiposEntradaDatosJson);
                                if (i.TEDatosId ===  d.entrada.TEDatosId) {
                                  TEDatosIdEncontrado = true;  //si coincide el tbTipoEntradaDatos con la entrada que evaluamos
                                  entradasVendidasPersonas.push(
                                    arr[index].EntradasVendidasPersonas
                                  );
                                  index = (formularioJson.TipoNominacion !=  undefined && formularioJson.TipoNominacion === "GRUPO") ? index : index++;
                                }                       
                            });
                            if(!TEDatosIdEncontrado)
                            {
                              entradasVendidasPersonas.push(
                                arr[index].EntradasVendidasPersonas
                              );
                              index++;
                            }
                            item.EntradasVendidasPersonas = entradasVendidasPersonas;
                          }
                        }
                      });
                    }
                  }
            const carrito = this.venta.carritoValue;
            carrito.entradas = data;
            await this.venta.setCarrito(carrito);
            
            // 
            //Comprobamos si tenemos el Marcar en Externo
            let marcarExterno = this.config.getConfig('MARCAR_EXTERNO');
            if (!marcarExterno || marcarExterno == "") marcarExterno = "0";
            if(marcarExterno = "1")
            {
              //Generamos el Insertar
              const entradaConjunta =
              this.pagoService.opcionesPagoValue.controls['entradaConjunta'].value || false;
              //Mandamos a la API
              this.marcarSub = this.pagoService.marcarExterno(entradaConjunta)
            
            .subscribe((res: any) => {
              //Comprobamos la devolucion
              if (res.EntradasVendidas != null 
              ) {
                  // ;
                  //Metemos valores en entradaNominativa.EntradasVendidasPersonas
                  this.updateNominativasEnCarrito(res.EntradasVendidas);
                  // y el valor de de PreInsercion para reenviarlo al Insertar
                  this.venta.idMarcadoExterno = res.Identificador;
                  //
                
              } else {
                if (res.Mensajes[0].DescripcionMensaje.includes('Servicio Técnico'))
                {
                  this.toast.info('Ha sido imposible conectarse a la Escuela. Por favor, avise al Servicio Técnico','Info',  {
                    toastComponent: InfoToast,
                    timeOut: 7500
                  });
                }
                else if (res.Mensajes[0].DescripcionMensaje.includes('No hay aforo para el horario:'))
                {
                  let posicion = res.Mensajes[0].DescripcionMensaje.indexOf('No hay aforo para el horario:');
                  this.toast.info('No ha sido posible insertar en escuela. ' + res.Mensajes[0].DescripcionMensaje.substring(posicion),'Info',  {
                    toastComponent: InfoToast,
                    timeOut: 7500
                  });
                }
                else
                {
                  this.toast.error(res.Mensajes[0].DescripcionMensaje, 'Error', {
                    toastComponent: ErrorToast,
                    timeOut: 5000
                  });
                }
              }
            });


              //FIN
            }
          }
        });
      });
    });
  }

  updateNominativasEnCarrito(arr)
  {
    let index = 0;
    let indexEntrada = 0;
    let entradasVendidasPersonas = [];
    // 
    this.insertarSub = this.venta.entradasArrCompleto.subscribe(data => {
      data.forEach(d => {
        if (
          d.entrada.TipoProducto === '1' &&
          d.entrada.TEDatosId !== null &&
          d.entrada.TEDatosId !== '' &&
          d.entrada.CuentaNominada !== '0'
        ) {
          entradasVendidasPersonas = [];
          for (let i = 0; i < d.cantidad; i++) {
            //entradasVendidasPersonas.push(arr[index].EntradasVendidasPersonas);
            //si tenemos la caracteristica nidClienteExterno && nidLocalizadorExterno lo updateamos
            //si no lo metemos
            Object.defineProperty(d.entrada.EntradasVendidasPersonas[i], 'nidLocalizadorExterno', {
              value: arr[indexEntrada] && arr[indexEntrada].EntradasVendidasPersonas && arr[indexEntrada].EntradasVendidasPersonas.Respuesta4 ? arr[indexEntrada].EntradasVendidasPersonas.Respuesta4: null
              //Respuesta4
              //writable: false
            });
            Object.defineProperty(d.entrada.EntradasVendidasPersonas[i], 'nidClienteExterno', {
              value: arr[indexEntrada] && arr[indexEntrada].EntradasVendidasPersonas && arr[indexEntrada].EntradasVendidasPersonas.CodigoAsignado?arr[indexEntrada].EntradasVendidasPersonas.CodigoAsignado: null
              //CodigoAsignado
              //writable: false
            });
            index++;
            indexEntrada++;
          }
          //d.entrada.EntradasVendidasPersonas = entradasVendidasPersonas;
          // 
        } else if (d.entrada.TipoProducto === '2') {
          entradasVendidasPersonas = [];
          for (let i = 0; i < d.cantidad; i++) {
            d.entrada.PromocionEntradas.forEach(item => {
              if (
                item.TEDatosId !== null &&
                item.TEDatosId !== '' &&
                item.CuentaNominada !== '0'
              ) {
                for (let j = 0; j < parseInt(item.NumeroEntradas, 10); j++) {
                  entradasVendidasPersonas.push(
                    arr[index].EntradasVendidasPersonas
                  );
                  index++;
                  indexEntrada++;
                }
                //item.EntradasVendidasPersonas = entradasVendidasPersonas;
                // 
              }
              else
              {
                indexEntrada++;
              }
            });
          }
        }
        else
        {
          indexEntrada++;
        }
      });
      const carrito = this.venta.carritoValue;
      carrito.entradas = data;
      this.venta.setCarrito(carrito);
      
      // 
    });
  }
  // insertarNominativasCarrito(arr) {
  //   let index = 0;
  //   let entradasVendidasPersonas = [];
  //   

  //   this.insertarSub = this.venta.entradasArrCompleto.subscribe(data => {
  //     data.forEach(d => {
  //       if (
  //         d.entrada.TipoProducto === '1' &&
  //         d.entrada.TEDatosId !== null &&
  //         d.entrada.TEDatosId !== '' &&
  //         d.entrada.CuentaNominada !== '0'
  //       ) {
  //         entradasVendidasPersonas = [];
  //         for (let i = 0; i < d.cantidad; i++) {
  //           entradasVendidasPersonas.push(arr[index].EntradasVendidasPersonas);
  //           index++;
  //         }
  //         d.entrada.EntradasVendidasPersonas = entradasVendidasPersonas;
  //       } else if (d.entrada.TipoProducto === '2') {
  //         entradasVendidasPersonas = [];
  //         for (let i = 0; i < d.cantidad; i++) {
  //           d.entrada.PromocionEntradas.forEach(item => {
  //             if (
  //               item.TEDatosId !== null &&
  //               item.TEDatosId !== '' &&
  //               item.CuentaNominada !== '0'
  //             ) {
  //               for (let j = 0; j < parseInt(item.NumeroEntradas, 10); j++) {
  //                 entradasVendidasPersonas.push(
  //                   arr[index].EntradasVendidasPersonas
  //                 );
  //                 index++;
  //               }
  //               item.EntradasVendidasPersonas = entradasVendidasPersonas;
  //             }
  //           });
  //         }
  //       }
  //     });
  //     const carrito = this.venta.carritoValue;
  //     carrito.entradas = data;
  //     this.venta.setCarrito(carrito);
  //     
  //     
  //   });
  // }

  controlCambioSelects(elemento, cambio, entrada)
  {
    if (elemento.change)
    {
      if(elemento.change == "cambioTrimestre")
      {
        let cambionombre = cambio.value;
        let trimestreseleccionado = this.temporadasCargadas.find(x => x.Nombre === cambionombre);
        if (!trimestreseleccionado) trimestreseleccionado = this.temporadasCargadas[0];
        //
        //
        //let formArrayPadre:FormArray =this.form.controls.entradas as FormArray;
        //let objetoenlazado = formArrayPadre.controls[entrada];
        //
        //this.diasSemanaSeleccionables = this.construyeArrayDiasSemana(trimestreseleccionado);
        this.trimestreSeleccionado[entrada] = trimestreseleccionado;
        //this.ObsDiasSemanaSeleccionados = this.getArrayDiasSemana();
      } 
    }
    return cambio.value;
  }

  construyeArrayDiasSemana() :string[]
  {
    //
    let trimestre = this.trimestreSeleccionado[this.pager.currentPage - 1];
    let respuesta=[];
    if(trimestre)
    {
      if (trimestre.Lunes == 1) respuesta.push("Lunes");
      if (trimestre.Martes == 1) respuesta.push("Martes");
      if (trimestre.Miercoles == 1) respuesta.push("Miercoles");
      if (trimestre.Jueves == 1) respuesta.push("Jueves");
      if (trimestre.Viernes == 1) respuesta.push("Viernes");
      if (trimestre.Sabado == 1) respuesta.push("Sabado");
      if (trimestre.Domingo == 1) respuesta.push("Domingo");
    }
    return respuesta;
  }

  // getArrayDiasSemana()
  // {
  //   return new Promise<string[]>(resolve => {
  //       resolve(this.construyeArrayDiasSemana());
  //   });
  // }

  getOptions(options)
  {
    //;
    const tipo = typeof(options);
    if (tipo == "function") return options();
    return options;
  }
  cargaFormulario(formulario)
  {
    if (formulario && formulario.trimestre && formulario.trimestre.trim() != "")
    {
      formulario.trimestre = this.temporadasCargadas.find(x => x.Nombre === formulario.trimestre).Codigo;
    }
    return formulario;
  }
  abrirBusqueda(campo, formulario)
  {
    this.abrir = true;
    //
    //
    const formulariosRellenados = [...this.form.getRawValue()]; //this.form.value
    //
    //
      this.contactoService.findContacto(formulariosRellenados[0].entradas[this.pager.currentPage -1][campo.name],campo.busqueda).subscribe((res: any) => {
      if (res) {
        this.contactos = res; //.DatosResult.ListadoContacto
        this.setPageContacto(1);//Duplicar el paginador para los contactos
      } else {
        this.contactos = [];
      }
    });
  }
  seleccionarContacto(contacto)
  {
     
    //
    // 
    let entradas :FormArray = <FormArray>this.form.controls["entradas"];
    let formulario :FormGroup = <FormGroup>entradas.controls[this.pager.currentPage -1];
    //
    //
    //Asignamos los valores, si se añaden mas campos a la tabla de configuracion (tbTiposEntradaDatos)
    //habra que meter su asignacion aqui
    formulario.controls["nombre"].patchValue(contacto["Nombre"]);
    formulario.controls["apellidos"].patchValue(contacto["Apellido1"] + (contacto["Apellido2"] && contacto["Apellido2"] != ""? " ":"") + contacto["Apellido2"]);
    //formulario.controls["tipoDocumento"].patchValue("");
    formulario.controls["documento"].patchValue(contacto["Cif"]);
    formulario.controls["edad"].patchValue(moment(contacto["FechaNacimiento"]).format('YYYY'));
    formulario.controls["email"].patchValue(contacto["Email"]);
    formulario.controls["sexo"].patchValue(contacto["Sexo"]);
    formulario.controls["telefono"].patchValue(contacto["Telefono"]);
    formulario.controls["codigoPostal"].patchValue(contacto["CP"]);

    this.idContactos[this.pager.currentPage -1] = contacto["pkId"];

    this.abrir = false;
    //this.form.updateValueAndValidity();
    //this.cdRef.detectChanges();
  }

  rellenarFormularioXEntradasNominativas(formulario, nominacion)
  {
    if (formulario && nominacion && Object.keys(formulario).length > 0 && nominacion.length >0 )
    {
      //
      Object.entries(formulario).forEach(item => 
        {
          //si se añaden campos hay que incluirlos aqui
          if (item[0] == 'nombre') item[1][0] = nominacion[0].nombre;
          if (item[0] == 'apellidos') item[1][0] = nominacion[0].apellidos;
          if (item[0] == 'tipoDocumento') item[1][0] = nominacion[0].tipoDocumento;
          if (item[0] == 'documento') item[1][0] = nominacion[0].documento;
          if (item[0] == 'edad') item[1][0] = nominacion[0].edad;
          if (item[0] == 'sexo') item[1][0] = nominacion[0].sexo;
          if (item[0] == 'email') item[1][0] = nominacion[0].email;
          if (item[0] == 'codigoPostal') item[1][0] = nominacion[0].codigoPostal;
          if (item[0] == 'telefono') item[1][0] = nominacion[0].telefono;
          if (item[0] == 'curso') item[1][0] = nominacion[0].curso;
          if (item[0] == 'tipoTabla') item[1][0] = nominacion[0].tipoTabla;
          if (item[0] == 'Rango') item[1][0] = nominacion[0].Rango;
          if (item[0] == 'Profesor') item[1][0] = nominacion[0].Profesor;
          if (item[0] == 'dia') item[1][0] = nominacion[0].dia;
          //
          if (item[0] == 'trimestre') {
            let trimestrecargado = this.temporadasCargadas.find( x => x.Codigo == nominacion[0].trimestre);
            item[1][0] = trimestrecargado? trimestrecargado.Nombre: null
          };
          //
        });
        //
    }
  }

  valorAnterior() :boolean
  {
    let respuesta = true;
    // 
    if (this.pager.currentPage <= 1)
    {
      respuesta = true;
    }
    else
    {
      respuesta = false;
      //Comprobamos que el formulario anterior sea valido 
      let entradas :FormArray = <FormArray>this.form.controls["entradas"];
      let formulario :FormGroup = <FormGroup>entradas.controls[this.pager.currentPage -2];
      //;
      //
      if (!formulario || !formulario.valid)
      {
        respuesta = true;
      }
    }
    return respuesta;
  }

  copiarAnterior(pagina) :void
  {
    let entradas :FormArray = <FormArray>this.form.controls["entradas"];
    let formularioAnterior :FormGroup = <FormGroup>entradas.controls[pagina -1];
    let formularioActual :FormGroup = <FormGroup>entradas.controls[pagina];
    //Repasamos los controles 
    let camposAnteriores :FormGroup = <FormGroup> <unknown>formularioAnterior.controls;
    let camposActuales:FormGroup = <FormGroup> <unknown>formularioActual.controls;

    Object.keys(camposAnteriores).forEach((control: string) => {
      const typedControlAnterior: FormControl = camposAnteriores[control];
      const typedControlActual: FormControl = camposActuales[control];
      // 
      // 
      if (typedControlActual.disabled == false)
      {
        typedControlActual.patchValue(typedControlAnterior.value);
      }
      
      });

  }
}


function validadorEdad(comprobador: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value !== undefined &&
      (isNaN(control.value)|| !comprobador)
    ) {
      return { validadorEdad: true };
    }
    return null;
  };
}

