import { Component, OnInit } from '@angular/core';
import { StepperNavigationService } from 'src/app/modules/venta';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ticketing-stepper-auditorio',
  templateUrl: './stepper-auditorio.component.html',
  styleUrls: ['./stepper-auditorio.component.scss']
})
export class StepperAuditorioComponent implements OnInit {
  pasos = [];

  constructor(
    private stepperNavigation: StepperNavigationService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.stepperNavigation
      .getShowSteps()
      .subscribe(pasos => (this.pasos = pasos));
  }
  debeSerVisible(step: any) {
    /*   if (step.url !== 'actividades') {
      step.visible = false;
    } */
    return step.visible;
  }
  navegar(url) {
    if (url === 'actividades') {
      this.stepperNavigation.desbloquearNavegacionPorPago();
      this.stepperNavigation.continuarVenta.next(false);
      this.stepperNavigation.resetearPasos();
      this.router.navigate(['/auditorio/actividades']);
    } else if (url === 'seleccion-sesiones') {
      this.router.navigate(['/auditorio/seleccion-sesiones']);
    } else if (url === 'seleccion-butaca') {
      this.router.navigate(['/auditorio/seleccion-butaca']);
    }
  }
}
