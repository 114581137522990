import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StepperNavigationService } from './services/stepper-navigation.service';
import { VentaService } from './services/venta.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ConfigurationService } from 'src/app/core';
import { Subscription } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';

@Component({
  selector: 'ticketing-venta',
  templateUrl: './venta.component.html',
  styleUrls: ['./venta.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'layout'
  }
  
})
export class VentaComponent implements OnInit, OnDestroy, AfterContentChecked {
  pasos = [];
  ventanaActualDePago: boolean;
  ventanaActualDeEntradas = true;
  entradasConMotivos = [];
  modoPresentacion: string;
  continuar: boolean;
  routerSub: Subscription;
  configSub: Subscription;
  stepperSub: Subscription;

  constructor(
    private router: Router,
    private stepperNavigation: StepperNavigationService,
    private venta: VentaService,
    private modalService: ModalService,
    private config: ConfigurationService,
    private configuracion: AppConfiguration,
    private changeDetector: ChangeDetectorRef
  ) {
    
    this.routerSub = router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.ventanaActualDePago =
          val.url.split('/')[2] === 'pago' ? true : false;
        this.ventanaActualDeEntradas =
          val.url.split('/')[2] === 'entradas' ? true : false;
      }
    });
  }

  ngOnInit() {
    this.configSub = this.config.appConfig.subscribe(data => {
      this.modoPresentacion =data.DatosResult.AppConfigJsonConfig && data.DatosResult.AppConfigJsonConfig.ModoPresentacion?
        data.DatosResult.AppConfigJsonConfig.ModoPresentacion:'';
    });
    this.stepperSub = this.stepperNavigation.continuarVenta.subscribe(data => {
      this.continuar = data;
    });
    this.venta.setMasEntradas('normal');
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
    if (this.stepperSub) {
      this.stepperSub.unsubscribe();
    }
  }

  hayEntradasEnElCarrito(): boolean {
    return this.venta.carritoValue.entradas.length !== 0;
  }

  esReservaAgrupada() : boolean{
    return this.venta.carritoOrigenValue.reservaAgrupada	=== '1';

  }

  avanzar() {
    
    this.stepperNavigation.continuarVenta.next(true);
    this.stepperNavigation.next();
  }

  openMasEntradasModal() {
    
    this.modalService.open('selector-mas-entradas');
  }

  isContinuar()
  {
    return this.continuar;
  }
}
