import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ButacasService } from '../../../services/butacas.service';
import { ConfigurationService } from 'src/app/core';
import { EntradasService, VentaService } from 'src/app/modules/venta';
import { EspectaculosService } from '../../../services/espectaculos.service';
import { InfoMapasService } from '../../../services/info-mapas.service';
import { butacaToEntrada } from '../../seleccion-butacas/butacaToEntrada.mapper';
import { PagerService } from 'src/app/shared/services/pager.service';
import { NumpadService } from 'src/app/shared/services/numpad.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { entradaToButaca } from 'src/app/modules/auditorio/components/seleccion-butacas/butacaToEntrada.mapper';
import * as moment from 'moment';

@Component({
  selector: 'ticketing-selector-tarifas',
  templateUrl: './selector-tarifas.component.html',
  styleUrls: ['./selector-tarifas.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'layout'
  }
})
export class SelectorTarifasComponent implements OnInit {
  @Input() id: string;

  private element: any;

  butacaMarcada;
  espectaculoSeleccionado;
  diaHoraSeleccionado;
  divisaSimboloDecimal: string;
  divisaSimbolo: string;
  esNumerada: boolean;
  permiteAceptar = false;
  numeroTarifasSeleccionadas = 0;
  pager: any = {};
  pagedItems: any[];
  permiteMarcar = true;
  carrito: any;
  tarifaBloqueada = [];

  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private butacaService: ButacasService,
    private configuration: ConfigurationService,
    private espectaculoService: EspectaculosService,
    private infoMapasService: InfoMapasService,
    private ventaService: VentaService,
    private pagerService: PagerService,
    private numpadService: NumpadService,
    private formControlService: FormControlService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.divisaSimboloDecimal = this.configuration.divisasValue[0].SimboloDecimal;
    this.divisaSimbolo = this.configuration.divisasValue[0].simbolo;
    const modal = this;
    if (!this.id) {
      return;
    }

    this.espectaculoSeleccionado = this.espectaculoService.espectaculoSeleccionadoValue;
    this.diaHoraSeleccionado = moment(this.espectaculoSeleccionado.dtFecha).format('DD/MM/YYYY') + ' ' +
    moment(this.espectaculoSeleccionado.dtHoraInicio , 'HH:mm:ss'
    ).format('HH:mm');

    // 
    this.butacaService.esNumerada.subscribe(data => {
      this.esNumerada = data;
    });

    if (this.modalService.exists(this.id)) {
      this.modalService.remove(this.id);
    }

    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);

    this.ventaService.carrito.subscribe(carrito => {
      this.carrito = carrito;
    });
  }

  open() {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
    this.butacaMarcada = this.butacaService.butacaMarcadaValue;
    this.tarifaBloqueada = this.butacaService.tarifaBloqueada;
    // 
    this.butacaMarcada.info.tiposDeEntrada.forEach(tipo => {
      if (!('Cantidad' in tipo)) {
        const entradasDeVenta = this.ventaService.carritoValue.entradas.filter(
          e => e.entrada.idTarifa == tipo.IdTarifa
        );
        tipo.Cantidad = entradasDeVenta.length;
      }
    });
    this.setPage(1);
  }

  close() {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    this.butacaService.esNumerada.next(true);
  }

  marcarTarifa(index) {
    
    this.butacaMarcada.tipoSeleccionado = this.butacaMarcada.info.tiposDeEntrada[
      index
    ];
    // 
    // 
    const tarifablock = this.butacaService.tarifaBloqueada.find(x => x == this.butacaMarcada.tipoSeleccionado.IdTarifa);
    if (tarifablock) {
      return;
    }
    //JS 20191228
    //this.butacaMarcada.fecha = this.espectaculoService.espectaculoSeleccionadoValue.dtFecha;
    //this.butacaMarcada.hora = this.espectaculoService.espectaculoSeleccionadoValue.dtHoraInicio;
    this.butacaMarcada.fecha = this.espectaculoService.sesionSeleccionada.fechaSesion;
    this.butacaMarcada.hora = this.espectaculoService.sesionSeleccionada.horaInicioSesion;
    //FJS
    // TODO: Por favor simplificame
    this.butacaService
      .httpPostMarcarDesmarcarButaca(this.butacaMarcada, true)
      .subscribe((res: any) => {
        if (
          res.DatosResult.IdRB.includes(
            Number.parseInt(
              this.butacaMarcada.info.infoGeneral.recintoButacaId,
              10
            )
          )
        ) {
          const espectaculo = {
            ...this.espectaculoService.espectaculoSeleccionadoValue,
            fecha: this.espectaculoService.espectaculoSeleccionadoValue.dtFecha,
            hora: this.espectaculoService.espectaculoSeleccionadoValue
              .dtHoraInicio
          };
          this.espectaculoService.pushEspectaculo(espectaculo);
          const datosExtra = {
            idEspectaculoAsociado: this.espectaculoService
              .espectaculoSeleccionadoValue.iEspectaculoId,
            espectaculo: {
              ...this.espectaculoService.espectaculoSeleccionadoValue
            }
          };
          // 
          // 
          //JS 20191228 30
          datosExtra.espectaculo.dtFecha = this.espectaculoService.sesionSeleccionada.fechaSesion;
          datosExtra.espectaculo.dtHoraInicio = this.espectaculoService.sesionSeleccionada.horaInicioSesion;
          datosExtra.espectaculo.idRecinto = this.butacaMarcada.dataIdRecinto;
          datosExtra.espectaculo.chNombreRecinto = this.butacaMarcada.info.infoGeneral.nombreRecinto;
          datosExtra.espectaculo.idTipoSesion = this.butacaMarcada.info.infoGeneral.idTipoSesion;
          //FJS
          const butaca = { ...this.butacaMarcada, ...datosExtra };
          const entrada = butacaToEntrada(butaca);
          ;
          this.butacaService.pushButaca(butaca);
          this.ventaService.sendEntrada(entrada);

          const infoSesion = {
            EsButaca: true,
            recinto: {
              ControlaAforo: '0'
            },
            // sesion: {
            //   Fecha: this.espectaculoService.espectaculoSeleccionadoValue
            //     .dtFecha,
            //   HoraInicio: moment(
            //     this.espectaculoService.espectaculoSeleccionadoValue
            //       .dtHoraInicio,
            //     'HH:mm:ss'
            //   ).format('HH:mm'),
            //   RecintoId: this.espectaculoService.espectaculoSeleccionadoValue
            //     .idRecinto
            // }
            //JS 20191228 30
            sesion: {
              Fecha: this.espectaculoService.sesionSeleccionada.fechaSesion,
              HoraInicio: moment(
                this.espectaculoService.sesionSeleccionada.horaInicioSesion,
                'HH:mm:ss'
              ).format('HH:mm'),
              RecintoId: this.butacaMarcada.dataIdRecinto
              //FJS
            }
          };
          this.ventaService.carritoValue.horarios.push(infoSesion);
          this.butacaMarcada.DOMElement.style('fill', 'blue');
          this.modalService.close('selectorTarifas');
          this.butacaService.esNumerada.next(true);
        } else {
          // 
          this.butacaService.getEstadosButacas(
            this.infoMapasService.recinto,
            this.espectaculoService.espectaculoSeleccionadoValue
              .chNombreFicheroSVG
          );
        }
      });
  }

  claseTarifa(index) {
    index = index + ((this.pager.currentPage - 1) * this.pager.pageSize);
    if (this.butacaMarcada && this.butacaMarcada.info.tiposDeEntrada[
      index
    ]) {
      const tarifa = this.butacaService.tarifaBloqueada.find(x => x == this.butacaMarcada.info.tiposDeEntrada[
        index
      ].IdTarifa);
      if (tarifa) {
        return 'Rtable-cell motivo-cell disabled';
      } else {
        return 'Rtable-cell motivo-cell';
      }
    } else {
      return 'Rtable-cell motivo-cell';
    }
  }

  claseTarifaNoNumerada(index) {
    index = index + ((this.pager.currentPage - 1) * this.pager.pageSize);
    if (this.butacaMarcada && this.butacaMarcada.info.tiposDeEntrada[
      index
    ]) {
      const tarifa = this.butacaService.tarifaBloqueada.find(x => x == this.butacaMarcada.info.tiposDeEntrada[
        index
      ].IdTarifa);
      if (tarifa) {
        return 'Rtable-row disabled';
      } else {
        return 'Rtable-row';
      }
    } else {
      return 'Rtable-row';
    }
  }

  claseTarifaNoNumeradaTexto(index) {
    index = index + ((this.pager.currentPage - 1) * this.pager.pageSize);
    if (this.butacaMarcada && this.butacaMarcada.info.tiposDeEntrada[
      index
    ]) {
      const tarifa = this.butacaService.tarifaBloqueada.find(x => x == this.butacaMarcada.info.tiposDeEntrada[
        index
      ].IdTarifa);
      if (tarifa) {
        return 'Rtable-cell--content item-content disabled';
      } else {
        return 'Rtable-cell--content item-content';
      }
    } else {
      return 'Rtable-cell--content item-content';
    }
  }

  claseTarifaNoNumeradaPrecio(index) {
    index = index + ((this.pager.currentPage - 1) * this.pager.pageSize);
    if (this.butacaMarcada && this.butacaMarcada.info.tiposDeEntrada[
      index
    ]) {
      const tarifa = this.butacaService.tarifaBloqueada.find(x => x == this.butacaMarcada.info.tiposDeEntrada[
        index
      ].IdTarifa);
      if (tarifa) {
        return 'Rtable-cell--content precio-content disabled';
      } else {
        return 'Rtable-cell--content precio-content';
      }
    } else {
      return 'Rtable-cell--content precio-content';
    }
  }

  claseTarifaNoNumeradaCantidad(index) {
    index = index + ((this.pager.currentPage - 1) * this.pager.pageSize);
    if (this.butacaMarcada && this.butacaMarcada.info.tiposDeEntrada[
      index
    ]) {
      const tarifa = this.butacaService.tarifaBloqueada.find(x => x == this.butacaMarcada.info.tiposDeEntrada[
        index
      ].IdTarifa);
      if (tarifa) {
        return 'qty text disabled';
      } else {
        return 'qty text';
      }
    } else {
      return 'qty text';
    }
  }

  claseTarifaNoNumeradaPlus(index) {
    index = index + ((this.pager.currentPage - 1) * this.pager.pageSize);
    if (this.butacaMarcada && this.butacaMarcada.info.tiposDeEntrada[
      index
    ]) {
      const tarifa = this.butacaService.tarifaBloqueada.find(x => x == this.butacaMarcada.info.tiposDeEntrada[
        index
      ].IdTarifa);
      if (tarifa) {
        return 'addValueButton plus disabled';
      } else {
        return 'addValueButton plus';
      }
    } else {
      return 'addValueButton plus';
    }
  }

  claseTarifaNoNumeradaMinus(index) {
    index = index + ((this.pager.currentPage - 1) * this.pager.pageSize);
    if (this.butacaMarcada && this.butacaMarcada.info.tiposDeEntrada[
      index
    ]) {
      const tarifa = this.butacaService.tarifaBloqueada.find(x => x == this.butacaMarcada.info.tiposDeEntrada[
        index
      ].IdTarifa);
      if (tarifa) {
        return 'addValueButton minus disabled';
      } else {
        return 'addValueButton minus';
      }
    } else {
      return 'addValueButton minus';
    }
  }

  conversor(precio: string) {
    return Number.parseFloat(precio.replace(/,/, '.'));
  }

  restaCantidadNoNumerada(tarifa, index, recinto) {
    // 
    index = index + ((this.pager.currentPage - 1) * this.pager.pageSize);
    const tarifaBloq = this.butacaService.tarifaBloqueada.find(x => x == tarifa.IdTarifa);
    if (tarifaBloq) {
      return;
    }
    this.permiteMarcar = false;
    if (this.butacaMarcada.info.tiposDeEntrada[index].Cantidad === 0) {
      this.permiteMarcar = true;
      return;
    } else {
      this.butacaService
        .httpPostMarcarDesmarcarButacaNoNumeradas('0', tarifa, recinto)
        .subscribe((res: any) => {
          // 
          this.carrito.entradas.forEach(item => {
            // 
            //   item.entrada.idRecintoButaca,
            //   res.DatosResult.Butacas[0].idRecintoButaca
            // );
            if (
              item.entrada.idRecintoButaca ==
              res.DatosResult.Butacas[0].idRecintoButaca
            ) {
              // 
              // const b = entradaToButaca(item);
              // this.butacaService.removeButaca(item.entrada.extraData);
              this.ventaService.removeEntrada(item.entrada);
            }
          });
          this.numeroTarifasSeleccionadas--;
          this.butacaMarcada.info.tiposDeEntrada[index].Cantidad =
            this.butacaMarcada.info.tiposDeEntrada[index].Cantidad - 1;
            this.butacaService.butacaMarcadaValue.info.tiposDeEntrada[index].Cantidad =
            this.butacaMarcada.info.tiposDeEntrada[index].Cantidad;
          this.permiteMarcar = true;
        });
    }
    
  }

  sumaCantidadNoNumerada(tarifa, index, recinto) {
    // 
    index = index + ((this.pager.currentPage - 1) * this.pager.pageSize);
    // 
    const tarifaBloq = this.butacaService.tarifaBloqueada.find(x => x == tarifa.IdTarifa);
    if (tarifaBloq) {
      return;
    }
    this.permiteMarcar = false;
    // 
    // TODO: Por favor simplificame
    this.butacaService
      .httpPostMarcarDesmarcarButacaNoNumeradas('1', tarifa, recinto)
      .subscribe((res: any) => {
        if (res.Mensajes.length > 0) {
          alert('No hay butacas disponibles');
          this.permiteMarcar = true;
          return;
        }
        const espectaculo = {
          ...this.espectaculoService.espectaculoSeleccionadoValue,
          // fecha: this.espectaculoService.espectaculoSeleccionadoValue.dtFecha,//JS 20191230
          // hora: this.espectaculoService.espectaculoSeleccionadoValue.dtHoraInicio //JS 20191230
          recinto: this.butacaMarcada.dataIdRecinto, //JS 20191231
          fecha: this.espectaculoService.sesionSeleccionada.fechaSesion,
          hora: this.espectaculoService.sesionSeleccionada.horaInicioSesion
        };
        this.espectaculoService.pushEspectaculo(espectaculo);
        // 
        // ;
        const butacaMarcada = {
          tag: '',
          id: '',
          cx: '',
          cy: '',
          r: '',
          'datatipobutaca': '',
          dataNumFila: res.DatosResult.Butacas[0].Fila,
          dataNumButaca: res.DatosResult.Butacas[0].Numero,
          dataIdRecinto: res.DatosResult.Butacas[0].RecintoId,
          idRecintoButaca: res.DatosResult.Butacas[0].idRecintoButaca,
          tarifas: [],
          info: {
            infoGeneral: {
              nombreRecinto: this.butacaMarcada.info.infoGeneral.nombreRecinto
            },
            tiposDeEntrada: []
          },
          DOMElement: '',
          tipoSeleccionado: tarifa,
          fecha: this.espectaculoService.sesionSeleccionada.fechaSesion,
          hora: this.espectaculoService.sesionSeleccionada.horaInicioSesion
          // fecha: this.espectaculoService.espectaculoSeleccionadoValue.dtFecha,
          // hora: this.espectaculoService.espectaculoSeleccionadoValue
          //   .dtHoraInicio
        };
        const datosExtra = {
          idEspectaculoAsociado: this.espectaculoService.espectaculoSeleccionadoValue.iEspectaculoId.toString(),
          espectaculo: {
            ...this.espectaculoService.espectaculoSeleccionadoValue
          }
        };
        // 
        //JS 20191228
        datosExtra.espectaculo.dtFecha = this.espectaculoService.sesionSeleccionada.fechaSesion;
        datosExtra.espectaculo.dtHoraInicio = this.espectaculoService.sesionSeleccionada.horaInicioSesion;
        datosExtra.espectaculo.idRecinto = this.butacaMarcada.dataIdRecinto;
        datosExtra.espectaculo.chNombreRecinto = this.butacaMarcada.info.infoGeneral.nombreRecinto;
        datosExtra.espectaculo.idTipoSesion = this.butacaMarcada.info.infoGeneral.idTipoSesion;
        //FJS
        const butaca = { ...butacaMarcada, ...datosExtra };
        const entrada = butacaToEntrada(butaca);
        this.ventaService.sendEntrada(entrada);
        const infoSesion = {
          EsButaca: true,
          //recinto: this.espectaculoService.espectaculoSeleccionadoValue
          //  .idRecinto,
          // sesion: {
          //   Fecha: this.espectaculoService.espectaculoSeleccionadoValue.dtFecha,
          //   HoraInicio: moment(
          //     this.espectaculoService.espectaculoSeleccionadoValue.dtHoraInicio,
          //     'HH:mm:ss'
          //   ).format('HH:mm'),
          //   RecintoId: this.espectaculoService.espectaculoSeleccionadoValue
          //     .idRecinto
          // }
          //JS 20191228 30
          recinto: this.butacaMarcada.dataIdRecinto,
          sesion: {
            Fecha: this.espectaculoService.sesionSeleccionada.fechaSesion,
            HoraInicio: moment(
              this.espectaculoService.sesionSeleccionada.horaInicioSesion,
              'HH:mm:ss'
            ).format('HH:mm'),
            RecintoId: this.butacaMarcada.dataIdRecinto
            //FJS
          }
        };
        this.ventaService.carritoValue.horarios.push(infoSesion);
        this.numeroTarifasSeleccionadas++;
        this.butacaMarcada.info.tiposDeEntrada[index].Cantidad =
          this.butacaMarcada.info.tiposDeEntrada[index].Cantidad + 1;
        this.butacaService.butacaMarcadaValue.info.tiposDeEntrada[index].Cantidad =
          this.butacaMarcada.info.tiposDeEntrada[index].Cantidad;
          // 
        this.permiteMarcar = true;
      });
  }

  confirmarCierre() {
    if (!this.permiteAceptar) {
      return;
    }
    this.modalService.close('selectorTarifas');
  }

  tarifaSelected() {
    if (this.numeroTarifasSeleccionadas > 0) {
      this.permiteAceptar = true;
    } else {
      this.permiteAceptar = false;
    }
    return this.permiteAceptar;
  }

  inputDeTeclado(evento, tarifa) {}

  setPage(page: number): void {
    // 
    this.pager = this.pagerService.getPager(
      this.butacaMarcada.info.tiposDeEntrada.length,
      page,
      9
    );
    this.pagedItems = this.butacaMarcada.info.tiposDeEntrada.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    this.pager.pages = this.pager.pages.filter(
      num => num <= this.pager.totalPages
    );

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }

  focusValue(event: any, entrada: any): void {
    if (entrada.noEditable) {
      return;
    }
    event.srcElement.value = '';
    this.numpadService.selected = event.srcElement;
    this.numpadService.entrada = entrada;
    this.formControlService.setCurrentFormGroup(
      null,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
    if (event.srcElement.getAttribute('category') === 'CANTIDAD') {
      this.numpadService.commaDisabled = true;
    } else {
      this.numpadService.commaDisabled = false;
    }
  }

  unfocusValue(event: any): void {
    
    if (event.srcElement.value === '') {
      this.numpadService.setNumpadOrder('reset');
    }
  }
}
