import { CardPayment } from './cardPayment.interface';
import { OsConnection } from '../connection/osConnection.interface';
import { OsConnectionFactory } from '../connection/osConnection.factory';
import { ConnectionType } from '../connection/connectionType.enum';

export class DatafonoCardPayment implements CardPayment {
  osConnection: OsConnection;
  constructor() {
    this.osConnection = OsConnectionFactory.getConnection(ConnectionType.VIRTUAL);
  }

  pay(
    idTpv: string,
    NumDecimalesDivisa: string,
    ContadorAlbaranesVenta: string,
    ContadorAlbaranesDevolucion: string,
    Identificador_Alb: string,
    OperacionVenta: string,
    TipoComunicacionVenta: string,
    ImporteVenta: string,
    ImporteVenta_dev: string,
    TpvIdOrigen: string,
    NumTarjetaOrigen: string,
    CaducidadTarjetaOrigen: string,
    NumOperacionOrigen: string,
    IdRtsOrigen: string,
    NumTerminal: string,
    VersionWeb: string,
    NumCom: string,
    ClaveCom: string,
    COMPinPad: string,
    TimeOutTjta: string = "0"
  ) {
    return this.ventaTarjeta(
      idTpv,
      NumDecimalesDivisa,
      ContadorAlbaranesVenta,
      ContadorAlbaranesDevolucion,
      Identificador_Alb,
      OperacionVenta,
      TipoComunicacionVenta,
      ImporteVenta,
      ImporteVenta_dev,
      TpvIdOrigen,
      NumTarjetaOrigen,
      CaducidadTarjetaOrigen,
      NumOperacionOrigen,
      IdRtsOrigen,
      NumTerminal,
      VersionWeb,
      NumCom,
      ClaveCom,
      COMPinPad,
      TimeOutTjta
    );
  }

  ventaTarjeta(
    idTpv: string,
    NumDecimalesDivisa: string,
    ContadorAlbaranesVenta: string,
    ContadorAlbaranesDevolucion: string,
    Identificador_Alb: string,
    OperacionVenta: string,
    TipoComunicacionVenta: string,
    ImporteVenta: string,
    ImporteVenta_dev: string,
    TpvIdOrigen: string,
    NumTarjetaOrigen: string,
    CaducidadTarjetaOrigen: string,
    NumOperacionOrigen: string,
    IdRtsOrigen: string,
    NumTerminal: string,
    VersionWeb: string,
    NumCom: string,
    ClaveCom: string,
    COMPinPad: string,
    TimeOutTjta: string = "0"
  ){
      const res = 'OK:<Operaciones version="6.0"><resultadoOperacion>' + 
      // '<importe></importe>' + 
      // '<moneda>978</moneda>' + 
      // '<tarjetaClienteRecibo></tarjetaClienteRecibo>' + 
      // '<tarjetaComercioRecibo></tarjetaComercioRecibo>' + 
      // '<marcaTarjeta></marcaTarjeta>' + 
      // '<caducidad></caducidad>' + 
      // '<comercio></comercio>' + 
      // '<terminal></terminal>' + 
      // '<pedido>61897</pedido>' + 
      // '<tipoTasaAplicada></tipoTasaAplicada>' +
      // '<identificadorRTS></identificadorRTS>' + 
      // '<factura></factura>' + 
      // '<fechaOperacion>2019-10-03 17,22,18.0</fechaOperacion>' +
      // '<estado>F</estado>' + 
      // '<resultado>Autorizada</resultado>' + 
      // '<codigoRespuesta>079642</codigoRespuesta>' +
      // '<firma></firma>' + 
      // '<operacionemv>true</operacionemv>' +
      // '<resverificacion></resverificacion>' + 
      // '<conttrans></conttrans>' + 
      // '<sectarjeta>01</sectarjeta>' + 
      // '<idapp></idapp>' +
      // '<DDFName></DDFName>' + 
      // '<etiquetaApp></etiquetaApp>' +
      // '<operContactLess>TRUE</operContactLess>' +
      // '<ReciboSoloCliente>TRUE</ReciboSoloCliente>' +
      '</resultadoOperacion></Operaciones>';
      return res;

  }
}
