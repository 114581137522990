import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ticketing-devolucion',
  templateUrl: './devolucion.component.html',
  styleUrls: ['./devolucion.component.scss']
})
export class DevolucionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
