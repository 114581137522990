import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoAccion'
})
export class TipoAccionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case '00':
        return 'ANULADA';
      case '01':
        return 'PAGADA';
      case '02':
        return 'RESERVA';
      case '03':
        return 'MODIFICADO';
      case '04':
        return 'CANJEADA';
      case '05':
        return 'DEVUELTA';
      case '06':
        return 'DEVUELTA EN TAQUILLA';
      case '07':
        return 'ACCESO LOCALIZADOR';
      case '08':
        return 'ACCESO VENTA TAQUILLA';
      case '09':
        return 'VENDIDA';
      case '10':
        return 'ANULADA';
      default:
        return '';
    }
  }

}
