import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ticketing-espectaculos',
  templateUrl: './espectaculos.component.html',
  styleUrls: ['./espectaculos.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'layout layout--auditorio'
  }
})
export class EspectaculosComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
