import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Albaran } from '../../models/albaran.model';
import { AlbaranService } from '../../services/albaran.service';
import { mergeMap, take } from 'rxjs/operators';
import { DevolucionService } from '../../services/devolucion.service';
import { Carrito } from 'src/app/modules/venta';
import { Router } from '@angular/router';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterService } from 'src/app/shared/services/filter.service';
import { PagerService } from 'src/app/shared/services/pager.service';
import { PrintService } from 'src/app/shared/services/print.service';
import { FacturaService } from 'src/app/shared/services/factura.service';
import { ConfigurationService, TurnoService } from 'src/app/core';
import * as moment from 'moment';
import { PagoService } from 'src/app/modules/venta/services/pago.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorToast } from 'src/app/core/toast/error.toast';

interface AlbaranDevolucion extends Albaran {
  AlbaranItemsDesglose: any;
}
@Component({
  selector: 'ticketing-selector-devolucion',
  templateUrl: './selector-devolucion.component.html',
  styleUrls: ['./selector-devolucion.component.scss']
})
export class SelectorDevolucionComponent implements OnInit, OnDestroy {
  albaran$: Observable<Albaran>;
  albaran: Albaran;
  carritoDevolucion$: Observable<Carrito>;
  arr = [];
  selectedItems = [];

  @ViewChild('checkAllSwitch') checkAllSwitch: ElementRef;

  items: any[];
  conf: any;
  pager: any;
  pagedItems: any[];
  deshabilitarFiltro: boolean;
  busquedaForm: FormGroup;
  pulsado = false;

  fecha;
  recintos = [];
  recintosIndex = [];
  currentRecinto;
  currentRecintoIndex = 0;
  sesionSeleccionada;
  idioma = 'es-ES';
  listadoSesiones= [];
  sesionesSeleccionadas= [];

  private alive = true;

  franjasHorario = [];

  constructor(
    private albaranService: AlbaranService,
    private devolucionService: DevolucionService,
    private pagerService: PagerService,
    private facturaService: FacturaService,
    private printService: PrintService,
    private formControlService: FormControlService,
    private formBuilder: FormBuilder,
    private filterService: FilterService,
    private router: Router,
    private configuration: ConfigurationService,
    private turnoService: TurnoService,
    private toast: ToastrService,
    private pagoService: PagoService
  ) {}

  ngOnInit() {
    this.conf = this.configuration.appConfig.value;
    this.busquedaForm = this.formBuilder.group({
      query: ''
    });
    this.albaran = this.albaranService.albaranValue;
    // this.arr = this.unbundleItems(this.albaran.AlbaranItems);
    // 
    this.arr = this.generateItems(
      this.albaran.AlbaranTicketsDatosImpresion,
      JSON.parse(JSON.stringify(this.albaran.AlbaranItems)), // Yaribel 20210224 Hacemos copia profunda de AlbaranItems
      this.albaran.AlbaranMotivos
    );
    // 
    const listaCompleta = this.arr;
    this.busquedaForm.valueChanges.subscribe(val => {
      this.albaran.AlbaranRecintos.forEach (itemRecinto => {
        if (itemRecinto.Periodo && itemRecinto.Periodo == '1') {
          const recintoInfo = { recintoId: itemRecinto.RecintoId, recintoNombre: itemRecinto.RecintoNombre };
          if (!this.recintosIndex.includes(itemRecinto.RecintoId)) {
            this.recintosIndex.push (
              itemRecinto.RecintoId);
          }
          this.recintos.push(
            recintoInfo);
        }
      });

      this.albaran.AlbaranRecintos.forEach (itemRecinto => {
        if (itemRecinto.Periodo && itemRecinto.Periodo == '1') {
          if (!this.listadoSesiones.includes(itemRecinto.FechaEntrada)) {
            this.listadoSesiones.push(
                itemRecinto.FechaEntrada );
          }
        }
      });
      // 
      // 
      // 
      if (val.query === '') {
        this.arr = listaCompleta;
      } else {
        this.arr = this.filterService.filter(listaCompleta, val.query);
      }
      this.setPage(1);
    });
    this.setPage(1);
    this.carritoDevolucion$ = this.devolucionService.carritoDevolucion$;
    // 
    this.albaranService.anulacionTotal.subscribe(data => {
      if (data) {
        this.deshabilitarFiltro = data;
        this.selectAll();
      } else {
        this.deshabilitarFiltro = data;
        this.unselectAll();
      }
    });
  }

  checkEntrada(event, ticket) {
    if (event.srcElement.checked) {
      // 
      this.selectedItems.push(ticket);
      // 
      this.devolucionService.setItemsSeleccionados(this.selectedItems);
      this.devolucionService.carritoDevolucion.agregarEntrada(JSON.parse(JSON.stringify(ticket.itemInfo)), true); // Yaribel 20210225  Añadimos copia profunda del objeto para que no se modifiquen

      // 
      // 
    } else {
      this.devolucionService.carritoDevolucion.eliminarEntrada(JSON.parse(JSON.stringify(ticket.itemInfo)), true);// Yaribel 20210225  Añadimos copia profunda del objeto para que no se modifiquen
      this.selectedItems = this.selectedItems.filter(e => e.id !== ticket.id);
      this.devolucionService.setItemsSeleccionados(this.selectedItems);
      // 
      // 

    }
    if (
      this.selectedItems.length ===
      this.arr.filter(e => e.Devuelta !== '1').length
    ) {
      this.checkAllSwitch.nativeElement['checked'] = true;
    } else {
      this.checkAllSwitch.nativeElement['checked'] = false;
    }
    if (this.selectedItems.length === 0) {
      this.checkAllSwitch.nativeElement['checked'] = false;
    }
  }

  isChecked(ticket) {
    return this.selectedItems.find(e => e.id === ticket.id);
  }

  checkAllEntradas(event) {
    if (event.srcElement.checked) {
      this.selectAll();
    } else {
      this.unselectAll();
    }
  }

  private selectAll() {
    this.arr.forEach(e => {
      if (!this.isChecked(e) && !e.yaDevuelto) {
        this.selectedItems.push(e);
        this.devolucionService.setItemsSeleccionados(this.selectedItems);
        this.devolucionService.carritoDevolucion.agregarEntrada(e.itemInfo, true);
      }
    });
  }

  private unselectAll() {
    
    this.selectedItems = [];
    this.devolucionService.setItemsSeleccionados(this.selectedItems);
    this.devolucionService.setCarritoDevolucion(new Carrito());
  }

//<< Yaribel 20210224  modificamos el generateItems  para que tenga en cuenta orden conjunta y  y Qr Promo
  // private generateItems(tickets, albaranes, motivos?) {
    
  //   const ticketsAgrupados = this.groupBy(tickets, c =>
  //     c.NumOrdenPromo !== '' ? c.NumOrdenPromo : c.RegistroTablaId
  //   );

  //   
  //   
  //   

  //   const respuesta = [];
  //   const numOrdenPromoId = [];
  //   try {
  //   for (const [key, ticket] of Object.entries(ticketsAgrupados)) {
  //     
  //     
  //     if (Object.entries(ticket).length > 1) {
  //       let contador = 0;
  //       while (contador < Object.entries(ticket).length)  {
  //         

  //         if (ticket[contador].TipoProducto === '1') {
  //           const datosEntrada = albaranes.find(i => {
  //             return i.ItemId === ticket[contador].ItemId && i.TipoProducto == 1;
  //           });
  //           
  //           if (datosEntrada){
  //           datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
  //           datosEntrada.idTarifa = datosEntrada.ItemId;
  //           datosEntrada.TipoEntradaId = datosEntrada.ItemId;
  //           let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId)?
  //                                  motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId).ItemNombre:"";
  //           const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
  //           respuesta.push({
  //             tickets: ticket,
  //             yaDevuelto: yaDevuelto,
  //             id: +ticket[contador].NumTicket,
  //             numTickets: +ticket[contador].NumTicket,
  //             itemInfo: datosEntrada,
  //             descMotivo: txtMotivo
  //           });
  //         }
  //         } else if (ticket[contador].TipoProducto === '2') {
  //           
  //           
  //           if (!numOrdenPromoId.includes(ticket[contador].NumOrdenPromo)) {
  //             const datosEntrada = albaranes.find(item => {
  //                return item.ItemId === ticket[contador].TipoPromocionId && item.TipoProducto == 2;  
  //             });
  //             
  //             if (datosEntrada){
  //             datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
  //             datosEntrada.idTarifa = datosEntrada.ItemId;
  //             datosEntrada.TipoPromocionId = datosEntrada.ItemId;
  //             const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
  //             
  //             
  //             numOrdenPromoId.push(ticket[contador].NumOrdenPromo);
  //             const promocionEntradas = [];
  //             let contadorPromo = 0;
  //             datosEntrada.PromocionEntradas.forEach(entrada => {
  //               //
  //               //
  //               if (contadorPromo >= Object.entries(ticket).length) {
  //                 contadorPromo = 0;
  //               }
  //               if (entrada.NumOrdenPromo == ticket[contador].NumOrdenPromo) {
  //                 const tempObject = Object.assign({}, entrada, {
  //                   NumOrdenPromo: ticket[contador].NumOrdenPromo,
  //                   NumeroEntradas: ticket[contadorPromo].NumTicket
  //                 });
  //                 promocionEntradas.push(tempObject);
  //               }
  //               contadorPromo++;
  //             });
  //             
  //             const temp = Object.assign({}, datosEntrada, {
  //               PromocionEntradas: promocionEntradas
  //             });
  //             let numTickets = '';
  //             for (const [k, item] of Object.entries(ticket)) {
  //               numTickets = '' + numTickets + ', ' + item.NumTicket;
  //             }
  //             numTickets = numTickets.substr(1);
  //             
  //             let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === ticket[contador].ItemId)?
  //                                    motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === ticket[contador].ItemId).ItemNombre:"";
  //             respuesta.push({
  //               tickets: ticket,
  //               yaDevuelto: yaDevuelto,
  //               id: +ticket[contador].NumTicket,
  //               numTickets: numTickets,
  //               itemInfo: temp,
  //               descMotivo: txtMotivo
  //             });
  //           }
  //           }
  //         }
  //         contador ++;
  //       }
  //     } else {
  //       if (ticket[0].TipoProducto === '1') {
  //         const datosEntrada = albaranes.find(i => {
  //           return i.ItemId === ticket[0].ItemId && i.TipoProducto == 1;
  //         });
  //         
  //         if (datosEntrada){
  //         datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
  //         datosEntrada.idTarifa = datosEntrada.ItemId;
  //         datosEntrada.TipoEntradaId = datosEntrada.ItemId;
  //         const yaDevuelto = ticket[0].Devuelta === '0' ? false : true;
  //         let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket === ticket[0].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId)?
  //                                motivos.find(x => x.NumTicket === ticket[0].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId).ItemNombre:"";
  //         respuesta.push({
  //           tickets: ticket,
  //           yaDevuelto: yaDevuelto,
  //           id: +ticket[0].NumTicket,
  //           numTickets: +ticket[0].NumTicket,
  //           itemInfo: datosEntrada,
  //           descMotivo: txtMotivo
  //         });
  //       }
  //       } else if (ticket[0].TipoProducto === '2') {
  //         
  //         
  //         if (!numOrdenPromoId.includes(ticket[0].NumOrdenPromo)) {
  //           const datosEntrada = albaranes.find(item => {
  //             //return item.ItemId === ticket[0].ItemId && item.TipoProducto == 2;
  //             return item.ItemId === ticket[0].TipoPromocionId && item.TipoProducto == 2; //Yaribel 20201127 Cambiamos esto para que evalue por item
  //           });
  //           
  //           if (datosEntrada){
  //           datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
  //           datosEntrada.idTarifa = datosEntrada.ItemId;
  //           datosEntrada.TipoPromocionId = datosEntrada.ItemId;
  //           const yaDevuelto = ticket[0].Devuelta === '0' ? false : true;
  //           
  //           
  //           numOrdenPromoId.push(ticket[0].NumOrdenPromo);
  //           const promocionEntradas = [];
  //           datosEntrada.PromocionEntradas.forEach(entrada => {
  //             const tempObject = Object.assign({}, entrada, {
  //               NumOrdenPromo: ticket[0].NumOrdenPromo,
  //               NumeroEntradas: ticket[0].NumTicket
  //             });
  //             promocionEntradas.push(tempObject);
  //           });
  //           
  //           const temp = Object.assign({}, datosEntrada, {
  //             PromocionEntradas: promocionEntradas
  //           });
  //           let numTickets = '';
  //           for (const [k, item] of Object.entries(ticket)) {
  //             numTickets = '' + numTickets + ', ' + item.NumTicket;
  //           }
  //           numTickets = numTickets.substr(1);
  //           
  //           let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket === ticket[0].NumTicket && x.TipoEntradaId === ticket[0].ItemId)?
  //                                  motivos.find(x => x.NumTicket === ticket[0].NumTicket && x.TipoEntradaId === ticket[0].ItemId).ItemNombre:"";
  //           respuesta.push({
  //             tickets: ticket,
  //             yaDevuelto: yaDevuelto,
  //             id: +ticket[0].NumTicket,
  //             numTickets: numTickets,
  //             itemInfo: temp,
  //             descMotivo: txtMotivo
  //           });
  //         }
  //         }
  //       }
  //     }
  //   }

  //   
  // }catch(e){
  //   console.error(e);
  //   this.toast.error('Ocurrió un error inesperado', 'Error', {
  //     toastComponent: ErrorToast
  //   });
  // } finally{
  //   return respuesta;
  // }

  // }

  private generateItems(tickets, albaranes, motivos?) {
    
    const ticketsAgrupados = this.groupBy(tickets, c =>
      c.NumOrdenPromo !== '' ? c.NumOrdenPromo : c.RegistroTablaId
    );

    // 
    // 
    // 

    const respuesta = [];
    const numOrdenPromoId = [];
    try {
    for (const [key, ticket] of Object.entries(ticketsAgrupados)) {
      // 
      // 
      // if(Object.entries(ticket).length > 0)
      // {
      //   let contador = 0;
      //   while (contador < Object.entries(ticket).length)  {
      //     
      //     if (ticket[contador].TipoProducto === '1') {
      //       const datosEntrada = albaranes.find(i => {
      //         return i.ItemId === ticket[contador].ItemId && i.TipoProducto == 1;
      //       });
      //       
      //       if (datosEntrada){
      //       datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
      //       datosEntrada.idTarifa = datosEntrada.ItemId;
      //       datosEntrada.TipoEntradaId = datosEntrada.ItemId;
      //       let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId)?
      //                              motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId).ItemNombre:"";
      //       const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
      //       respuesta.push({
      //         tickets: ticket,
      //         yaDevuelto: yaDevuelto,
      //         id: +ticket[contador].NumTicket,
      //         numTickets: +ticket[contador].NumTicket,
      //         itemInfo: datosEntrada,
      //         descMotivo: txtMotivo
      //       });
      //     }
      //     } else if (ticket[contador].TipoProducto === '2') {
      //       
      //       
      //       if (!numOrdenPromoId.includes(ticket[contador].NumOrdenPromo)) {
      //         const datosEntrada = albaranes.find(item => {
      //            return item.ItemId === ticket[contador].TipoPromocionId && item.TipoProducto == 2;  
      //         });
      //         
      //         if (datosEntrada){
      //         datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
      //         datosEntrada.idTarifa = datosEntrada.ItemId;
      //         datosEntrada.TipoPromocionId = datosEntrada.ItemId;
      //         const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
      //         
      //         
      //         numOrdenPromoId.push(ticket[contador].NumOrdenPromo);
      //         const promocionEntradas = [];
      //         let contadorPromo = 0;
      //         datosEntrada.PromocionEntradas.forEach(entrada => {
      //           //
      //           //
      //           if (contadorPromo >= Object.entries(ticket).length) {
      //             contadorPromo = 0;
      //           }
      //           if (entrada.NumOrdenPromo == ticket[contador].NumOrdenPromo) {
      //             const tempObject = Object.assign({}, entrada, {
      //               NumOrdenPromo: ticket[contador].NumOrdenPromo,
      //               NumeroEntradas: ticket[contadorPromo].NumTicket
      //             });
      //             promocionEntradas.push(tempObject);
      //           }
      //           contadorPromo++;
      //         });
      //         
      //         const temp = Object.assign({}, datosEntrada, {
      //           PromocionEntradas: promocionEntradas
      //         });
      //         let numTickets = '';
      //         for (const [k, item] of Object.entries(ticket)) {
      //           numTickets = '' + numTickets + ', ' + item.NumTicket;
      //         }
      //         numTickets = numTickets.substr(1);
      //         
      //         let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === ticket[contador].ItemId)?
      //                                motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === ticket[contador].ItemId).ItemNombre:"";
      //         respuesta.push({
      //           tickets: ticket,
      //           yaDevuelto: yaDevuelto,
      //           id: +ticket[contador].NumTicket,
      //           numTickets: numTickets,
      //           itemInfo: temp,
      //           descMotivo: txtMotivo
      //         });
      //       }
      //       }
      //     }
      //     contador ++;
      //   }

      // }

      if (Object.entries(ticket).length > 1) {
        let contador = 0;
        while (contador < Object.entries(ticket).length)  {
          // 

          if (ticket[contador].TipoProducto === '1') {
            const datosEntrada = albaranes.find(i => {
              return i.ItemId === ticket[contador].ItemId && i.TipoProducto == 1;
            });
            // 
            if (datosEntrada){
            datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
            datosEntrada.idTarifa = datosEntrada.ItemId;
            datosEntrada.TipoEntradaId = datosEntrada.ItemId;
            let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId)?
                                   motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId).ItemNombre:"";
            const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
            datosEntrada.Cantidad = ticket[contador].Cantidad;
            respuesta.push({
              tickets: ticket,
              yaDevuelto: yaDevuelto,
              id: +ticket[contador].NumTicket,
              numTickets: +ticket[contador].NumTicket,
              itemInfo: datosEntrada,
              descMotivo: txtMotivo
            });
          }
          } else if (ticket[contador].TipoProducto === '2') {
            // 
            // 

            if (!numOrdenPromoId.includes(ticket[contador].NumOrdenPromo)) {
              const datosEntrada = albaranes.find(item => {
                 return item.ItemId === ticket[contador].TipoPromocionId && item.TipoProducto == 2;  
              });
              // 
              if (datosEntrada){
              datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
              datosEntrada.idTarifa = datosEntrada.ItemId;
              datosEntrada.TipoPromocionId = datosEntrada.ItemId;
              const yaDevuelto = ticket[contador].Devuelta === '0' ? false : true;
              datosEntrada.Cantidad = ticket[contador].Cantidad;
              // 
              // 
              numOrdenPromoId.push(ticket[contador].NumOrdenPromo);
              const promocionEntradas = [];
              let contadorPromo = 0;
              datosEntrada.PromocionEntradas.forEach(entrada => {
                if (contadorPromo >= Object.entries(ticket).length) {
                  contadorPromo = 0;
                }               
                //<< Yaribel 20210224 Añadimos comprobacion de orden conjunta o QRPromo 
                const tempObject = Object.assign({}, entrada, {
                  NumOrdenPromo: +ticket[0].OrdenCjta > 0? entrada.NumOrdenPromo : ticket[contador].NumOrdenPromo,
                  NumeroEntradas: ticket[contadorPromo].NumTicket
                });
                if(ticket[0].OrdenCjta == '0'){
                  if (entrada.NumOrdenPromo == ticket[contador].NumOrdenPromo) {                                      
                     promocionEntradas.push(tempObject);                    
                  }
                }else{
                  promocionEntradas.push(tempObject);
                }
                // Yaribel 20210224 >>               
                contadorPromo++;
              });
              // 
              const temp = Object.assign({}, datosEntrada, {
                PromocionEntradas: promocionEntradas
              });
              let numTickets = '';
              for (const [k, item] of Object.entries(ticket)) {
                numTickets = '' + numTickets + ', ' + item.NumTicket;
              }
              numTickets = numTickets.substr(1);
              // 
              let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === ticket[contador].ItemId)?
                                     motivos.find(x => x.NumTicket == ticket[contador].NumTicket && x.TipoEntradaId === ticket[contador].ItemId).ItemNombre:"";
              respuesta.push({
                tickets: ticket,
                yaDevuelto: yaDevuelto,
                id: +ticket[contador].NumTicket,
                numTickets: numTickets,
                itemInfo: temp,
                descMotivo: txtMotivo
              });
            }
            }
          }
          contador ++;
        }
      } else {
        if (ticket[0].TipoProducto === '1') {
          const datosEntrada = albaranes.find(i => {
            return i.ItemId === ticket[0].ItemId && i.TipoProducto == 1;
          });
          // 
          if (datosEntrada){
          datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
          datosEntrada.idTarifa = datosEntrada.ItemId;
          datosEntrada.TipoEntradaId = datosEntrada.ItemId;
          const yaDevuelto = ticket[0].Devuelta === '0' ? false : true;
          datosEntrada.Cantidad = ticket[0].Cantidad;
          let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket === ticket[0].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId)?
                                 motivos.find(x => x.NumTicket === ticket[0].NumTicket && x.TipoEntradaId === datosEntrada.TipoEntradaId).ItemNombre:"";
          respuesta.push({
            tickets: ticket,
            yaDevuelto: yaDevuelto,
            id: +ticket[0].NumTicket,
            numTickets: +ticket[0].NumTicket,
            itemInfo: datosEntrada,
            descMotivo: txtMotivo
          });
        }
        } else if (ticket[0].TipoProducto === '2') {
          // 
          
          if (!numOrdenPromoId.includes(ticket[0].NumOrdenPromo)) {
            const datosEntrada = albaranes.find(item => {
              //return item.ItemId === ticket[0].ItemId && item.TipoProducto == 2;
              return item.ItemId === ticket[0].TipoPromocionId && item.TipoProducto == 2; //Yaribel 20201127 Cambiamos esto para que evalue por item
            });
            // 
            if (datosEntrada){
            datosEntrada.PrecioEnDivisaReferencia = datosEntrada.PrecioUnidad;
            datosEntrada.idTarifa = datosEntrada.ItemId;
            datosEntrada.TipoPromocionId = datosEntrada.ItemId;
            const yaDevuelto = ticket[0].Devuelta === '0' ? false : true;
            datosEntrada.Cantidad = ticket[0].Cantidad;
            // 
            // 
            numOrdenPromoId.push(ticket[0].NumOrdenPromo);
            const promocionEntradas = [];
            datosEntrada.PromocionEntradas.forEach(entrada => {      
              //<<Yaribel 20210224 Añadimos comprobacion de orden conjunta o QRPromo
              // const tempObject = Object.assign({}, entrada, {
              //   NumOrdenPromo: ticket[0].NumOrdenPromo,
              //   NumeroEntradas: ticket[0].NumTicket
              // });
              // promocionEntradas.push(tempObject);
              const tempObject = Object.assign({}, entrada, {
                NumOrdenPromo: ticket[0].NumOrdenPromo,
                NumeroEntradas: ticket[0].NumTicket
              });
              if(ticket[0].OrdenCjta == '0'){
                if(entrada.NumOrdenPromo == ticket[0].NumOrdenPromo) {
                  if(ticket[0].ImprimirQRPromo == '1' || ticket[0].ImprimirQRPromo == 'True'){
                  promocionEntradas.push(tempObject);
                  } else{
                    if(entrada.TipoEntradaId == ticket[0].TipoProducto)
                      promocionEntradas.push(tempObject);
                  }
                }
              } else {
                promocionEntradas.push(tempObject);
              }
              //Yaribel 20210224 >>
            });
            // 
            const temp = Object.assign({}, datosEntrada, {
              PromocionEntradas: promocionEntradas
            });
            let numTickets = '';
            for (const [k, item] of Object.entries(ticket)) {
              numTickets = '' + numTickets + ', ' + item.NumTicket;
            }
            numTickets = numTickets.substr(1);
            // 
            let txtMotivo:string = motivos && motivos.length >0 && motivos.find(x => x.NumTicket === ticket[0].NumTicket && x.TipoEntradaId === ticket[0].ItemId)?
                                   motivos.find(x => x.NumTicket === ticket[0].NumTicket && x.TipoEntradaId === ticket[0].ItemId).ItemNombre:"";
            respuesta.push({
              tickets: ticket,
              yaDevuelto: yaDevuelto,
              id: +ticket[0].NumTicket,
              numTickets: numTickets,
              itemInfo: temp,
              descMotivo: txtMotivo
            });
          }
          }
        }
      }
    }

    // 
  }catch(e){
    console.error(e);
    this.toast.error('Ocurrió un error inesperado', 'Error', {
      toastComponent: ErrorToast
    });
  } finally{
    return respuesta;
  }

  }
// Yaribel 20210224 >>


  groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
  }

  private setPage(page: number): void {
    this.pager = this.pagerService.getPager(this.arr.length, page, 10);
    this.pagedItems = this.arr.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    this.pager.pages = this.pager.pages.filter(
      num => num <= this.pager.totalPages
    );

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }

  async devolver() {
    this.pulsado = true;
    const saltar = true;
    let oldNumAlbaran: string = this.albaranService.albaranValue.NumAlbaran;
    let oldAlbaranTPVid: string = this.albaranService.albaranValue.TPVId;
    if (!saltar && (this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1'
     || this.albaranService.albaranValue.AlbaranFOPs.length < 2)) {
       
      if (this.selectedItems.length === this.arr.length) {
        // 
        if (this.albaranService.albaranValue.TienePagoCC === '1') {
          this.albaranService
            .generarIdentificadores(
              this.albaranService.albaranValue.TPVId,
              this.albaranService.albaranValue.Prefijo
            )
            .subscribe((data: any) => {
              /* const res: any = await this.albaranService.anularAlbaranTarjeta(
                data.DatosResult.Identificador,
                data.DatosResult.NumAlbaran
              ); */
              this.albaranService
                .anularAlbaranTarjeta(
                  data.DatosResult.Identificador,
                  data.DatosResult.NumAlbaran
                )
                .subscribe(item => {
                  // 
                  if (item.AlbaranId && item.Facturado === '0') {
                    this.albaranService
                      .refrescarAlbaran(
                        this.albaranService.albaranValue.NumAlbaran
                      )
                      .subscribe(() => {
                        this.albaranService.anulacionTotal.next(false);
                        this.unselectAll();
                        this.router.navigate(['gestionar-venta']);
                      });

                  } else if (item.AlbaranId && item.Facturado === '1') {
                    // 
                    // this.facturaService
                    //   .recuperarFacturaRelacionAnular(item)
                    //   .pipe(
                    //     mergeMap(res => {
                    //       if (res) {
                    //         const facturas = res.DatosResult.Facturas.map(
                    //           factura => this.facturaService.pdfFactura(factura)
                    //         );
                    //         return forkJoin(facturas);
                    //       }
                    //     })
                    //   )
                    //   .subscribe(async (facturas: any) => {
                    //     if (facturas) {
                    //       for (let i = 0; i < facturas.length; i++) {
                    //         await this.printService.printFacturaPDF(
                    //           facturas[i].DatosResult
                    //         );
                    //       }
                    //       this.albaranService
                    //       .refrescarAlbaran(
                    //         this.albaranService.albaranValue.NumAlbaran
                    //       )
                    //       .subscribe(() => {
                    //         this.albaranService.anulacionTotal.next(false);
                    //         this.unselectAll();
                    //         this.router.navigate(['gestionar-venta']);
                    //       });
                    //     }
                    //   });

                    this.facturaService
                    .pdfFactura(item)
                         .subscribe(async (facturas: any) => {
                      if (facturas.DatosResult) {
                        await this.printService.printFacturaPDF(
                          facturas.DatosResult
                          );                       
                        this.albaranService
                        .refrescarAlbaran(
                          this.albaranService.albaranValue.NumAlbaran
                        )
                        .subscribe(() => {
                          this.albaranService.anulacionTotal.next(false);
                          this.unselectAll();
                          this.router.navigate(['gestionar-venta']);
                        });
                      }
                    });
                  }
                  if(item.NumAlbaran)
                  {
                    this.imprimirJustificanteDevolucion (item.NumAlbaran, 'TOTAL');
                    this.albaranService.refrescarAlbaran(oldNumAlbaran).subscribe();
                  }
                
                  // this.albaranService.refrescarAlbaranConTPVId(item.Numalbaran,item.TPVId).subscribe(datos =>{
                  //   if(datos)
                  //   {
                  //       this.printService.printRefundAlbaran({ 
                  //         datos: datos
                  //       });
                  //   }
                  // });


                });
            });
        } else {
          const response: any = await this.albaranService.anularAlbaran();
          if (response.AlbaranId && response.Facturado === '0') {
            this.albaranService
              .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
              .subscribe(() => {
                this.albaranService.anulacionTotal.next(false);
                this.unselectAll();
                this.router.navigate(['gestionar-venta']);
              });
          } else if (response.AlbaranId && response.Facturado === '1') {
            // 
            // this.facturaService
            //   .recuperarFacturaRelacionAnular(response)
            //   .pipe(
            //     mergeMap(resp => {
            //       if (resp) {
            //         const facturas = resp.DatosResult.Facturas.map(factura =>
            //           this.facturaService.pdfFactura(factura)
            //         );
            //         return forkJoin(facturas);
            //       }
            //     })
            //   )
            //   .subscribe(async (facturas: any) => {
            //     if (facturas) {
            //       for (let i = 0; i < facturas.length; i++) {
            //         await this.printService.printFacturaPDF(facturas[i].DatosResult);
            //       }
            //       this.albaranService
            //       .refrescarAlbaran(
            //         this.albaranService.albaranValue.NumAlbaran
            //       )
            //       .subscribe(() => {
            //         this.albaranService.anulacionTotal.next(false);
            //         this.unselectAll();
            //         this.router.navigate(['gestionar-venta']);
            //       });
            //     }
            //   });
            this.facturaService
            .pdfFactura(response)                
            .subscribe(async (facturas: any) => {
              if (facturas.DatosResult) {
                await this.printService.printFacturaPDF(facturas.DatosResult);               
                this.albaranService
                .refrescarAlbaran(
                  this.albaranService.albaranValue.NumAlbaran
                )
                .subscribe(() => {
                  this.albaranService.anulacionTotal.next(false);
                  this.unselectAll();
                  this.router.navigate(['gestionar-venta']);
                });
              }
            });
          }
          if(response.NumAlbaran)
                  {
                    this.imprimirJustificanteDevolucion (response.NumAlbaran, 'TOTAL');
                    this.albaranService.refrescarAlbaran(oldNumAlbaran).subscribe();
                  }
        }

      } else {
        if (this.albaranService.albaranValue.TienePagoCC === '1') {
          this.albaranService
            .generarIdentificadores(
              this.albaranService.albaranValue.TPVId,
              this.albaranService.albaranValue.Prefijo
            )
            .subscribe((data: any) => {
              this.albaranService
                .devolucionParcialTarjeta(
                  this.selectedItems,
                  data.DatosResult.Identificador,
                  data.DatosResult.NumAlbaran
                )
                .subscribe((item:any) => {

                  // if (item) {
                  //   this.albaranService
                  //     .datosAlbaran(this.albaranService.albaranValue.NumAlbaran)
                  //     .subscribe(res => {
                  //       if (
                  //         res.DatosResult.ListadoAlbaranInfo[0].Facturado === '1'
                  //       ) {
                  //         this.facturaService
                  //           .recuperarFacturaRelacion(
                  //             this.albaranService.albaranValue
                  //           )
                  //           .pipe(
                  //             mergeMap(res => {
                  //               if (res) {
                  //                 const facturas = res.DatosResult.Facturas.map(
                  //                   factura =>
                  //                     this.facturaService.pdfFactura(factura)
                  //                 );
                  //                 return forkJoin(facturas);
                  //               }
                  //             })
                  //           )
                  //           .subscribe((facturas: any) => {
                  //             if (facturas) {
                  //               for (let i = 0; i < facturas.length; i++) {
                  //                 this.printService.printFacturaPDF(
                  //                   facturas[i].DatosResult
                  //                 );
                  //               }
                  //               this.albaranService
                  //                 .refrescarAlbaran(
                  //                   this.albaranService.albaranValue.NumAlbaran
                  //                 )
                  //                 .subscribe();
                  //               this.albaranService.anulacionTotal.next(false);
                  //               this.unselectAll();
                  //               this.router.navigate(['gestionar-venta']);
                  //             }
                  //           });
                  //       } else {
                  //         this.albaranService
                  //           .refrescarAlbaran(
                  //             this.albaranService.albaranValue.NumAlbaran
                  //           )
                  //           .subscribe();
                  //         this.albaranService.anulacionTotal.next(false);
                  //         this.unselectAll();
                  //         this.router.navigate(['gestionar-venta']);
                  //       }
                  //     });
                  // }

                  if (item.DatosResult) {
                    this.albaranService
                      .datosAlbaranConTPVId(item.DatosResult.NumAlbaran, item.DatosResult.TpvId)
                      .subscribe(res => {
                        if (
                          res.DatosResult.ListadoAlbaranInfo[0].Facturado === '1'
                        ) {                          // Yaribel 20201123 Hacer la peticion de Factura
                          this.facturaService.pdfFactura(res.DatosResult.ListadoAlbaranInfo[0])
                          .subscribe((facturas: any) => {
                              if (facturas.DatosResult) {
                                  this.printService.printFacturaPDF(
                                    facturas.DatosResult
                                  );
                                
                                this.albaranService
                                  .refrescarAlbaran(
                                    oldNumAlbaran
                                  )
                                  .subscribe();
                                this.albaranService.anulacionTotal.next(false);
                                this.unselectAll();
                                this.router.navigate(['gestionar-venta']);
                              }
                            });
                        } else {
                          
                          this.albaranService
                          .refrescarAlbaran(
                            oldNumAlbaran
                          )
                          .subscribe();
                        this.albaranService.anulacionTotal.next(false);
                        this.unselectAll();
                        this.router.navigate(['gestionar-venta']);
                        }
                      });

                  if(item.DatosResult)
                  {
                    this.imprimirJustificanteDevolucion (item.DatosResult.NumAlbaran, 'PARCIAL');
                    this.albaranService.refrescarAlbaran(oldNumAlbaran).subscribe();
                  }

                  }
                });
            });
        } else {
          const res: any = await this.albaranService.devolucionParcial(
            this.selectedItems
          );
          // if (res) {
          //   this.albaranService
          //     .datosAlbaran(this.albaranService.albaranValue.NumAlbaran)
          //     .subscribe(res => {
          //       if (res.DatosResult.ListadoAlbaranInfo[0].Facturado === '1') {
          //         this.facturaService
          //           .recuperarFacturaRelacion(this.albaranService.albaranValue)
          //           .pipe(
          //             mergeMap(res => {
          //               if (res) {
          //                 const facturas = res.DatosResult.Facturas.map(factura =>
          //                   this.facturaService.pdfFactura(factura)
          //                 );
          //                 return forkJoin(facturas);
          //               }
          //             })
          //           )
          //           .subscribe((facturas: any) => {
          //             if (facturas) {
          //               for (let i = 0; i < facturas.length; i++) {
          //                 this.printService.printFacturaPDF(
          //                   facturas[i].DatosResult
          //                 );
          //               }
          //               this.albaranService
          //                 .refrescarAlbaran(
          //                   this.albaranService.albaranValue.NumAlbaran
          //                 )
          //                 .subscribe();
          //               this.albaranService.anulacionTotal.next(false);
          //               this.unselectAll();
          //               this.router.navigate(['gestionar-venta']);
          //             }
          //           });
          //       } else {
          //         this.albaranService
          //           .refrescarAlbaran(this.albaranService.albaranValue.NumAlbaran)
          //           .subscribe();
          //         this.albaranService.anulacionTotal.next(false);
          //         this.unselectAll();
          //         this.router.navigate(['gestionar-venta']);
          //       }
          //     });
          // }

          if (res.DatosResult) {
            this.albaranService
              .datosAlbaranConTPVId(res.DatosResult.NumAlbaran, res.DatosResult.TpvId)
              .subscribe(res => {
                if (res.DatosResult.ListadoAlbaranInfo[0].Facturado === '1') {
                  this.facturaService.pdfFactura(res.DatosResult.ListadoAlbaranInfo[0])                         
                    .subscribe((facturas: any) => {
                      if (facturas.DatosResult) {
                        this.printService.printFacturaPDF(
                            facturas.DatosResult
                          );                       
                        this.albaranService
                          .refrescarAlbaran(
                            oldNumAlbaran
                          )
                          .subscribe();
                        this.albaranService.anulacionTotal.next(false);
                        this.unselectAll();
                        this.router.navigate(['gestionar-venta']);
                      }
                    });
                } else {
                  this.albaranService
                    .refrescarAlbaran(oldNumAlbaran)
                    .subscribe();
                  this.albaranService.anulacionTotal.next(false);
                  this.unselectAll();
                  this.router.navigate(['gestionar-venta']);
                }

              });

              this.imprimirJustificanteDevolucion (res.DatosResult.NumAlbaran, 'PARCIAL');
              this.albaranService.refrescarAlbaran(oldNumAlbaran).subscribe();
          }
        }
      }
    } else { //Pago multiple nueva ventana
      // 
      this.router.navigate(['/gestionar-venta/pago-devolucion-parcial']);
    }
  }
  ngOnDestroy() {
    
    const saltar = true;
    if (!saltar && (this.conf.DatosResult.AppConfigJsonPago.PagoMultiple != '1'
    || this.albaranService.albaranValue.AlbaranFOPs.length < 2)) {
      this.unselectAll();
      this.albaranService.anulacionTotal.next(false);
    } else {
      this.devolucionService.setItemsSeleccionados(this.selectedItems);
      this.devolucionService.setCarritoDevolucion(this.devolucionService.carritoDevolucion);
      this.selectedItems = [];
    }
  }

  focusValue(event) {
    this.formControlService.setCurrentFormGroup(
      this.busquedaForm,
      event.srcElement,
      event.srcElement.getAttribute('category')
    );
  }

  getFechaSeleccionada() {
    /*if (this.albaranService.) {
      return moment(this.ventaService.fechaSeleccionadaValue)
        .locale(this.idioma)
        .format('L');
    }*/
  }

  claseSeleccionaSesion(sesion) {
    let seleccionada = false;
    // 
    if (this.sesionesSeleccionadas.length > 0) {
      // 
      this.sesionesSeleccionadas.forEach (sesionSeleccionada => {
        if (sesionSeleccionada.FechaEntrada == sesion.FechaEntrada &&
          sesion.RecintoId == sesionSeleccionada.RecintoId) {
          seleccionada = true;
        }
      });
    }
    if (seleccionada) {
      return 'Rtable-cell--content horario-content selected';
    } else {
      return 'Rtable-cell--content horario-content';
    }
  }

  seleccionarSesion(sesion) {
    // 
    this.sesionesSeleccionadas.push(sesion);
  }

  prev() {
    if (this.comprobarLimitePrev()) {
      return;
    }
    this.currentRecintoIndex--;
    this.currentRecinto = this.recintos[this.currentRecintoIndex];
    //this.ventaService.currentRecinto = this.recintos[this.currentRecintoIndex];
  }

  comprobarLimitePrev() {
    return this.currentRecintoIndex === 0;
  }

  next() {
    if (this.comprobarLimiteNext()) {
      //this.navigationService.next();
      return;
    }
    this.currentRecintoIndex++;
    this.currentRecinto = this.recintos[this.currentRecintoIndex];
    //this.ventaService.currentRecinto = this.recintos[this.currentRecintoIndex];
  }

  comprobarLimiteNext() {
    return this.currentRecintoIndex === this.recintos.length - 1;
  }
  
  isSelected(sesion) {
    /*
     
     /*return this.ventaService.carritoValue.horarios.find(
       horario =>
         (horario.sesion.RecintosSesionId === sesion.RecintosSesionId ||
           (!horario.sesion.RecintosSesionId && sesion.RecintoId == horario.sesion.RecintoId)) &&
         horario.sesion.Fecha === sesion.Fecha &&
         horario.sesion.HoraInicio === sesion.HoraInicio
     );*/
   }


   imprimirJustificanteDevolucion (NumAlbaran: string, tipo: string){
//TODO  Yaribel 20201124 mejorar mapear el objeto y no usar refrescarAlbaran
    this.albaranService.refrescarAlbaran(NumAlbaran).subscribe(res =>{
      if(res)
      {
        const albaranValue = this.albaranService.albaranValue;
        const NombreTPV = this.albaranService.albaranValue.NombreTPV;
        const IdTPV = this.albaranService.albaranValue.IdTPV;
        this.pagoService.datosCabecera().pipe(take(1)).subscribe(data => {
        switch (tipo){
          case 'TOTAL' :
            this.printService.printRefundAlbaran({ 
              datos: albaranValue,
              NombreTPV: NombreTPV,
              IdTPV: IdTPV
            }, data);
            break;
            case 'PARCIAL':
              this.printService.printPartialRefund({
                datos: albaranValue
              }, data);
              break;
        }
      });
      }

    });

   
   }
}
