import { Injectable } from '@angular/core';
import { of, Subject, BehaviorSubject } from 'rxjs';
import { VentaService } from './venta.service';
import { Router } from '@angular/router';
import { MotivosService } from './motivos.service';
import { RecintosService } from './recintos.service';
import { HashLocationStrategy } from '@angular/common';
import { ConfigurationService } from '../../../core/conf/configuration.service';
import { ProcedenciaService } from './procedencia.service';

@Injectable({
  providedIn: 'root'
})
export class StepperNavigationService {
  continuarVenta = new BehaviorSubject(undefined);
  pasos = [
    {
      url: 'entradas',
      nombre: 'ENTRADAS',
      visible: true,
      activado: true
    },
    /*{
      url: 'motivos',
      nombre: 'MOTIVOS',
      visible: false,
      activado: false
    },*/
    {
      url: 'horarios',
      nombre: 'HORARIOS',
      visible: false,
      activado: true
    },
    {
      url: 'procedencia',
      nombre: 'PROCEDENCIA',
      visible: false,
      activado: this.configuration.datosTPVPathValue && this.configuration.datosTPVPathValue.TipoProcedencia && this.configuration.datosTPVPathValue.TipoProcedencia !== "0" ? true : false
    },
    {
      url: 'pago',
      nombre: 'PAGO',
      visible: false,
      activado: true
    }
  ];

  actividades = [
    {
      url: 'actividades',
      nombre: 'ACTIVIDADES',
      visible: true,
      activado: true
    },
    {
      url: 'seleccion-sesiones',
      nombre: 'SESIONES',
      visible: false,
      activado: true
    },
    {
      url: 'seleccion-butaca',
      nombre: 'SELECCIÓN LOCALIDAD',
      visible: false,
      activado: true
    }
  ];
  private nextRoute;
  private recintos = [];
  puedeIrAPago = new BehaviorSubject(true);

  constructor(
    private router: Router,
    private venta: VentaService,
    private motivosService: MotivosService,
    private recintosService: RecintosService,
    public procedenciasService: ProcedenciaService,
    private configuration: ConfigurationService,
  ) {
    
  }

  getSteps() {
    return of(this.pasos);
  }

  getShowSteps() {
    return of(this.actividades);
  }

  siguientePaso(url: string) {
    const pasos = this.pasos.filter(paso => paso.activado === true);
    return pasos[pasos.findIndex(paso => paso.url === url) + 1];
  }

  anteriorPaso(url: string) {
    const pasos = this.pasos.filter(paso => paso.activado === true);
    return pasos[pasos.findIndex(paso => paso.url === url) - 1];
  }

  hacerVisible(url: string) {
    this.pasos[this.pasos.findIndex(paso => paso.url === url)].visible = true;
  }

  resetearPasos() {
    this.pasos.forEach(paso => {
      paso.activado = true;
      if (paso.url !== 'entradas') {
        paso.visible = false;
      }
    });
  }

  bloquearNavegacionPorPago() {
    this.pasos.forEach(paso => {
      if (paso.url !== 'pago') {
        paso.activado = false;
      }
      return paso;
    });
  }

  desbloquearNavegacionPorPago() {
    this.pasos.forEach(paso => {
      paso.activado = true;
    });
  }

  nextActividad() {
    const url = this.getUrlVentaSlice();
    const pasos = this.pasos;
    this.pasos = this.actividades;
    this.nextRoute = this.siguientePaso(this.nextRoute);
    this.nextRoute = this.siguientePaso(url);
    if (!this.nextRoute) {
      return;
    }
    // this.navigateToNextRoute();
    this.router
      .navigate(['auditorio', this.nextRoute.url], {
        state: { fromButton: true }
      })
      .then(success => {
        if (success) {
          this.hacerVisible(this.nextRoute.url);
          this.pasos = pasos;
        }
      });
  }

  next() {
    const url = this.getUrlVentaSlice();
    this.setNextSteps();
    //WOIT debriamos saltarnos los pasos no activos
    this.compruebaProcedencias();
    this.nextRoute = this.siguientePaso(url);
    if (!this.nextRoute) {
      return;
    }
    switch (url) {
      case 'entradas':
        if (this.venta.carritoValue.entradas.length === 0) {
          return;
        }
        this.navigateToNextRoute();
        break;
      case 'horarios':
        let puedeIrAPago = true;
        this.recintosService.recintosValue.forEach(m => {
          const seHaElegidoRecinto = this.venta.carritoValue.horarios.find(
            h => h.recinto.Recinto === m.Recinto
          );
          if (m.obligatorio && !seHaElegidoRecinto) {
            puedeIrAPago = false;
          }
        });
        if (puedeIrAPago) {
          this.navigateToNextRoute();
        }
        break;
      case 'procedencia':
        //comprobamos si se han seleccionado todas las procedencias antes de saltar al siguiente.
        //necesitaamos un procedencias service similar al de recintos
        setTimeout(() => {
          let continuamos = this.procedenciasService.procedenciasCompletas();
          if (continuamos){ this.navigateToNextRoute();}
        },500);

        break;
      default:
        this.navigateToNextRoute();
    }
  }

  getUrlVentaSlice() {
    if (this.router.url === '/') {
      return 'entradas';
    }
    return this.router.url.split('/')[2];
  }

  navigateToNextRoute() {
    this.router
      .navigate(['venta', this.nextRoute.url], { state: { fromButton: true } })
      .then(success => {
        if (success) {
          this.hacerVisible(this.nextRoute.url);
          this.venta.carritoModificado = false;
          this.venta.setHayDescuento(false); //Yaribel 20210730  cliente Mapfre VSTS 8370
        }
      });
  }

  setNextSteps() {
    //this.setMotivos();
    this.setHorarios();
    this.nextRoute = this.siguientePaso(this.nextRoute);
  }

  setMotivos() {
    const entradasMotivos = [];
    
    this.venta.carritoValue.entradas.forEach(e => {
      if (e.entrada.TipoProductoId === '1' && e.entrada.GrupoMotivoid !== '') {
        entradasMotivos.push({
          Entrada: e.entrada,
          GrupoMotivoId: e.entrada.GrupoMotivoId,
          cantidad: e.cantidad
        });
          //<< Yaribel 20210504  Se ha modificado el codigo para indicar GrupoMotivoId tanto en entradas como en promociones y solo tiene valor si tiene motivos seleccionables                   
      // } else if (e.entrada.TipoProducto === '2') {   //
      } else if (e.entrada.TipoProducto === '2' && e.entrada.GrupoMotivoid !== '') {       
        for (let i = 0; i < e.entrada.PromocionEntradas.length; i++) {
                  // if (e.entrada.PromocionEntradas[i].GrupoMotivoId !== '') {
            entradasMotivos.push({
              Entrada: e.entrada,
              //GrupoMotivoId: e.entrada.PromocionEntradas[i].GrupoMotivoId,
              GrupoMotivoId:  e.entrada.GrupoMotivoid,
              cantidad:
                e.cantidad *
                parseInt(e.entrada.PromocionEntradas[i].NumeroEntradas, 10)
            });
            return;
          // }
        }
      }
    });
    // 
    this.pasos[this.pasos.findIndex(paso => paso.url === 'motivos')].activado =
      entradasMotivos.length === 0 ? false : true;
    this.pasos[this.pasos.findIndex(paso => paso.url === 'motivos')].visible =
      entradasMotivos.length === 0 ? false : true;
    this.venta.carritoValue.hayDescuentoMotivo =
      entradasMotivos.length === 0 ? false : true;
    this.motivosService.setMotivosValue(entradasMotivos);
  }

  setHorarios() {
    const misRecintos = this.generarRecintos();
    const recintosSeleccion = [];
    misRecintos.forEach((miRecinto: any) => {
      if (miRecinto.Activo === '1' && miRecinto.ControlaAforo === '1') {
        const esObligatorioSeleccionar =
          miRecinto.EsActividad === '0' ? true : false;
        recintosSeleccion.push({
          obligatorio: esObligatorioSeleccionar,
          ...miRecinto
        });
      }
    });
    this.pasos[this.pasos.findIndex(paso => paso.url === 'horarios')].activado =
      recintosSeleccion.length === 0 ? false : true;
    this.pasos[this.pasos.findIndex(paso => paso.url === 'horarios')].visible =
      recintosSeleccion.length === 0 ? false : true;
    this.recintosService.setRecintosValue(recintosSeleccion);
  }

  generarRecintos() {
    const recintos = [];
    this.venta.carritoValue.entradas.forEach(e => {
      if (e.entrada.recintos === undefined) {
        return recintos;
      }
      if (!('noEditable' in e.entrada)) {
        e.entrada.recintos.forEach((r: any) => {
          recintos.push(r);
        });
      }
    });
    const mapeoRecintoEntrada = recintos.map(r => ({
      idRecinto: r.Recinto,
      entrada: r.TipoEntrada
    }));

    const recintosConEntrada = recintos.map((r: any) => {
      r.entradas = mapeoRecintoEntrada
        .filter(re => re.idRecinto === r.Recinto)
        .map(rec => rec.entrada);
      return r;
    });
    return this.removeDuplicates(recintosConEntrada, 'Recinto');
  }

  compruebaProcedencias()
  {
    //Comprobacion configuracion
    this.pasos[this.pasos.findIndex(paso => paso.url === 'procedencia')].activado =
    this.configuration.datosTPVPathValue.TipoProcedencia && this.configuration.datosTPVPathValue.TipoProcedencia !== "0" ? true : false;
    //WOIT comprobacion tenemos datos
  }
  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }
}
