import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { LocalizadorService } from './services/localizador.service';
import { Subject, Subscription, Observable } from 'rxjs';

import { FormGroup, FormBuilder } from '@angular/forms';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { VentaService, EntradasService } from '../venta';
import * as moment from 'moment';
import { ConfigurationService } from 'src/app/core';
import { takeWhile, map, take } from 'rxjs/operators';
import { Localizador } from './models/localizador.model';
import { PagerService } from 'src/app/shared/services/pager.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { Router } from '@angular/router';
import { Reserva } from './models/reserva.model';
import { MotivosService } from '../venta/services/motivos.service';

@Component({
  selector: 'ticketing-localizador',
  templateUrl: './localizador.component.html',
  styleUrls: ['./localizador.component.scss'],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    class: 'layout'
  }
})
export class LocalizadorComponent implements OnInit, OnDestroy {
  idioma = 'es-Es';
  reservas;
  fecha;

  localizadorForm: FormGroup;
  myFormSub: Subscription;

  localizador: Localizador;
  localizador$: Observable<Localizador>;
  localizadores$: Observable<any>;
  filtrarCentro;

  private alive = true;

  @ViewChild('localizadorInput') localizadorInput: ElementRef;

  pager: any = {};
  pagedItems: any[];
  sePuedeAceptar: boolean;
  sePuedeImprimir: boolean;
  sePuedeFinalizar: boolean;
  items: any[];
  itemsConMotivo: any[];
  reserva: any;
  loading = false;

  constructor(
    public localizadorService: LocalizadorService,
    private pagerService: PagerService,
    private formBuilder: FormBuilder,
    private formControlService: FormControlService,
    private modalService: ModalService,
    private configurationService: ConfigurationService,
    private router: Router,
    private entradaService: EntradasService,
    private ventaService: VentaService,
    private motivoService: MotivosService
  ) {
    this.localizadorForm = this.formBuilder.group({
      localizador: '',
      fecha: '',
      filtrarCentro: this.filtrarCentro
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  ngOnInit() {
    this.localizador$ = this.localizadorService.localizador;
    this.localizadores$ = this.localizadorService.localizadores;
    this.filtrarCentro = this.configurationService.appConfig.value.DatosResult.AppConfigJsonLocalizador.FiltrarCentro && this.configurationService.appConfig.value.DatosResult.AppConfigJsonLocalizador.FiltrarCentro > 0? true:false
    this.localizadorForm.controls['filtrarCentro'].patchValue(this.filtrarCentro);
    this.onChanges();
    this.configurationService.datosInicio
      .pipe(takeWhile(() => this.alive))
      .subscribe(conf => (this.idioma = conf.Idioma));
    this.localizadorService.fechaSeleccionada.pipe(takeWhile(() => this.alive)).subscribe(fecha => {
      this.fecha = fecha;
      if (fecha) {
        this.localizadorForm.controls['fecha'].patchValue(
          moment(this.fecha)
            .locale(this.idioma)
            .format('L')
        );
      } else {
        this.localizadorForm.controls['fecha'].patchValue('');
      }
    });
    this.localizadorForm.controls['fecha'].patchValue(
      moment(this.fecha)
        .locale(this.idioma)
        .format('L')
    );
    this.localizadorService.queryLocalizador$
      .pipe(takeWhile(() => this.alive))
      .subscribe(localizador => {
        if (localizador) {
          this.localizadorForm.controls['localizador'].patchValue(localizador);
        } else {
          this.localizadorForm.controls['localizador'].patchValue('');
        }
      });

  }

  ngOnDestroy() {
    this.localizadorService.setLocalizadorValue(this.localizadorForm.controls['localizador'].value);
    this.alive = false;
    if(this.myFormSub){this.myFormSub.unsubscribe();}
  }

  getFechaSeleccionada() {
    if (this.localizadorService.fechaSeleccionadaValue) {
      return moment(this.localizadorService.fechaSeleccionadaValue)
        .locale(this.idioma)
        .format('L');
    }
  }

  onChanges() {
    this.myFormSub = this.localizadorService
      .buscar(this.localizadorForm.valueChanges, this.localizadorService.fechaSeleccionadaValue)
      .pipe(takeWhile(() => this.alive))
      .subscribe(reservas => {
        this.reservas = reservas;
        this.setPage(1);
      });
  }

  deleteDate() {
    this.localizadorService.setFechaSeleccionadaValue(undefined);
  }

  nextDia() {
    if (this.localizadorService.fechaSeleccionadaValue) {
      this.localizadorService.setFechaSeleccionadaValue(
        this.localizadorService.fechaSeleccionadaValue.add(1, 'days')
      );
    }
  }

  backDia() {
    if (this.localizadorService.fechaSeleccionadaValue) {
      this.localizadorService.setFechaSeleccionadaValue(
        this.localizadorService.fechaSeleccionadaValue.subtract(1, 'days')
      );
    }
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.reservas.length, page, 6);
    this.pagedItems = this.reservas.slice(this.pager.startIndex, this.pager.endIndex + 1);

    this.pager.pages = this.pager.pages.filter(num => num <= this.pager.totalPages);

    while (this.pager.pages.length < 6 && this.pager.pages[0] > 1) {
      this.pager.pages.unshift(this.pager.pages[0] - 1);
    }
  }

  focusValue(event: Event) {
    this.formControlService.setCurrentFormGroup(
      this.localizadorForm,
      this.localizadorInput.nativeElement,
      (<HTMLElement>event.target).getAttribute('category')
    );
  }

  checkStatus(status: string) {
    switch (status) {
      case '00':
        return 'anulada';
      case '01':
        return 'pagada';
      case '02':
        return 'pendiente';
      case '03':
        return 'vencida';
      case '04':
        return 'canjeada';
      case '05':
        return 'devuelta';
      case '06':
        return 'devuelta Parcialmente'
      default:
        return 'pendiente';
    }
  }

  checkStatusColor(status: string) {
    switch (status) {
      case '00':
        return 'anulada';
      case '01':
        return 'pagada';
      case '02':
        return 'pendiente';
      case '03':
        return 'vencida';
      case '04':
        return 'canjeada';
      case '05':
        return 'devuelta';
      case '06':
          return 'devolucionParcial'
      default:
        return 'pendiente';
    }
  }

  seleccionarReserva(reserva) {
    this.reserva = reserva;
  }

  async cargarEntradas(reserva) {
    this.reserva = reserva;
    if (reserva) {
      this.loading = true;
      this.localizadorService
        .getDatosAdicionalesReserva(reserva.ClaveReserva)
        .pipe(take(1))
        .subscribe(async (res: Reserva) => {
          reserva = res;
          this.sePuedeAceptar = reserva.Anulada === '0' && reserva.NumVenta === '';
          this.sePuedeImprimir =
            reserva.Anulada === '0' && reserva.Pagada === '1' && reserva.NumVenta === '';
          this.sePuedeFinalizar =
            reserva.Anulada === '0' && reserva.Pagada === '0' && reserva.NumVenta === '';
          await this.mapEntradas(reserva).then(entradas => {
            this.items = entradas;
            this.itemsConMotivo = entradas.filter(
              linea => linea.entrada.GrupoMotivoId && linea.entrada.GrupoMotivoId !== ''
            );
          });
          if (!this.sePuedeAceptar) {
            return;
          }
          await this.cargarDatosLocalizador(reserva);
          this.ventaService.carritoValue.localizador = reserva;
          this.ventaService.carritoValue.reserva = {
            ClaveReserva: reserva.ClaveReserva,
            ReservaId: reserva.pkId,
            Pagada: reserva.Status === '01' ? 1 : 0
          };
          this.localizadorService.setLocalizadorValue(reserva);
          this.localizadorService.entradasHolder = reserva.ReservaItems;
          this.agregarMotivos(reserva);
          this.router.navigate(['/venta/entradas']);
        });
    }
  }

  private async cargarDatosLocalizador(reserva) {
    this.ventaService.clearCarrito();
    const entradas = await this.mapEntradas(reserva);
    entradas.forEach(entrada => {
      this.ventaService.sendEntradas(entrada.entrada, entrada.cantidad);
    });
    const cliente = this.mapCliente(reserva);
    this.ventaService.setClienteSeleccionadoValue(cliente);
  }

  mapCliente(reserva) {
    return reserva.ReservaCliente[0];
  }

  agregarMotivos(reserva) {
    if (this.itemsConMotivo.length !== 0) {
      this.motivoService.setEntradasDesdeLocalizadorConMotivo(this.itemsConMotivo);
      // TODO: arreglar esto, peta con los localizadores que tengan entradas con motivo
      this.openModal('motivosLocalizador');
      this.modalService.open('motivosLocalizador');
    }
  }

async mapEntradas(reserva): Promise<any[]> {

  const entradas: any = reserva.ReservaItems.map(producto => {  
    
    const entrada = {
      ...JSON.parse(JSON.stringify(producto)),
      noEditable: reserva.Pagada === '1' ? true : false,
      TipoEntradaId: producto.ItemId,
      TipoPromocionId: '',
      TipoAbonadoId: '',
      Minimo: '',
      Maximo: '',
      Orden: '',
      PrecioEnDivisaReferencia: producto.PrecioUnidad
     };
     entrada.IVA = producto.IVA.replace(',', '.');
     entrada.Comision = this.conversor(producto.Comision);
     entrada.Fecha = producto.FechaHoraEntrada;
     entrada.precioFinalVenta = this.conversor(producto.PrecioUnidad); 
     
    return { entrada: entrada, cantidad: Number.parseInt(producto.Cantidad, 10) };
  });
  const entradasIds = entradas.map(e => e.entrada.TipoEntradaId).join(',');

  return await this.entradaService
    .getRecintosEntrada(entradasIds)
    .pipe(
      map(() => {
        entradas.forEach(e => {
          if (e.entrada) {
            e.entrada.recintos = [];
          }
        });
        return entradas;
      })
    )
    .toPromise();
}


  // async mapEntradas(reserva): Promise<any[]> {
  //   const entradas: any = reserva.ReservaItems.map(producto => {
  //     const entrada = {
  //       noEditable: reserva.Pagada === '1' ? true : false,
  //       CategoriaId: producto.CategoriaId,
  //       CodigoPromocional: producto.CodigoPromocional,
  //       TipoEntradaId: producto.ItemId,
  //       TipoPromocionId: '',
  //       TipoAbonadoId: '',
  //       GrupoId: producto.GrupoId,
  //       GrupoMotivoId: producto.GrupoMotivoId,
  //       NombreTipo: producto.NombreTipo,
  //       NombrePantalla: producto.NombrePantalla,
  //       NombreImpresion: producto.NombreImpresion,
  //       NombreDispay: producto.NombreDisplay,
  //       Minimo: '',
  //       Maximo: '',
  //       Orden: '',
  //       PrecioEnDivisaReferencia: producto.PrecioUnidad,
  //       PrecioBase: producto.PrecioBase,
  //       IVA: producto.IVA.replace(',', '.'),
  //       Comision: this.conversor(producto.Comision),
  //       IvaComision: producto.IvaComision,
  //       ComisionBase: this.conversor(producto.ComisionBase),
  //       DesglosarComision: producto.DesglosarComision,
  //       Fecha: producto.FechaHoraEntrada,
  //       FarId: producto.FarId,
  //       precioFinalVenta: this.conversor(producto.PrecioUnidad),
  //       ImprimirTicket: producto.ImprimirTicket,  //Yaribel 20210702  añadimos la variable 
  //     };
  //     return { entrada: entrada, cantidad: Number.parseInt(producto.Cantidad, 10) };
  //   });
  //   const entradasIds = entradas.map(e => e.entrada.TipoEntradaId).join(',');

  //   return await this.entradaService
  //     .getRecintosEntrada(entradasIds)
  //     .pipe(
  //       map(() => {
  //         entradas.forEach(e => {
  //           if (e.entrada) {
  //             e.entrada.recintos = [];
  //           }
  //         });
  //         return entradas;
  //       })
  //     )
  //     .toPromise();
  // }

  conversor(precio: string) {
    return Number.parseFloat(precio.replace(/,/, '.'));
  }

  selectedReservaClass(reserva) {
    if (reserva === this.reserva) {
      return 'selected';
    } else {
      return 'unselected';
    }
  }

  isReservaSelected() {
    if (this.reserva) {
      return false;
    } else {
      return true;
    }
  }

  changefiltrarCentro(){
    this.filtrarCentro = !this.filtrarCentro;
  }
  mostrarFiltro(){
    return this.configurationService.appConfig.value.DatosResult.AppConfigJsonLocalizador.FiltrarCentro && this.configurationService.appConfig.value.DatosResult.AppConfigJsonLocalizador.FiltrarCentro == 2;
  }
}
