import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VentaComponent } from './modules/venta/venta.component';
import { DevolucionComponent } from './modules/devolucion/devolucion.component';
import { SelectorEntradasComponent } from './modules/venta/components/selector-entradas/selector-entradas.component';
import { SelectorHorariosComponent } from './modules/venta/components/selector-horarios/selector-horarios.component';
import { SelectorProcedenciaComponent } from './modules/venta/components/selector-procedencia/selector-procedencia.component';
import { SelectorPromocionesComponent } from './modules/venta/components/selector-promociones/selector-promociones.component';
import { SelectorPagoComponent } from './modules/venta/components/selector-pago/selector-pago.component';
import { GestionarComponent } from './modules/gestionar/gestionar.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CambioUsuarioComponent } from './modules/gestionar/components/cambio-usuario/cambio-usuario.component';
import { CierreCajaComponent } from './modules/gestionar/components/cierre-caja/cierre-caja.component';
import { PagoGuard } from './core/guards/pago.guard';
import { ConsultarDineroCajaComponent } from './modules/gestionar/components/consultar-dinero-caja/consultar-dinero-caja.component';
import { LocalizadorComponent } from './modules/localizador/localizador.component';
import { DetalleLocalizadorComponent } from './modules/localizador/components/detalle-localizador/detalle-localizador.component';
import { GestionarVentaComponent } from './modules/gestionar-venta/gestionar-venta.component';
import { DetalleVentaComponent } from './modules/gestionar-venta/components/detalle-venta/detalle-venta.component';
import { ImprimirVentaComponent } from './modules/gestionar-venta/components/imprimir-venta/imprimir-venta.component';
import { AuditorioComponent } from './modules/auditorio/auditorio.component';
import { EspectaculosComponent } from './modules/auditorio/components/espectaculos/espectaculos.component';
// tslint:disable-next-line:max-line-length
import { SelectorEntradasBotoneraComponent } from './modules/venta/components/selector-entradas-botonera/selector-entradas-botonera.component';
import { SelectorDevolucionComponent } from './modules/gestionar-venta/components/selector-devolucion/selector-devolucion.component';
import { SeleccionButacasComponent } from './modules/auditorio/components/seleccion-butacas/seleccion-butacas.component';
import { AgendaCalendarioComponent } from './modules/agenda/components/agenda-calendario/agenda-calendario.component';
import { SelectorMotivosComponent } from './modules/venta/components/selector-motivos/selector-motivos.component';
import { AgendaLoginComponent } from './modules/agenda/components/agenda-login/agenda-login.component';
import { SeleccionSesionesComponent } from './modules/auditorio/components/seleccion-sesiones/seleccion-sesiones.component';
import { SelectorPagoDevolucionComponent } from './modules/gestionar-venta/components/selector-devolucion/selector-pago-devolucion.component';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { CambiarPasswordComponent } from './core/auth/cambiar-password.component';
import { LoginComponent } from './core/auth/login/login.component';
import { CambiarPassGuard } from './core/guards/cambiar-pass.guard';
import { RetirarIntroducirComponent } from './modules/gestionar/components/retirar-introducir/retirar-introducir.component';
import { DesglosarCambioComponent } from './modules/gestionar/components/desglosar-cambio/desglosar-cambio.component';
import { ClienteAsociadoComponent } from './modules/Asociado/components/cliente-asociado/cliente-asociado.component';
import { MovimientosCajaComponent } from './modules/gestionar/components/movimientos-caja/movimientos-caja.component';
import { PermisosGuard } from './core/guards/permisos.guard';
import { IntermediateGuard } from './core/guards/intermediate.guard';
import { CodigoAuthComponent } from './core/auth/codigo-auth/codigo-auth.component';

export function discernirComponente(bool) {
  if (bool) {
    return SelectorEntradasBotoneraComponent;
  } else {
    return SelectorEntradasComponent;
  }
}
const routes: Routes = [
  {
    path: 'venta',
    component: VentaComponent,
    children: [
      // { path: '', component: VentaComponent },
      { path: '', redirectTo: 'venta', pathMatch: 'prefix' },
      { path: 'entradas', redirectTo: 'venta', pathMatch: 'prefix' },
      {
        path: 'horarios',
        component: SelectorHorariosComponent,
        canActivate: [PagoGuard]
      },
      {
        path: 'motivos',
        component: SelectorMotivosComponent,
        canActivate: [PagoGuard]
      },
      {
        path: 'procedencia',
        component: SelectorProcedenciaComponent,
        canActivate: [PagoGuard]
      },
      {
        path: 'promociones',
        component: SelectorPromocionesComponent,
        canActivate: [PagoGuard]
      },
      {
        path: 'pago',
        component: SelectorPagoComponent,
        canActivate: [PagoGuard]
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'gestionar-venta',
    component: GestionarVentaComponent,
    children: [
      { path: '', component: DetalleVentaComponent
        
      },
      { path: 'devolucion-parcial', component: SelectorDevolucionComponent,
        canActivate: [AuthGuard]
      },
      { path: 'pago-devolucion-parcial', component: SelectorPagoDevolucionComponent,
        canActivate: [AuthGuard]
      },
      { path: 'imprimir', component: ImprimirVentaComponent,
      canActivate: [AuthGuard]
      }
    ],
    // canActivate: [AuthGuard]
  },
  //JS prueba Path externos
  {
    path: 'gestionar-venta/:tpv/:albaran',
    component: GestionarVentaComponent//,
    //canActivate: [AuthGuard]
  },
  {
    path: 'devolucion',
    component: DevolucionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'auditorio',
    component: AuditorioComponent,
    children: [
      { path: '', redirectTo: 'actividades', pathMatch: 'prefix' },
      // { path: 'auditorio', redirectTo: 'actividades', pathMatch: 'prefix' },
      { path: 'actividades', component: EspectaculosComponent },
      { path: 'seleccion-butaca', component: SeleccionButacasComponent },
      { path: 'seleccion-sesiones', component: SeleccionSesionesComponent },
      {
        path: 'pago',
        component: SelectorPagoComponent,
        canActivate: [PagoGuard]
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'localizador',
    component: LocalizadorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'localizador/:localizador/:numeroPedido',
    component: DetalleLocalizadorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'turno',
    component: GestionarComponent,
    children: [
       { path: '', component: GestionarComponent },
       {path: 'retirarIntroducir', component: RetirarIntroducirComponent},
       {path: 'movimientos', component: MovimientosCajaComponent},
       { path: 'consulta', component: ConsultarDineroCajaComponent },
      { path: 'consultaEfectivo', component: ConsultarDineroCajaComponent },
      { path: 'cambio', component: CambioUsuarioComponent },
      { path: 'cierre', component: CierreCajaComponent }
      // {path: 'impresion', component: ImprimirComponent},
    ],
    canActivate: [AuthGuard, PermisosGuard]
    
  },
  {path: 'desglose', component: DesglosarCambioComponent},
  // JS 20190823 componente agenda
  {
    path: 'agenda',
    component: AgendaCalendarioComponent,
    children: [
      { path: '', component: AgendaCalendarioComponent },
      { path: 'tipovisita', component: AgendaCalendarioComponent },
      { path: 'listahoras', component: AgendaCalendarioComponent }
    ],
    canActivate: [AuthGuard]
  },
  // FJS
  {
    path: 'cambio-usuario',
    component: CambioUsuarioComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'agenda-login',
    component: AgendaLoginComponent,
    canActivate: [AuthGuard]
  },
  {path: 'codigoAuth', component: CodigoAuthComponent,canActivate: [CambiarPassGuard] },
  { path: 'cambiar-password', component: CambiarPasswordComponent, canActivate: [CambiarPassGuard]},
  { path: 'login', component: LoginComponent},
  { path: 'login/:path', component: LoginComponent},
  {path: 'asociado', component: ClienteAsociadoComponent, canActivate: [AuthGuard]},
  { path: '', component: VentaComponent,
  canActivate: [AuthGuard] },
  {path: '**', component: VentaComponent, canActivate: [AuthGuard]}

  // { path: '', redirectTo: 'venta', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      enableTracing: false, // <-- debugging purposes only
      preloadingStrategy: SelectivePreloadingStrategyService,
      useHash: true
    }),
    ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
