import { CardPayment } from './cardPayment.interface';
import { OsConnection } from '../connection/osConnection.interface';
import { OsConnectionFactory } from '../connection/osConnection.factory';
import { ConnectionType } from '../connection/connectionType.enum';

export class VirtualCardPayment implements CardPayment {
  osConnection: OsConnection;
  constructor() {
    this.osConnection = OsConnectionFactory.getConnection(ConnectionType.VIRTUAL);
  }

  pay(
    idTpv: string,
    NumDecimalesDivisa: string,
    ContadorAlbaranesVenta: string,
    ContadorAlbaranesDevolucion: string,
    Identificador_Alb: string,
    OperacionVenta: string,
    TipoComunicacionVenta: string,
    ImporteVenta: string,
    ImporteVenta_dev: string,
    TpvIdOrigen: string,
    NumTarjetaOrigen: string,
    CaducidadTarjetaOrigen: string,
    NumOperacionOrigen: string,
    IdRtsOrigen: string,
    NumTerminal: string,
    VersionWeb: string,
    NumCom: string,
    ClaveCom: string,
    COMPinPad: string,
    TimeOutTjta: string = "0"
  ) {
    return this.osConnection.ventaTarjeta(
      idTpv,
      NumDecimalesDivisa,
      ContadorAlbaranesVenta,
      ContadorAlbaranesDevolucion,
      Identificador_Alb,
      OperacionVenta,
      TipoComunicacionVenta,
      ImporteVenta,
      ImporteVenta_dev,
      TpvIdOrigen,
      NumTarjetaOrigen,
      CaducidadTarjetaOrigen,
      NumOperacionOrigen,
      IdRtsOrigen,
      NumTerminal,
      VersionWeb,
      NumCom,
      ClaveCom,
      COMPinPad,
      TimeOutTjta
    );
  }
}
