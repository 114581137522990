import { Component, OnInit } from '@angular/core';
import { KeyboardService } from '../../services/keyboard.service';
import { FormControlService } from '../../services/form-control.service';

enum KeyboardStatus {
  MINUS, CAPS, SPECIAL
}

@Component({
  selector: 'ticketing-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnInit {

  status = KeyboardStatus.MINUS;
  currentKeySet = [];
  keys = [
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ñ'],
    ['z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.'],
  ];
  shiftedKeys = [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ñ'],
    ['Z', 'X', 'C', 'V', 'B', 'N', 'M', ',', '.'],
  ];
  specialKeys = [
    ['á', 'é', 'í', 'ó', 'ú', '^', '·', 'º', '¡', '¿'],
    ['@', '#', '€', '&', '*', '(', ')', '´', '"', '+'],
    ['%', '_', '-', '=', '/', ';', ':', '!', '?'],
  ];
  constructor(private formControlService: FormControlService) { }

  ngOnInit() {
    this.currentKeySet = this.keys;
  }

  capsToMinusShift() {
    if (this.status === KeyboardStatus.MINUS) {
      this.status = KeyboardStatus.CAPS;
      this.currentKeySet = this.shiftedKeys;
      return;
    }
    if (this.status === KeyboardStatus.CAPS) {
      this.status = KeyboardStatus.MINUS;
      this.currentKeySet = this.keys;
      return;
    }
  }

  normalToSpecial() {
    if (this.status === KeyboardStatus.MINUS || this.status === KeyboardStatus.CAPS) {
      this.status = KeyboardStatus.SPECIAL;
      this.currentKeySet = this.specialKeys;
      return;
    }
    if (this.status === KeyboardStatus.SPECIAL) {
      this.status = KeyboardStatus.MINUS;
      this.currentKeySet = this.keys;
      return;
    }
  }

  send(key: string) {
    if (this.formControlExists()) {
      this.formControlService.inputChar(key);
    }
  }

  deleteOne() {
    if (this.formControlExists()) {
      this.formControlService.deleteChar();
    }
  }

  loseFocus() {
    if (this.formControlExists()) {
      this.formControlService.unfocus();
    }
  }

  formControlExists() {
    return this.formControlService.currentFormGroupValue;
  }

}
