import { Component, OnInit } from '@angular/core';
import { FormControlService } from '../../services/form-control.service';
import { NumpadService } from '../../services/numpad.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ticketing-numpad',
  templateUrl: './numpad.component.html',
  styleUrls: ['./numpad.component.scss']
})
export class NumpadComponent implements OnInit {
  constructor(public formControlService: FormControlService, private router: Router) {}

  ngOnInit() {}

  send(key: string) {
    if (this.formControlExists()) {
      this.formControlService.inputChar(key);
    }
  }

  deleteOne() {
    if (this.formControlExists()) {
      this.formControlService.deleteChar();
    }
  }

  setToBlank() {
    if (this.formControlExists()) {
      this.formControlService.setToBlank();
    }
  }

  formControlExists() {
    return this.formControlService.currentFormGroupValue;
  }
}
