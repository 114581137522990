import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrintService } from '../../services/print.service';
import { Subscription } from 'rxjs';
import { LocalizadorService } from 'src/app/modules/localizador/services/localizador.service';
import { TurnoService } from 'src/app/core';
import { AppConfiguration } from 'src/app/app.configuration';
import * as moment from 'moment';

@Component({
  selector: 'ticketing-impresion-localizador-modal',
  templateUrl: './impresion-localizador-modal.component.html',
  styleUrls: ['./impresion-localizador-modal.component.scss']
})
export class ImpresionLocalizadorModalComponent implements OnInit {
  @Input() id: string;
  private element: any;

  formSubscription: Subscription;
  opcionesImpresionLocalizadorForm: FormGroup;
  constructor(
    private modalService: ModalService,
    el: ElementRef,
    private router: Router,
    private fb: FormBuilder,
    private printService: PrintService,
    private localizadorService: LocalizadorService,
    private config: AppConfiguration,
    private turnoService: TurnoService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.opcionesImpresionLocalizadorForm = this.fb.group({
      imprimirTodo: false,
      entradas: false,
      etiquetas: false
    });

    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
    this.formSubscription = this.opcionesImpresionLocalizadorForm.controls[
      'imprimirTodo'
    ].valueChanges.subscribe(val => {
      if (val) {
        this.marcarTodo();
      } else {
        this.desmarcarTodo();
      }
    });

    this.opcionesImpresionLocalizadorForm.controls['entradas'].enable();
    this.opcionesImpresionLocalizadorForm.controls['etiquetas'].disable();
  }

  close(): void {
    this.printService.setOpcionesDeImpresion(this.opcionesImpresionLocalizadorForm.value);
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
    if(this.formSubscription){this.formSubscription.unsubscribe();}
  }

  aceptarOpcionesEImprimir() {
    this.printService.setOpcionesDeImpresion(this.opcionesImpresionLocalizadorForm.value);

    if (this.printService.opcionesDeImpresionValue.etiquetas) {
      this.modalService.open('etiquetas');
      //this.router.navigate(['venta', 'entradas']);
      if (this.config.getConfig('COMPRAINICIO')) {
        this.router.navigate([this.config.getConfig('COMPRAINICIO')]);
      } else {
        this.router.navigate(['']);
      }
    }

    if (this.printService.opcionesDeImpresionValue.entradas) {
      this.localizadorService
        .reimprimirTickets(this.localizadorService.localizadorValue.ClaveReserva)
        .subscribe((infoImpresion) => {
          
          
          // 
          // 
          //<<Yaribel 20210126 unificamos la impresion de tickets en uno solo
          // this.printService.reimprimirTickets(
          //   this.turnoService.turnoValue.NombreTPV,
          //   this.localizadorService.localizadorValue.ClaveReserva,
          //   `${this.localizadorService.localizadorValue.FechaHoraEntrada}`,
          //   this.localizadorService.localizadorValue.ReservaItems,
          //   this.localizadorService.localizadorValue
          // );

          this.printService.imprimirTicketUnico(
            this.turnoService.turnoValue.NombreTPV,
            this.turnoService.turnoValue.TpvId,
            '',
            infoImpresion,
            this.localizadorService.localizadorValue.FechaHoraEntrada.split(' ')[1],
            this.localizadorService.localizadorValue.ReservaItems,
            this.localizadorService.localizadorValue.ClaveReserva,
            moment(this.localizadorService.localizadorValue.ReservaHistorial[0].Fecha).format('DD/MM/YYYY'),
            this.localizadorService.localizadorValue.ReservaHistorial[0].Hora.slice(0, -3),
            this.localizadorService.localizadorValue.ReservaRecintos,
            this.localizadorService.localizadorValue.ReservaMotivos,
            this.localizadorService.localizadorValue.Localizador,
            "0" //this.albaran.TipoImpresion
            
          );
          // Yaribel 20210126 >> 



        });
      this.router.navigate(['']);
    }
    this.close();
  }

  marcarTodo() {
    this.opcionesImpresionLocalizadorForm.controls['entradas'].patchValue(true);
    this.opcionesImpresionLocalizadorForm.controls['etiquetas'].patchValue(true);
  }

  desmarcarTodo() {
    this.opcionesImpresionLocalizadorForm.controls['entradas'].patchValue(false);
    this.opcionesImpresionLocalizadorForm.controls['etiquetas'].patchValue(false);
  }
}
