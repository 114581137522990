import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionarComponent } from './gestionar.component';
import { TabsComponent } from './shared/tabs/tabs.component';
import { ConsultarDineroCajaComponent } from './components/consultar-dinero-caja/consultar-dinero-caja.component';
import { RetirarIntroducirComponent } from './components/retirar-introducir/retirar-introducir.component';
import { CambioUsuarioComponent } from './components/cambio-usuario/cambio-usuario.component';
import { CierreCajaComponent } from './components/cierre-caja/cierre-caja.component';
import { ImprimirComponent } from './components/imprimir/imprimir.component';
import { RouterModule } from '@angular/router';
import { MyCurrencyPipe } from 'src/app/shared/pipes/myCurrency.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MovimientosCajaComponent } from './components/movimientos-caja/movimientos-caja.component';

@NgModule({
  declarations: [GestionarComponent,
    TabsComponent,
    ConsultarDineroCajaComponent,
    RetirarIntroducirComponent,
    CambioUsuarioComponent,
    CierreCajaComponent,
    ImprimirComponent,
    MovimientosCajaComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    MyCurrencyPipe,
  ]
})
export class GestionarModule { }
