import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoImpresion'
})
export class TipoImpresionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case '00':
        return 'NORMAL';
      case '01':
        return 'CONJUNTA';
      default:
        return '';
    }
  }

}
