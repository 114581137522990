import { Component, OnInit } from '@angular/core';
import { AlbaranService } from '../../services/albaran.service';
import { Albaran } from '../../models/albaran.model';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PrintService } from 'src/app/shared/services/print.service';
import { TurnoService } from 'src/app/core';

@Component({
  selector: 'ticketing-detalle-venta',
  templateUrl: './detalle-venta.component.html',
  styleUrls: ['./detalle-venta.component.scss']
})
export class DetalleVentaComponent implements OnInit {
  albaran: Albaran;
  albaran$: Observable<Albaran>;
  albaranes$: Observable<any>;

  constructor(
    private albaranService: AlbaranService,
    private printService: PrintService,
    private turno: TurnoService
  ) {}

  ngOnInit() {
    this.albaran$ = this.albaranService.albaran.pipe(
      map((albaran: Albaran) => {
        if (
          albaran !== undefined &&
          'noData' in albaran &&
          albaran.noData === true
        ) {
          //this.albaranService.getUltimoAlbaran(this.turno.turnoValue.TpvId);
          albaran.AlbaranItems = [];
          return albaran;
        }
        // 
        if (albaran !== undefined) {
          const items = albaran.AlbaranItems.map(item =>
            this.addMotivoItem(item, albaran.AlbaranMotivos)
          );
          albaran.AlbaranItems = items;
        }
        if (albaran !== undefined) {
          this.printService.ventaAnulada =
            albaran.AlbaranHistorial[albaran.AlbaranHistorial.length - 1]
              .TipoAccion === '06'
              ? true
              : false;
        }
        return albaran;
      })
    );
  }

  addMotivoItem(item, motivos) {
    if (!motivos) {
      motivos = [];
    }
    item = { ...item, motivosAsociados: [] };
    item.motivosAsociados = motivos.filter(m => m.idTarifa === item.ItemId);
    return item;
  }
}
