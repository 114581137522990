import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { }

  filter(collection: any[], params: string) {
    const parsedCollection = this.parse(collection);
    const criterias = this.getCriteriosIndividuales(params);
    const filteredCollection = criterias.flatMap( criteria => {
      return this.filterByCriteria(parsedCollection, criteria);
    });
    const collectionNoDuplicates = this.eliminarDuplicados(filteredCollection);
    return collectionNoDuplicates;
  }

  getCriteriosIndividuales(params) {
    return params.split(',').map( param => {
      const p = {
        rango: {
          ...this.getRango(param),
          inclusivo: true
        }
      };
      return p;
    });
  }

  getRango(param: string) {
     if (param.includes('-')) {
      const values = param.split('-');
      return {
        inicio: +values[0],
        fin: +values[1]
      };
     } else {
       return {
         inicio: +param,
         fin: +param
       };
     }
  }

  filterByCriteria(collection: any[], criteria) {
    const firstIndex = collection.findIndex(e => e.NumTicket === criteria.rango.inicio);
     const lastIndex = collection.findIndex(e => e.NumTicket === criteria.rango.fin);
     return collection.slice(firstIndex, lastIndex + 1);
  }

  eliminarDuplicados(collection) {
    return Array.from(new Set(collection.map(a => a.NumTicket))).map(id => {
      return collection.find(a => a.NumTicket === id);
    });
  }

  parse(collection) {
    return collection.map( e => {
      e.NumTicket = +e.NumTicket;
      return e;
    });
  }

}
