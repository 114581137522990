import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppConfiguration } from 'src/app/app.configuration';
import { VentaService, StepperNavigationService, Carrito } from 'src/app/modules/venta';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'ticketing-confirmar-canjeo-caducado',
  templateUrl: './confirmar-canjeo-caducado.component.html',
  styleUrls: ['./confirmar-canjeo-caducado.component.scss']
})
export class ConfirmarCanjeoCaducadoComponent implements OnInit {
  @Input() id: string;
  private element: any;
  
  constructor(
    private modalService: ModalService,
    private el: ElementRef,
    private toast: ToastrService,
    private router: Router,
    private ventaService: VentaService,
    private stepperNavigation: StepperNavigationService,
    private config: AppConfiguration
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', function(e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  canjearVenta() {
    const currentDate = new Date();
    this.ventaService.carritoValue.fecha = moment(currentDate).format(
      'YYYY/MM/DD HH:mm:ss'
    );
    this.ventaService.carritoValue.resetearHorariosCarrito();
    this.stepperNavigation.continuarVenta.next(false);
    this.ventaService.carritoSubject.pipe(first()).subscribe((res1: Carrito) => {
      if (res1.carrito) {
        res1.setCarritoModificacionCarritoValue(res1.carrito);
        this.ventaService.carritoSubject.next(res1);
      }
    });
    this.stepperNavigation.resetearPasos();
    this.close();
    this.router.navigate(['']);
  }

}
